import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import styled from "styled-components";

import { Icon } from "./Icon";
import { Tooltip } from "./popup";

const Btn = styled.button`
  transition: 0.2s background;
  background: #5855f4;
  border-radius: 8px;
  height: 40px;
  padding: 0 12px;

  &:hover {
    background: #6f6cfa;
  }

  &:disabled {
    cursor: default;
    color: rgba(255, 255, 255, 0.5);
    background: #5855f4;
  }
`;

interface Props {
  tooltipText: React.ReactNode;
  clipboard: (()=>string) | string;
  hasIcon: boolean;
}

const CopyButtonAction: FC<Props> = ({ tooltipText, clipboard, hasIcon, children }) => {
  const [isCopyied, setIsCopyied] = useState(false);
  const [timer, setTimer] = useState<null | NodeJS.Timeout>(null);

  const handleClick = async () => {
    try {
      const result = typeof(clipboard) === "string" ? clipboard : clipboard();
      await navigator?.clipboard.writeText(result);
      setIsCopyied(true);

      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }

      const newTimer = setTimeout(() => {
        clearTimeout(newTimer);
        setIsCopyied(false);
      }, 2000);

      setTimer(newTimer);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Tooltip position="top center" text={isCopyied ? "Copied" : tooltipText}>
      <Btn onClick={handleClick}>
        {children}
        { (hasIcon !== false) && <Icon name="copy-link-icon" />}
      </Btn>
    </Tooltip>
  );
};


export default observer(CopyButtonAction);
