import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Dropdown } from "semantic-ui-react";
import "./styles.css";


export const ApplicationListDropdown: FC<{ account: IAccount, application: string|undefined,  selectApplication: (appName: string)=> void}> = ({ account, application, selectApplication }) => {
  const [applications, setApplications] = useState<{ text: string }[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const a = await account.connect();
      const q = new Set((await dasha.applications.getApplications({ account: a })).map((x) => x.name));
      const apps = Array.from(q).map((x) => ({ text: x, value: x, key: x }));
      setApplications(apps);
    };
    fetchData().then(() => {
      setLoading(false);
    });
  }, [account]);


  return (
    <Dropdown
      placeholder="Select Application"
      fluid
      search
      selection
      options={applications}
      loading={loading}
      value={application}
      onChange={(e, {value}) => selectApplication(value)}
    />
  );
};
