import React, { FC } from "react";
import { observer } from "mobx-react-lite";
import seedrandom from "seedrandom";

import AccountsManager from "../../core/account/Manager";

import { MenuOption, PopupMenu } from "../../uikit/popup";
import { ActionButton } from "../../uikit";
import { Icon } from "../../uikit";
import hue from "../../uikit/hue";
import * as S from "./styled";

const AccountWidget: FC<{ accounts: AccountsManager }> = ({ accounts }) => {
  if (accounts.isAuth == false) {
    return (
      <ActionButton onClick={() => accounts.login()} style={{ height: 32 }}>
        SIGN IN
      </ActionButton>
    );
  }

  const nickname = accounts.account.name;
  const letter = nickname.slice(0, 1).toUpperCase();
  const color = hue[Math.floor(hue.length * seedrandom(nickname)())];

  const actions: MenuOption[] = [
    {
      label: nickname,
      isEnable: true,
    },
    {
      label: "Sign out",
      icon: <Icon style={{ marginRight: 8 }} name="logout" />,
      action: () => accounts.logout(),
      isEnable: true,
    },
  ];

  return (
    <PopupMenu actions={actions} position="bottom right" offsetY={8}>
      <S.AvatarMenu>
        <S.Avatar style={{ background: `hsl(${color}, 57%, 57%)` }}>{letter}</S.Avatar>
        <Icon name="chevron-down" />
      </S.AvatarMenu>
    </PopupMenu>
  );
};

export default observer(AccountWidget);
