import { observer } from "mobx-react-lite";
import React, { FC, useEffect, useState } from "react";
import { useRef } from "react";
import { PureButton } from "../Button";
import { Icon } from "../Icon";
import * as S from "./styled";
import ArrowIcon from "./assets/arrow.svg";

export interface OptionWithItems {
  label: string;
  items: string[];
}

interface Props {
  options: string[] | OptionWithItems[];
  selected: string;
  focuses?: boolean;
  customValue?: boolean;
  isInvalid?: boolean;
  saveOnEnter?: boolean;
  newBtn?: React.ReactNode;
  position?: string;
  onChange: (value: string, subValue?: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  onRemove?: () => void;
}

interface SubListProps {
  option: OptionWithItems;
  onSelect: (entity: string, value?: string) => void;
}

const SubList = observer<SubListProps>(({ option, onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <S.List>
      <S.SubListLabel isOpen={isOpen}>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen((prevValue) => !prevValue);
          }}
        >
          <ArrowIcon />
        </button>
        <S.Item onClick={() => onSelect(option.label)}>{option.label}</S.Item>
      </S.SubListLabel>
      {isOpen && (
        <S.SubList>
          {option.items.map((item) => (
            <S.Item key={item} onClick={() => onSelect(option.label, item)}>
              {item}
            </S.Item>
          ))}
        </S.SubList>
      )}
    </S.List>
  );
});

const SearchSelect: FC<Props> = (props) => {
  const { options, position, isInvalid, customValue, focuses, selected, saveOnEnter, newBtn } = props;
  const { onChange, onRemove, onBlur, onFocus } = props;

  const [value, setValue] = useState("");
  const [isFocus, setFocus] = useState(false);
  const fieldRef = useRef<HTMLDivElement>(null);
  // @ts-ignore
  const filtered = options.filter((item: string | OptionWithItems) =>
    typeof item === "string"
      ? item.toLowerCase().includes(value.toLowerCase())
      : item.label.toLowerCase().includes(value.toLowerCase())
  );

  useEffect(() => {
    const handler = (e) => {
      if (fieldRef.current?.contains(e.target)) return;
      if (isFocus == false) return;
      setFocus(false);
      onBlur?.();
    };

    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, [isFocus, customValue, onChange, onBlur]);

  useEffect(() => {
    setValue(selected);
    updateInputSize(selected);
  }, [selected]);

  useEffect(() => {
    if (focuses !== true) return;
    fieldRef.current?.querySelector("input")?.focus();
  }, []);

  const updateInputSize = (text: string) => {
    const span = fieldRef.current?.querySelector("span");
    if (span == null) return;
    span.innerText = text;
  };

  const handleFocus = () => {
    setFocus(true);
    onFocus?.();
  };

  const handleSelect = (item: string, subItem?: string) => {
    onChange(item, subItem);
    setFocus(false);
    onBlur?.();
  };

  const handleKeyUp = (e) => {
    if (e.key === "Enter" && saveOnEnter) {
      handleSelect(value);
    }
  };

  return (
    <S.Field ref={fieldRef} isInvalid={isInvalid}>
      {((isFocus && filtered.length > 0) || newBtn) && (
        <S.Container $position={position}>
          {filtered.map((item) => {
            if (typeof item === "string") {
              return (
                <S.Item key={item} onClick={() => handleSelect(item)}>
                  {item}
                </S.Item>
              );
            }

            return <SubList key={item.label} option={item} onSelect={handleSelect} />;
          })}
          {newBtn}
        </S.Container>
      )}

      <span />
      <input
        value={value}
        onFocus={handleFocus}
        onKeyUp={handleKeyUp}
        onInput={(e) => updateInputSize(e.currentTarget.value)}
        onChange={(e) => setValue(e.target.value)}
        autoComplete="nope"
      />

      {onRemove && (
        <PureButton onClick={onRemove}>
          <Icon name="cross" />
        </PureButton>
      )}
    </S.Field>
  );
};

export default SearchSelect;
