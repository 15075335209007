import React, { FC, useEffect, useState } from "react";

import { IAccount } from "@core/account/interface";
import "./styles.css";
import { Link } from "react-router-dom";
import { CopyInput } from "../uikit/CopyInput";

export const ApiKey: FC<{ account: IAccount }> = ({ account }) => {
  const [apiKey, setApiKey] = useState("");
  const [server, setServer] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      const a = await account.connect();
      setApiKey(a.apiKey);
      setServer(a.server);
      setLoading(false);
    };
    fetchData();
  }, [account]);
  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>API Key</h2>
      {
        <>
          <h3>Server</h3>
          <CopyInput clipboard={server} loading={loading} tooltipText="Server" type="text" />
          <h3>API Key</h3>
          <CopyInput clipboard={apiKey} loading={loading} tooltipText="API Key" type="password" />
          <h3>Environment variables configuration (better for server/docker usage)</h3>
          <CopyInput
            clipboard={`export DASHA_SERVER=${server}\r\nexport DASHA_APIKEY=${apiKey}\r\n`}
            loading={loading}
            tooltipText="Environment"
            type="password"
          />
          <h3>
            <Link to="https://www.npmjs.com/package/@dasha.ai/cli">dasha-cli</Link> command for configuration (better
            for local usage)
          </h3>
          <CopyInput
            clipboard={`dasha account add -f --server ${server} --api-key ${apiKey} prod\r\ndasha account use prod\r\n`}
            loading={loading}
            tooltipText="Cli"
            type="password"
          />
        </>
      }
    </div>
  );
};
