import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table } from "semantic-ui-react";
import "./styles.css";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerQueues } from "./CustomerQueues";
import { InboundVoIP } from "./InboundVoIP";
import { OutboundVoIP } from "./OutboundVoIP";
import { useParams } from "react-router-dom";
import { FailedConversations } from "./FailedConversations";
interface Props {
  account: IAccount;
}

const DiagnosticsPanel: FC<Props> = ({ account }) => {
  const { customerId } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
    };
    fetchData().then(() => setLoading(false));
  }, []);

  return (
    <div className="defaultRoot" id="grid-workspace">
        <CustomerInfo account={account} customerId={customerId}></CustomerInfo>
        <CustomerQueues account={account} customerId={customerId}></CustomerQueues>
        <FailedConversations account={account}/>
    </div>
  );
};

export default observer(DiagnosticsPanel);
