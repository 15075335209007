import React, { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ArrowIcon from "./icons/arrow.svg";
import { ActionButton } from "./Button";
import { TooltipList } from "./TooltipList";

const Container = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  margin: 0 28px;
  width: 100%;
  height: 100%;
`;

const Button = styled(ActionButton)`
  width: calc(100% - 33px);
  height: 100%;
  border-radius: 6px 0 0 6px;
  margin: 0;
`;

const ExpandButton = styled(ActionButton)`
  border-left: 1px solid #161729;
  border-radius: 0 6px 6px 0;
  flex-shrink: 0;
  padding: 8px;
  height: 100%;
  margin: 0;
`;

interface Props {
  items: { value: number; button: string; label: string }[];
  onChange: (value: number) => void;
  className?: string;
  value: number;
}

export const ChooseButton: FC<Props> = ({ className, onChange, value, items }) => {
  const listRef = useRef<HTMLUListElement>(null);
  const expandRef = useRef<HTMLButtonElement>(null);

  const [item, setItem] = useState(() => items[0]);
  const [isOpen, setOpen] = useState(false);
  const selectItem = (item) => {
    setItem(item);
    onChange(item.value);
    setOpen(false);
  };

  // TODO: Hardcode tooltip position solver
  useEffect(() => {
    if (!expandRef.current || !listRef.current) return;
    const box = expandRef.current.getBoundingClientRect();
    if (box.top + 200 > window.innerHeight) {
      listRef.current.style.top = "-90px";
    } else {
      listRef.current.style.top = "40px";
    }
  }, [isOpen]);

  useEffect(() => {
    const handler = () => setOpen(false);
    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, []);

  useEffect(() => {
    setItem(items.find((pred) => pred.value === value) ?? items[0]);
  }, [value]);

  return (
    <Container className={className} onClick={(e) => e.stopPropagation()}>
      <Button onClick={() => onChange(item.value)}>{item.button}</Button>
      <ExpandButton ref={expandRef} onClick={() => setOpen(true)}>
        <ArrowIcon />
      </ExpandButton>

      <TooltipList ref={listRef} isOpen={isOpen}>
        {items
          .filter((pred) => pred.value !== item.value)
          .map((item) => (
            <li onClick={() => selectItem(item)} key={item.value}>
              {item.label}
            </li>
          ))}
      </TooltipList>
    </Container>
  );
};
