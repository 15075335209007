import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Popup, Icon } from "semantic-ui-react";
import "./styles.css";
import { InboundConfig } from "@dasha.ai/sdk/web/sip/inbound-configs";
import { InboundVoIPEditor } from "./InboundVoIPEditor";
import { VoIPCallReceiver } from "./VoIPCallReceiver";
import Playground from "../core/Playground";
import { Link, useParams } from "react-router-dom";

export const InboundVoIP: FC<{ account: IAccount, ide: Playground }> = ({ account, ide }) => {
  const { customerId } = useParams();
  const [inboundConfigs, setInboundConfigs] = useState<Record<string, InboundConfig>>({});
  const [inboundStatus, setInboundStatus] = useState<Record<string, dasha.sip.reports.EndpointStatus>>({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const a = await account.connectAs(customerId);
      const c = await dasha.sip.inboundConfigs.listConfigs({ account: a });
      setInboundConfigs(c);
      setLoading(false);
    };
    fetchData();
  }, [account]);

  const fetchStatuses = useCallback(() => {
    const fetchDataInternal = async () => {
      const a = await account.connectAs(customerId);

      const result = {};
      for (const endpoint of Object.values(inboundConfigs)) {
        const status = await dasha.sip.reports.getEndpointStatus(endpoint.id, 1, {account: a});
        result[endpoint.id] = status;
      }
      setInboundStatus(result);
    };
    fetchDataInternal().catch();
  }, [account, inboundConfigs]);

  useEffect(() => {
    fetchStatuses();
    const interval = setInterval(() => {
      fetchStatuses();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, [account, inboundConfigs]);

  const onUpdate = useCallback((name: string, value: InboundConfig | undefined) => {
    let copy = { ...inboundConfigs };
    if (value === undefined) {
      delete copy[name];
    } else {
      copy[name] = value;
    }    
    setInboundConfigs(copy);
  }, [inboundConfigs])

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Inbound VoIP</h2>
      <p>
      { customerId === undefined && (<Link to={"/voip/missed"}><h3>Missed calls</h3></Link>) }
      { customerId !== undefined && (<Link to={"/voip/missed/"+customerId}><h3>Missed calls</h3></Link>) }
      </p>
      <VoIPCallReceiver ide={ide}/>
      <Loader active={loading}>Loading..</Loader>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Edit</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Status<Popup content="A link that can be used in monitoring system" trigger={<Icon small className="help" />}/></Table.HeaderCell>
            <Table.HeaderCell>Application Name</Table.HeaderCell>
            <Table.HeaderCell>Group Name</Table.HeaderCell>
            <Table.HeaderCell>Priority</Table.HeaderCell>
            <Table.HeaderCell>Uri</Table.HeaderCell>
            <Table.HeaderCell>Alias</Table.HeaderCell>
            <Table.HeaderCell>Alias Uri</Table.HeaderCell>
            <Table.HeaderCell>IP ACLs</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {Object.entries(inboundConfigs).map(([k, c]) => {
            return (
              <Table.Row negative={inboundStatus[c.id]?.isError === true}>
                <Table.Cell> <InboundVoIPEditor account={account} existingName={k} onUpdate={onUpdate} customerId={customerId}/> </Table.Cell>
                <Table.Cell> {k} </Table.Cell>
                <Table.Cell> <Link to={"https://"+account.server+"/api/v1/sip/endpoint/status/"+c.id} target="_blank" rel="noopener noreferrer">{inboundStatus[c.id]?.status ?? "Unknown"}</Link> </Table.Cell>
                <Table.Cell> {c.applicationName} </Table.Cell>
                <Table.Cell> {c.groupName} </Table.Cell>
                <Table.Cell> {c.priority} </Table.Cell>
                <Table.Cell> {c.uri} </Table.Cell>
                <Table.Cell> {c.alias} </Table.Cell>
                <Table.Cell> {c.aliasUri} </Table.Cell>
                <Table.Cell> {c.ipAcls?.join(",")} </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        { <Table.Footer>
          <Table.Row>
            <InboundVoIPEditor account={account} existingName={undefined} onUpdate={onUpdate} customerId={customerId}/>
          </Table.Row>
        </Table.Footer>}
      </Table>
    </div>
  );
};
