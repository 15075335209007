import * as uuid from "uuid";
import initSqlJs, { SqlJs, Database, Statement } from "@dasha.ai/sql.js";
import isEqual from "lodash/isEqual";
import groupBy from "lodash/groupBy";
import orderBy from "lodash/orderBy";
import mapValues from "lodash/mapValues";

// @ts-ignore
import sqlWasm from "!!file-loader?name=sql-wasm-[contenthash].wasm!@dasha.ai/sql.js/dist/sql-wasm.wasm";

import {
  Profiler,
  SessionFilterRequest,
  NluSttReason,
  NluField,
  MessageLogProfiled,
  ReasonUserActionSetRequest,
  ClusterWithReasonIdAndSttId,
} from "./interfaces";
import { run, all, get } from "./helpers";
import { TransitionInformation, ReasonType, TransitionKey } from "./model/stuff";
import {
  Decision,
  SessionToDecision,
  Reason,
  UnknownReason,
  NlgResponse,
  Cluster,
  Session,
  NlgResponseWithSessionUserAction,
  ReasonWithSessionUserAction,
  TransitionUserAction,
  ReasonUserAction,
  Transition,
  SttOrNlgResponse,
  SttResponse,
  ApplicationInformation,
} from "./model/models";

enum DbTables {
  Decisions = "Decisions",
  Clusters = "Clusters",
  NlgResponses = "NlgResponses",
  NluResponses = "NluResponses",
  Reasons = "Reasons",
  Sessions = "Sessions",
  SessionToDecisions = "SessionToDecisions",
  SttResponses = "SttResponses",
  NlgResponseWithSessionUserActions = "NlgResponseWithSessionUserActions",
  ReasonUserActions = "ReasonUserActions",
  ReasonWithSessionUserActions = "ReasonWithSessionUserActions",
  TransitionUserActions = "TransitionUserActions",
  Transitions = "Transitions",
  SttOrNlgResponses = "SttOrNlgResponses",
  ClustersToSttResponses = "ClustersToSttResponses",
  ApplicationInformation = "ApplicationInformation",
}

const customViewPrefix = "filtered_";

export default class ProfilerStorage implements Profiler {
  private db!: Database;

  private dbTablesSet: Set<string> = new Set();

  private closed = false;

  private sql!: SqlJs;

  private currentPrefix: string;

  private constructor() {
    this.currentPrefix = "";
  }

  async getApplications(): Promise<ApplicationInformation[]> {
    const statement = this.db.prepare(`SELECT * FROM ${DbTables.ApplicationInformation}`);
    return await all<ApplicationInformation>(statement);
  }

  private getById<T>(id: string, prefix: string, table: DbTables): Promise<T | undefined> {
    const statement = this.db.prepare(`SELECT * FROM ${prefix}${table} Where Id = '${id}' LIMIT 1`);

    return get<T>(statement);
  }

  private async getByIds<T>(ids: string[], prefix: string, table: DbTables): Promise<T[]> {
    const statement = this.db.prepare(
      `SELECT * FROM ${prefix}${table} Where Id in (${ids.map((x) => `'${x}'`).join(",")})`
    );

    return await all<T>(statement);
  }

  private async getAll<T>(prefix: string, table: DbTables): Promise<T[]> {
    const statement = this.db.prepare(`SELECT * FROM ${prefix}${table}`);

    return await all<T>(statement);
  }

  async close(): Promise<void> {
    if (this.closed) {
      return;
    }

    this.closed = true;

    await this.dropViewIfExist();
    this.db.close();
  }

  public static async create(db: Uint8Array): Promise<ProfilerStorage> {
    const storage = new ProfilerStorage();

    // TODO: need to optimize sql.js importing (https://gitlab.com/dasha.ai/products/dasha-studio/-/issues/512)
    storage.sql = await initSqlJs({ locateFile: () => sqlWasm });

    storage.db = new storage.sql.Database(db);
    storage.dbTablesSet = new Set(await storage.getDbTables());
    await storage.addColumnIfNotExist(DbTables.Transitions, "SourceNodePath", "TEXT");
    await storage.addColumnIfNotExist(DbTables.Transitions, "TargetNodePath", "TEXT");
    await storage.addColumnIfNotExist(DbTables.Transitions, "TransitionPath", "TEXT");
    await storage.dropViewIfExist();

    return storage;
  }

  public async dispose(): Promise<void> {
    await this.close();
  }

  private async dropViewIfExist(): Promise<void> {
    await Promise.all(
      Object.values(DbTables).map((dbTable) =>
        run(this.db.prepare(`DROP VIEW IF EXISTS ${customViewPrefix}${dbTable}`))
      )
    );
  }

  private async addColumnIfNotExist(table: DbTables, field: string, type: string): Promise<void> {
    const statement = this.db.prepare(
      `SELECT COUNT(*) AS Count FROM pragma_table_info('${table}') WHERE name='${field}'`
    );
    const result = await get<{ count: number }>(statement);

    if (result?.count === 0) {
      await run(this.db.prepare(`ALTER TABLE ${table} ADD COLUMN ${field} ${type}`));
    }
  }

  //#region Apply FilteredContext
  public async setFilteredContext(filter?: SessionFilterRequest): Promise<void> {
    if (this.currentPrefix !== "") {
      await this.dropViewIfExist();

      this.currentPrefix = "";
    }

    if (filter === undefined) {
      return;
    }

    if (
      filter.completedTime !== undefined ||
      filter.startedTime !== undefined ||
      (filter.labels !== undefined && Object.keys(filter.labels).length !== 0)
    ) {
      this.currentPrefix = customViewPrefix;
      //Can change on Create Table but need do index
      await this.сreateViewForSession(filter);
      await this.сreateViewForSessionToDecisions();
      await this.сreateViewForDecisions();
    }
  }

  private сreateViewForDecisions(): Promise<void> {
    return run(
      this.db.prepare(
        `CREATE TEMPORARY VIEW
         ${this.currentPrefix}${DbTables.Decisions} AS
          SELECT Distinct(t.Id),t.ReasonId,t.TransitionId,t.FromNode,t.ToNode,t.Stack,t.TransitionType,t.BlockName
          FROM ${DbTables.Decisions} AS t
          INNER JOIN ${this.currentPrefix}${DbTables.SessionToDecisions} AS t1 ON t1.DecisionId = t.Id`
      )
    );
  }

  private сreateViewForSessionToDecisions(): Promise<void> {
    return run(
      this.db.prepare(
        `CREATE TEMPORARY VIEW
        ${this.currentPrefix}${DbTables.SessionToDecisions} AS
          SELECT t.* FROM ${DbTables.SessionToDecisions} AS t
          INNER JOIN ${this.currentPrefix}${DbTables.Sessions} AS t1 ON t.SessionId = t1.Id`
      )
    );
  }

  private сreateViewForSession(filter: SessionFilterRequest): Promise<void> {
    return run(
      this.db.prepare(
        `CREATE TEMPORARY VIEW
        ${this.currentPrefix}${DbTables.Sessions} AS
          SELECT * FROM ${DbTables.Sessions} ${this.getFilterClause("", filter)}`
      )
    );
  }

  private getFilterClause(prefix: string, filter: SessionFilterRequest): string {
    const whereClause: string[] = [];

    if (filter.startedTime !== undefined) {
      const lower = filter.startedTime.start.getTime();
      const upper = filter.startedTime.end.getTime();

      whereClause.push(`(${prefix}StartedTime >= ${lower} AND ${prefix}StartedTime <= ${upper})`);
    }

    if (filter.completedTime !== undefined) {
      const lower = filter.completedTime.start.getTime();
      const upper = filter.completedTime.end.getTime();

      whereClause.push(`(${prefix}CompletedTime >= ${lower} AND ${prefix}CompletedTime <= ${upper})`);
    }

    if (filter.labels !== undefined && Object.keys(filter.labels).length !== 0) {
      whereClause.push(
        ...Object.entries(filter.labels).map(([key, value]) => `json_extract(${prefix}Labels, '$.${key}') = '${value}'`)
      );
    }

    if (whereClause.length === 0) {
      return "";
    }

    return `WHERE ${whereClause.join(" AND ")}`;
  }
  //#endregion

  //#region Started / Completed Time
  async getMinStartedTime(): Promise<Date> {
    const statement = this.db.prepare(
      `SELECT MIN(StartedTime) AS StartedTime FROM ${this.currentPrefix}${DbTables.Sessions}`
    );
    const result = await get<{ startedTime: number }>(statement);

    if (result === undefined) {
      throw Error("Can`t get minimum for started time");
    }

    return new Date(result.startedTime);
  }

  async getMaxStartedTime(): Promise<Date> {
    const statement = this.db.prepare(
      `SELECT MAX(StartedTime) AS StartedTime FROM ${this.currentPrefix}${DbTables.Sessions}`
    );
    const result = await get<{ startedTime: number }>(statement);

    if (result === undefined) {
      throw Error("Can`t get maximum for started time");
    }

    return new Date(result.startedTime);
  }

  async getMinCompletedTime(): Promise<Date> {
    const statement = this.db.prepare(
      `SELECT MIN(CompletedTime) AS CompletedTime FROM ${this.currentPrefix}${DbTables.Sessions}`
    );
    const result = await get<{ completedTime: number }>(statement);

    if (result === undefined) {
      throw Error("Can`t get minimum for completed time");
    }

    return new Date(result.completedTime);
  }

  async getMaxCompletedTime(): Promise<Date> {
    const statement = this.db.prepare(
      `SELECT MAX(CompletedTime) AS CompletedTime FROM ${this.currentPrefix}${DbTables.Sessions}`
    );
    const result = await get<{ completedTime: number }>(statement);

    if (result === undefined) {
      throw Error("Can`t get maximum for completed time");
    }

    return new Date(result.completedTime);
  }
  //#endregion

  async getTransitionInformations(): Promise<TransitionInformation[]> {
    const statement = this.db.prepare(
      `
    SELECT t1.*, (
      SELECT COALESCE(SUM((
          SELECT COUNT(*)
          FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s
          WHERE d.Id = s.DecisionId)), 0)
      FROM ${this.currentPrefix}${DbTables.Decisions} AS d
      WHERE t1.Id = d.TransitionId) AS DecisionCount, (
      SELECT COALESCE(SUM((
          SELECT COUNT(*)
          FROM (
              SELECT DISTINCT s0.SessionId
              FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0
              WHERE d0.Id = s0.DecisionId
          ) AS t0)), 0)
      FROM ${this.currentPrefix}${DbTables.Decisions} AS d0
      WHERE t1.Id = d0.TransitionId) AS UniqDecisionCount
  FROM ${DbTables.Transitions} AS t1
  `
    );
    const result = await all<TransitionInformation>(statement);

    if (result === undefined) {
      throw Error("Can`t get transition informations");
    }

    result.forEach((x) => this.enrichTransitionInfo(x));

    return result;
  }

  //#region Transition Information
  async getTransitionInformation(request: TransitionKey): Promise<TransitionInformation> {
    const statement = this.db.prepare(
      `SELECT
      (
        SELECT
          SUM((SELECT COUNT(*) FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s
              WHERE d.Id = s.DecisionId))
        FROM ${this.currentPrefix}${DbTables.Decisions} AS d
        WHERE t0.Id = d.TransitionId
      ) AS decisionCount,
      COALESCE((
          SELECT t.Verified FROM ${DbTables.TransitionUserActions} AS t
          WHERE t.TransitionId = t0.Id
          LIMIT 1
        ), false) AS Verified,
      COALESCE((
          SELECT SUM(json_array_length(t2.FalsePositive)) FROM
            (SELECT DISTINCT d0.ReasonId FROM ${this.currentPrefix}${DbTables.Decisions} AS d0
              WHERE t0.Id = d0.TransitionId) AS t1
            LEFT JOIN ${DbTables.ReasonUserActions} AS t2 ON t2.ReasonId = t1.ReasonId
          LIMIT 1
        ), 0) AS falsePositiveCount,
      COALESCE((
          SELECT SUM(json_array_length(t2.FalseNegative)) FROM
            (SELECT DISTINCT d0.ReasonId FROM ${this.currentPrefix}${DbTables.Decisions} AS d0
              WHERE t0.Id = d0.TransitionId) AS t1
            LEFT JOIN ${DbTables.ReasonUserActions} AS t2 ON t2.ReasonId = t1.ReasonId
          LIMIT 1
        ), 0) AS falseNegativeCount,
    t0.* FROM ${DbTables.Transitions} AS t0
    Where ${this.getClauseForDecisionRequest("t0.", request)}`
    );
    const result = await get<TransitionInformation>(statement);

    if (result === undefined) {
      throw Error("Can`t get transition information by this transitionKey");
    }

    this.enrichTransitionInfo(result);

    return result;
  }

  private enrichTransitionInfo(transitionInfo: TransitionInformation | Transition): void {
    transitionInfo.blockPath = "block:" + (transitionInfo.stack ?? "").replace(/\//g, ":");

    if (!transitionInfo.sourceNodePath) {
      transitionInfo.sourceNodePath =
        "node:" + (transitionInfo.stack ?? "").replace(/\//g, ":") + `:${transitionInfo.fromNode}`;
    }

    if (!transitionInfo.targetNodePath) {
      transitionInfo.targetNodePath =
        "node:" + (transitionInfo.stack ?? "").replace(/\//g, ":") + `:${transitionInfo.toNode}`;
    }

    if (!transitionInfo.transitionPath) {
      transitionInfo.transitionPath = transitionInfo.transitionId
        ? "transition:" +
          (transitionInfo.stack ?? "").replace(/\//g, ":") +
          `:${transitionInfo.fromNode}:` +
          transitionInfo.transitionId
        : null;
    }
  }

  private getClauseForDecisionRequest(prefix: string, request: TransitionKey) {
    return `((( ${prefix}SourceNodePath is not NULL AND
      ${prefix}TargetNodePath is not NULL AND
      ${prefix}SourceNodePath = '${request.sourceNodePath}' AND
      ${prefix}TargetNodePath = '${request.targetNodePath}'
      ) OR (
        ${prefix}FromNode = '${request.fromNode}' AND
      ${prefix}ToNode = '${request.toNode}' AND
      ${prefix}BlockName = '${request.blockName}'
      )) AND (
       (${prefix}TransitionPath is not NULL AND
        ${prefix}TransitionPath = '${request.transitionPath}')
        OR (
          ${
            request.transitionId !== null
              ? `${prefix}TransitionId = '${request.transitionId}'`
              : `${prefix}TransitionId is NULL`
          }
        )))
    `;
  }

  async getTransitionsInformation(): Promise<TransitionInformation[]> {
    const statement = this.db.prepare(
      `SELECT
      (
        SELECT
          SUM((SELECT COUNT(*) FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s
              WHERE d.Id = s.DecisionId))
        FROM ${this.currentPrefix}${DbTables.Decisions} AS d
        WHERE t0.Id = d.TransitionId
      ) AS decisionCount,
      COALESCE((
          SELECT t.Verified FROM ${DbTables.TransitionUserActions} AS t
          WHERE t.TransitionId = t0.Id
          LIMIT 1
        ), false) AS Verified,
      COALESCE((
          SELECT SUM(json_array_length(t2.FalsePositive)) FROM
            (SELECT DISTINCT d0.ReasonId FROM ${this.currentPrefix}${DbTables.Decisions} AS d0
              WHERE t0.Id = d0.TransitionId) AS t1
            LEFT JOIN ${DbTables.ReasonUserActions} AS t2 ON t2.ReasonId = t1.ReasonId
          LIMIT 1
        ), 0) AS falsePositiveCount,
      COALESCE((
          SELECT SUM(json_array_length(t2.FalseNegative)) FROM
            (SELECT DISTINCT d0.ReasonId FROM ${this.currentPrefix}${DbTables.Decisions} AS d0
              WHERE t0.Id = d0.TransitionId) AS t1
            LEFT JOIN ${DbTables.ReasonUserActions} AS t2 ON t2.ReasonId = t1.ReasonId
          LIMIT 1
        ), 0) AS falseNegativeCount,
    t0.* FROM ${DbTables.Transitions} AS t0`
    );

    const results = await all<TransitionInformation>(statement);

    for (const result of results) {
      this.enrichTransitionInfo(result);
    }

    return results;
  }

  async getTransitionsByReasonId(reasonId: string): Promise<Transition[]> {
    const statement = this.db.prepare(
      `SELECT DISTINCT(t.Id), t.* FROM ${DbTables.Transitions} as t
      INNER JOIN ${this.currentPrefix}${DbTables.Decisions} as d on d.TransitionId = t.Id
      WHERE d.ReasonId = '${reasonId}'`
    );

    const results = await all<Transition>(statement);

    for (const result of results) {
      this.enrichTransitionInfo(result);
    }

    return results;
  }

  async getTransitionsByReasonSessionId(reasonId: string, sessionId: string): Promise<Transition[]> {
    const statement = this.db.prepare(
      `SELECT DISTINCT(t.Id), t.* FROM ${this.currentPrefix}${DbTables.SessionToDecisions} as std
      INNER JOIN  ${this.currentPrefix}${DbTables.Decisions} as d on d.Id = std.DecisionId
      INNER JOIN ${DbTables.Transitions} as t on t.Id = d.TransitionId
      WHERE d.ReasonId = '${reasonId}' AND std.SessionId = '${sessionId}'`
    );

    const results = await all<Transition>(statement);

    for (const result of results) {
      this.enrichTransitionInfo(result);
    }

    return results;
  }

  async getTransitionsBySttResponseSubString(subString: string): Promise<Transition[]> {
    const statement = this.db.prepare(
      `SElECT DISTINCT(t.Id), t.* FROM (SELECT * FROM ${DbTables.SttResponses}
        WHERE instr(Response ,'${subString}')) as st
        INNER JOIN ${DbTables.Reasons} as r on st.Id = r.SttResponseId
        INNER JOIN ${this.currentPrefix}${DbTables.Decisions} as d on d.ReasonId = r.Id
        INNER JOIN ${DbTables.Transitions} as t on t.Id = d.TransitionId`
    );

    const results = await all<Transition>(statement);

    for (const result of results) {
      this.enrichTransitionInfo(result);
    }

    return results;
  }
  //#endregion

  //TODO: When the filter format is selected, you need to implement its logic
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getDecisions(request: TransitionKey, _filter?: never): Promise<Decision[]> {
    const statement = this.db.prepare(
      `SELECT * FROM  ${this.currentPrefix}${DbTables.Decisions} as d
       INNER JOIN ${DbTables.Transitions}  AS t ON d.TransitionId = t.Id
       Where ${this.getClauseForDecisionRequest("t.", request)}`
    );

    return await all<Decision>(statement);
  }

  //#region get by own Id

  async getDecisionById(id: string): Promise<Decision | undefined> {
    return this.getById<Decision>(id, this.currentPrefix, DbTables.Decisions);
  }

  async getDecisionsByIds(ids: string[]): Promise<Decision[]> {
    return this.getByIds<Decision>(ids, this.currentPrefix, DbTables.Decisions);
  }

  async getCluster(id: string): Promise<Cluster | undefined> {
    return this.getById<Cluster>(id, "", DbTables.Clusters);
  }

  async getClusters(ids: string[]): Promise<Cluster[]> {
    return this.getByIds<Cluster>(ids, "", DbTables.Clusters);
  }

  async getClustersByTransitionKey(request: TransitionKey): Promise<ClusterWithReasonIdAndSttId[]> {
    const statement = this.db.prepare(
      `SELECT c0.Id, c0.Title, c.SttResponseId, d0.ReasonId
      FROM ${DbTables.ClustersToSttResponses} AS c
      INNER JOIN ${DbTables.Clusters} AS c0 ON c.ClusterId = c0.Id
      INNER JOIN ${DbTables.Transitions} as t0 ON t0.Id = c.TransitionId
      INNER JOIN
      ${this.currentPrefix}${DbTables.Decisions} as d0 ON d0.TransitionId = t0.Id
      WHERE ${this.getClauseForDecisionRequest("t0.", request)}`
    );

    return await all<ClusterWithReasonIdAndSttId>(statement);
  }

  async getClustersByTransitionTableId(transitionTableId: string): Promise<Cluster | undefined> {
    const statement = this.db.prepare(
      `SELECT c0.Id, c0.Title
      FROM ${DbTables.ClustersToSttResponses} AS c
      INNER JOIN ${DbTables.Clusters} AS c0 ON c.ClusterId = c0.Id
      WHERE c.TransitionId = '${transitionTableId}'
      LIMIT 1`
    );

    return await get<{ id: string; title: string }>(statement);
  }

  async getSessionsByTransition(request: TransitionKey): Promise<string[]> {
    const statement = this.db.prepare(
      `SELECT DISTINCT(s.Id) as Id FROM ${this.currentPrefix}${DbTables.Sessions} as s
      INNER JOIN ${this.currentPrefix}${DbTables.SessionToDecisions} as std on std.SessionId = s.Id
      INNER JOIN ${this.currentPrefix}${DbTables.Decisions} as d on d.Id = std.DecisionId
      INNER JOIN ${DbTables.Transitions} as t on t.Id = d.TransitionId
      WHERE ${this.getClauseForDecisionRequest("t.", request)}`
    );

    const results = await all<{ id: string }>(statement);

    return results.map((x) => x.id);
  }

  async getSessions(): Promise<Session[]> {
    const statement = this.db.prepare(`SELECT s.* FROM ${this.currentPrefix}${DbTables.Sessions} as s`);
    const results = await all<Session>(statement);

    return results.map((x) => ({
      ...x,
      ...{ jobData: x.jobData ? JSON.parse(x.jobData as string) : undefined },
    }));
  }

  async getSessionById(id: string): Promise<Session | undefined> {
    const session = await this.getById<Session>(id, this.currentPrefix, DbTables.Sessions);

    if (session !== undefined && session?.jobData !== undefined) {
      session.jobData = JSON.parse(session.jobData as string);
    }

    return session;
  }

  async getSessionsByIds(ids: string[]): Promise<Session[]> {
    return (await this.getByIds<Session>(ids, this.currentPrefix, DbTables.Sessions)).map((x) => ({
      ...x,
      ...{ jobData: x.jobData ? JSON.parse(x.jobData as string) : undefined },
    }));
  }

  async getSessionToDecisionById(id: string): Promise<SessionToDecision | undefined> {
    return this.getById<SessionToDecision>(id, this.currentPrefix, DbTables.SessionToDecisions);
  }

  async getSessionToDecisionsByIds(ids: string[]): Promise<SessionToDecision[]> {
    return this.getByIds<SessionToDecision>(ids, this.currentPrefix, DbTables.SessionToDecisions);
  }
  //#endregion

  async getLabelsDictionary(): Promise<Record<string, string[]>> {
    const statement = this.db.prepare(
      `SELECT s1.key, s1.value FROM (Select s.Labels FROM ${DbTables.Sessions} AS s), json_tree(Labels) AS s1
      WHERE s1.key IS NOT NULL)`
    );
    const result = await all<{ key: string; value: string }>(statement);

    const groupByKey = groupBy(result, (x) => x.key);

    return mapValues(groupByKey, (x) => x.map((y) => y.value));
  }

  //#region User Actions
  //#region NlgResponse with session user actions
  async getNlgResponseWithSessionUserActions(): Promise<NlgResponseWithSessionUserAction[]> {
    return this.getAll<NlgResponseWithSessionUserAction>("", DbTables.NlgResponseWithSessionUserActions);
  }

  async getNlgResponseWithSessionUserAction(
    sessionId: string,
    nlgResponseId: string
  ): Promise<NlgResponseWithSessionUserAction | undefined> {
    const statement = this.db.prepare(
      `SELECT * FROM ${DbTables.NlgResponseWithSessionUserActions}
       WHERE SessionId = '${sessionId}' AND NlgResponseId = '${nlgResponseId}'
       LIMIT 1`
    );

    return get<NlgResponseWithSessionUserAction>(statement);
  }

  async setNlgResponseWithSessionUserAction(sessionId: string, nlgResponseId: string, mark: boolean): Promise<void> {
    const statement = this.db.prepare(
      `INSERT OR REPLACE INTO ${DbTables.NlgResponseWithSessionUserActions}(Id,SessionId,NlgResponseId,Mark) VALUES(?,?,?,?)`
    );

    return run(statement, uuid.v4(), sessionId, nlgResponseId, mark);
  }

  //#endregion
  //#region Reason with session user actions
  async getReasonWithSessionUserActions(): Promise<ReasonWithSessionUserAction[]> {
    return this.getAll<ReasonWithSessionUserAction>("", DbTables.ReasonWithSessionUserActions);
  }

  async getReasonWithSessionUserAction(
    sessionId: string,
    reasonId: string
  ): Promise<ReasonWithSessionUserAction | undefined> {
    const statement = this.db.prepare(
      `SELECT * FROM ${DbTables.ReasonWithSessionUserActions}
       WHERE ReasonId = '${reasonId} AND SessionId = '${sessionId}'
       LIMIT 1`
    );

    return get<ReasonWithSessionUserAction>(statement);
  }

  async setReasonWithSessionUserAction(sessionId: string, reasonId: string, mark: boolean): Promise<void> {
    const statement = this.db.prepare(
      `INSERT OR REPLACE INTO ${DbTables.ReasonWithSessionUserActions}(Id,SessionId,ReasonId,Mark) VALUES(?,?,?,?)`
    );

    return run(statement, uuid.v4(), sessionId, reasonId, mark);
  }

  //#endregion
  //#region Transition user Actions
  async getTransitionUserActions(): Promise<TransitionUserAction[]> {
    return this.getAll<TransitionUserAction>("", DbTables.TransitionUserActions);
  }

  async getTransitionUserAction(transition: TransitionKey): Promise<TransitionUserAction | undefined> {
    const statement = this.db.prepare(
      `SELECT ta.* FROM ${DbTables.TransitionUserActions} as ta
       INNER JOIN (
         SELECT * FROM ${DbTables.Transitions} as t0
         WHERE
         ${this.getClauseForDecisionRequest("t0.", transition)} ) AS t ON t.Id = ta.TransitionId
       LIMIT 1`
    );

    return get<TransitionUserAction>(statement);
  }

  async getTransition(transition: TransitionKey): Promise<Transition | undefined> {
    const statement = this.db.prepare(
      `SELECT * FROM ${DbTables.Transitions} as t0
       WHERE ${this.getClauseForDecisionRequest("t0.", transition)}
       LIMIT 1`
    );

    return get<Transition>(statement);
  }

  async getTransitionById(transitionId: string): Promise<Transition | undefined> {
    return this.getById<Transition>(transitionId, "", DbTables.Transitions);
  }

  async setTransitionUserAction(transition: TransitionKey, verified: boolean): Promise<void> {
    const trans = await this.getTransition(transition);

    if (trans === undefined) {
      throw new Error("Not exist such transition");
    }

    const statement = this.db.prepare(
      `INSERT OR REPLACE INTO ${DbTables.TransitionUserActions}(Id,TransitionId,Verified) VALUES(?,?,?)`
    );

    return run(statement, uuid.v4(), trans.id, verified);
  }

  //#endregion
  //#region Reason user action
  async getReasonUserActions(): Promise<ReasonUserAction[]> {
    const result = await this.getAll<ReasonUserAction>("", DbTables.ReasonUserActions);

    result.forEach((x) => {
      x.falseNegative = JSON.parse(x.falseNegative as unknown as string);
      x.falsePositive = JSON.parse(x.falsePositive as unknown as string);
    });

    return result;
  }

  async getReasonUserActionByReasonId(reasonId: string): Promise<ReasonUserAction | undefined> {
    const statement = this.db.prepare(
      `SELECT * FROM ${DbTables.ReasonUserActions}
       WHERE ReasonId = '${reasonId}'
       LIMIT 1`
    );

    const result = await get<ReasonUserAction>(statement);

    if (result !== undefined) {
      result.falseNegative = JSON.parse(result.falseNegative as unknown as string);
      result.falsePositive = JSON.parse(result.falsePositive as unknown as string);
    }

    return result;
  }

  async getReasonUserActionsByReasonIds(reasonIds: string[]): Promise<ReasonUserAction[]> {
    const statement = this.db.prepare(
      `SELECT * FROM ${DbTables.ReasonUserActions}
       WHERE ReasonId in (${reasonIds.map((x) => `'${x}'`).join(",")})`
    );

    const result = await all<ReasonUserAction>(statement);

    result.forEach((x) => {
      x.falseNegative = JSON.parse(x.falseNegative as unknown as string);
      x.falsePositive = JSON.parse(x.falsePositive as unknown as string);
    });

    return result;
  }

  async setReasonUserActions(actions: ReasonUserActionSetRequest[]) {
    const userActions = await this.getReasonUserActionsByReasonIds(actions.map((x) => x.reasonId));
    const userActionsMap = new Map<string, ReasonUserAction>(userActions.map((x) => [x.reasonId, x]));
    const array: string[] = [];

    for (const action of actions) {
      const fact = action.fact;
      const userAction: ReasonUserAction = userActionsMap.get(action.reasonId) ?? {
        id: uuid.v4(),
        reasonId: action.reasonId,
        falsePositive: [],
        falseNegative: [],
      };

      if (action.type === "FalsePositive") {
        if (userAction.falsePositive.every((x) => !isEqual(x, fact))) {
          userAction.falsePositive.push(fact);
        }
      } else {
        if (userAction.falseNegative.every((x) => !isEqual(x, fact))) {
          userAction.falseNegative.push(fact);
        }
      }

      const falsePositive = JSON.stringify(userAction.falsePositive);
      const falseNegative = JSON.stringify(userAction.falseNegative);

      array.push(`('${userAction.id}','${userAction.reasonId}','${falsePositive}','${falseNegative}')`);
    }

    if (array.length === 0) {
      return;
    }

    const statement = this.db.prepare(
      `INSERT OR REPLACE INTO ${
        DbTables.ReasonUserActions
      }(Id,ReasonId,FalsePositive,FalseNegative) VALUES ${array.join(",")}`
    );

    return run(statement);
  }

  async unsetReasonUserActions(actions: ReasonUserActionSetRequest[]): Promise<void> {
    const userActions = await this.getReasonUserActionsByReasonIds(actions.map((x) => x.reasonId));
    const userActionsMap = new Map<string, ReasonUserAction>(userActions.map((x) => [x.reasonId, x]));
    const array: string[] = [];

    for (const action of actions) {
      const fact = action.fact;
      const reasonId = action.reasonId;
      const userAction = userActionsMap.get(reasonId);

      if (userAction === undefined) {
        continue;
      }

      if (action.type === "FalsePositive") {
        userAction.falsePositive = userAction.falsePositive.filter((x) => !isEqual(x, fact));
      } else {
        userAction.falseNegative = userAction.falseNegative.filter((x) => !isEqual(x, fact));
      }

      const falsePositive = JSON.stringify(userAction.falsePositive);
      const falseNegative = JSON.stringify(userAction.falseNegative);

      array.push(`('${userAction.id}','${userAction.reasonId}','${falsePositive}','${falseNegative}')`);
    }

    if (array.length === 0) {
      return;
    }

    const statement = this.db.prepare(
      `INSERT OR REPLACE INTO ${
        DbTables.ReasonUserActions
      }(Id,ReasonId,FalsePositive,FalseNegative) VALUES ${array.join(",")}`
    );

    return run(statement);
  }

  //#endregion
  //#endregion

  async getReasons(request: TransitionKey): Promise<Reason[]> {
    const statement = this.db.prepare(
      `SELECT r.* FROM ${DbTables.Reasons} AS r
           WHERE EXISTS (
            SELECT 1 FROM ${this.currentPrefix}${DbTables.Decisions} AS d
            INNER JOIN ${DbTables.Transitions}  AS t ON d.TransitionId = t.Id
            WHERE
              (r.Id = d.ReasonId)
              AND ${this.getClauseForDecisionRequest("t.", request)}
          )`
    );

    return await all<Reason>(statement);
  }

  async getSttResponseByReasonId(reasonId: string): Promise<SttResponse | undefined> {
    const query = `
      SELECT s.Response, s.ClusterId, s.Id
        FROM ${DbTables.Reasons} AS r
        INNER JOIN ${DbTables.SttResponses} AS s ON r.SttResponseId = s.Id
        WHERE (r.Type = ${ReasonType.SttWithNluReason})
        AND (r.Id = '${reasonId}')
        LIMIT 1`;
    const statement = this.db.prepare(query);

    return await get<SttResponse>(statement);
  }

  async getUnknownReasons(request: TransitionKey): Promise<UnknownReason[]> {
    const statement = this.db.prepare(
      `SELECT r.Id, r.Type, r.Reason FROM ${DbTables.Reasons} AS r
           WHERE (r.Type = 0)
            AND EXISTS (
            SELECT 1 FROM ${this.currentPrefix}${DbTables.Decisions} AS d
            INNER JOIN ${DbTables.Transitions}  AS t ON d.TransitionId = t.Id
            WHERE (r.Id = d.ReasonId)
              AND ${this.getClauseForDecisionRequest("t.", request)})`
    );

    const result = await all<UnknownReason>(statement);

    result.forEach((x) => {
      x.reason = JSON.parse(x.reason as unknown as string);
    });

    return result;
  }

  async getDbTables(): Promise<string[]> {
    const statement = this.db.prepare(`SELECT name as Name FROM sqlite_master WHERE type='table'`);
    const result = await all<{ name: string }>(statement);

    return result.map((x) => x.name);
  }

  hasClustersToResponsesTable(): boolean {
    return this.dbTablesSet.has(DbTables.ClustersToSttResponses);
  }

  // TODO: When the filter format is selected, you need to implement its logic
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getNluSttReasons(request: TransitionKey, _filter?: never): Promise<NluSttReason[]> {
    // TODO: need to create separate functions with queries grouped by meaning (#497)
    const statement = this.db.prepare(
      `SELECT r.Id, r.NluResponseId, r.SttResponseId, n.Response as NluResponse, s.Response as SttResponse, s.ClusterId
           FROM ${DbTables.Reasons} AS r
           INNER JOIN ${DbTables.NluResponses}  AS n ON r.NluResponseId = n.Id
           INNER JOIN ${DbTables.SttResponses}  AS s ON r.SttResponseId = s.Id
           WHERE (r.Type = ${ReasonType.SttWithNluReason} )
            AND EXISTS (
            SELECT 1 FROM ${this.currentPrefix}${DbTables.Decisions} AS d
            INNER JOIN ${DbTables.Transitions}  AS t ON d.TransitionId = t.Id
            WHERE (r.Id = d.ReasonId)
              AND ${this.getClauseForDecisionRequest("t.", request)})`
    );

    const result = await all<NluSttReason>(statement);

    result.forEach((x) => {
      x.nluResponse = JSON.parse(x.nluResponse as unknown as string);
    });

    return result;
  }

  async getNluSttReasonByDecisionId(decisionId: string): Promise<NluSttReason | undefined> {
    const statement = this.db.prepare(
      `SELECT r.Id, r.NluResponseId, r.SttResponseId, n.Response as NluResponse, s.Response as SttResponse, s.ClusterId
           FROM ${DbTables.Reasons} AS r
           INNER JOIN ${DbTables.NluResponses}  AS n ON r.NluResponseId = n.Id
           INNER JOIN ${DbTables.SttResponses}  AS s ON r.SttResponseId = s.Id
           INNER JOIN ${this.currentPrefix}${DbTables.Decisions}  AS d ON (r.Id = d.ReasonId) AND (d.Id = '${decisionId}')
           WHERE (r.Type = ${ReasonType.SttWithNluReason} )
           LIMIT 1`
    );

    const result = await get<NluSttReason>(statement);

    if (result !== undefined) {
      result.nluResponse = JSON.parse(result.nluResponse as unknown as string);
    }

    return result;
  }

  async getSessionsByReasonId(reasonId: string): Promise<Session[]> {
    const statement = this.db.prepare(
      `SELECT s.* FROM ${this.currentPrefix}${DbTables.Sessions} AS s 
           WHERE EXISTS (
            SELECT 1 FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0 
              INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d ON s0.DecisionId = d.Id 
            WHERE (s.Id = s0.SessionId) AND (d.ReasonId = '${reasonId}'))`
    );

    return (await all<Session>(statement)).map<Session>((x) => ({
      ...x,
      ...{ jobData: x.jobData ? JSON.parse(x.jobData as string) : undefined },
    }));
  }

  async getSessionIdsByReasonId(reasonId: string): Promise<string[]> {
    const statement = this.db.prepare(
      `SELECT s.Id FROM ${this.currentPrefix}${DbTables.Sessions} AS s 
           WHERE EXISTS (
            SELECT 1 FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0 
              INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d ON s0.DecisionId = d.Id 
            WHERE (s.Id = s0.SessionId) AND (d.ReasonId = '${reasonId}'))`
    );

    return (await all<{ id: string }>(statement)).map<string>((x) => x.id);
  }

  async getSessionsByReasonIdAndTransition(
    reasonId: string,
    transition: TransitionKey
  ): Promise<(Session & { voiceSegmentId: string; sttResponseId: string })[]> {
    const statement = this.db
      .prepare(`SELECT s.Id, s.CompletedTime, s.Labels, s.StartedTime, t.VoiceSegmentId, t.SttResponseId
              FROM ${this.currentPrefix}${DbTables.Sessions} AS s
              INNER JOIN (
                SELECT DISTINCT d.ReasonId, s0.SessionId,  songr.VoiceSegmentId,  songr.SttResponseId
                FROM  ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0
                INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d
                  ON s0.DecisionId = d.Id
                INNER JOIN ${DbTables.SttOrNlgResponses} AS songr
                  ON songr.SessionToDecisionId = s0.Id
                INNER JOIN ${DbTables.Transitions} AS c ON d.TransitionId = c.Id
                WHERE d.ReasonId = '${reasonId}' AND songr.VoiceSegmentId is not NULL
                AND ${this.getClauseForDecisionRequest("c.", transition)}
              ) AS t ON s.Id = t.SessionId `);

    return await all<Session & { voiceSegmentId: string; sttResponseId: string }>(statement);
  }

  async getSessionsByReasonIdAndTransitionTableId(
    reasonId: string,
    transitionTableId: string
  ): Promise<(Session & { voiceSegmentId: string; sttResponseId: string })[]> {
    try {
      const statement = this.db.prepare(`SELECT s.*, t.VoiceSegmentId, t.SttResponseId
              FROM ${this.currentPrefix}${DbTables.Sessions} AS s 
              INNER JOIN (
                SELECT DISTINCT d.ReasonId, s0.SessionId,  songr.VoiceSegmentId,  songr.SttResponseId
                FROM  ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0 
                INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d 
                  ON s0.DecisionId = d.Id
                INNER JOIN ${DbTables.SttOrNlgResponses} AS songr 
                  ON songr.SessionToDecisionId = s0.Id 
                WHERE d.ReasonId = '${reasonId}' AND d.TransitionId = '${transitionTableId}'
              ) AS t ON s.Id = t.SessionId `);

      const results = await all<Session & { voiceSegmentId: string; sttResponseId: string }>(statement);

      return results.map((x) => ({
        ...x,
        ...{ jobData: x.jobData ? JSON.parse(x.jobData as string) : undefined },
      }));
    } catch (e) {
      console.log(e);
      return [];
    }
  }

  async getSessionsByReasonIds(reasonIds: string[], transition: TransitionKey): Promise<Record<string, Session[]>> {
    const statement = this.db.prepare(`SELECT s.*, t.ReasonId
              FROM ${this.currentPrefix}${DbTables.Sessions} AS s 
              INNER JOIN (
                SELECT DISTINCT d.ReasonId, s0.SessionId 
                FROM  ${this.currentPrefix}${DbTables.SessionToDecisions} AS s0 
                INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d 
                  ON s0.DecisionId = d.Id 
                INNER JOIN ${DbTables.Transitions} AS c ON d.TransitionId = c.Id  
                WHERE d.ReasonId IN (${reasonIds.map((x) => `'${x}'`).join(",")}) 
                AND ${this.getClauseForDecisionRequest("c.", transition)}
              ) AS t ON s.Id = t.SessionId `);

    const result = (await all<Session & { reasonId: string }>(statement)).map((x) => ({
      ...x,
      ...{ jobData: JSON.parse(x.jobData as string) },
    }));

    return groupBy(result, (x) => x.reasonId) as Record<string, Session[]>;
  }

  async getSessionToDecisionsByTupleSessionIdWithReasonId(
    sessionId: string,
    reasonId: string
  ): Promise<SessionToDecision[]> {
    const statement = this.db.prepare(
      `SELECT s.* FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s
        INNER JOIN ${this.currentPrefix}${DbTables.Decisions} AS d ON s.DecisionId = d.Id
        WHERE s.SessionId = '${sessionId}' AND d.ReasonId = '${reasonId}'`
    );

    return await all<SessionToDecision>(statement);
  }

  async getSessionToDecisionsBySessionId(sessionId: string): Promise<SessionToDecision[]> {
    const statement = this.db.prepare(
      `SELECT s.* FROM ${this.currentPrefix}${DbTables.SessionToDecisions} AS s
        WHERE s.SessionId = '${sessionId}'`
    );

    return await all<SessionToDecision>(statement);
  }

  getNluResponseFieldValues(field: NluField.Data, type: "Value"): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.Data, type: "Field"): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.Intent): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.SentenceType): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.Skill): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.State): Promise<string[]>;

  getNluResponseFieldValues(field: NluField.FactType): Promise<string[]>;

  async getNluResponseFieldValues(field: NluField, ...args: any[]): Promise<string[]> {
    let statement: Statement;
    const sqlPart = `SELECT DISTINCT(json_extract(t1.value, '$.${field}')) AS value
    FROM (
      SELECT json_extract(response, '$.NluResponse') AS t
      FROM ${DbTables.NluResponses}), json_each(t) AS t1`;

    if (NluField.Data !== field) {
      statement = this.db.prepare(sqlPart);
    } else {
      const argsValue = args[0] === "Value" ? "value" : "key";

      statement = this.db.prepare(
        `SELECT DISTINCT(t3.${argsValue})
         FROM (
          ${sqlPart}, json_each(t2) AS t3`
      );
    }

    const result = await all<{ value: string }>(statement);

    return result.map((x) => x.value);
  }

  async getNluStt(): Promise<NluSttReason[]> {
    // TODO: need to create separate functions with queries grouped by meaning (#497)
    const statement = this.db.prepare(
      `SELECT r.Id, r.NluResponseId, r.SttResponseId, n.Response as NluResponse, s.Response as SttResponse, s.ClusterId
           FROM ${DbTables.Reasons} AS r
           INNER JOIN ${DbTables.NluResponses}  AS n ON r.NluResponseId = n.Id
           INNER JOIN ${DbTables.SttResponses}  AS s ON r.SttResponseId = s.Id
           WHERE (r.Type = ${ReasonType.SttWithNluReason} ) `
    );

    const result = await all<NluSttReason>(statement);

    result.forEach((x) => {
      x.nluResponse = JSON.parse(x.nluResponse as unknown as string);
    });

    return result;
  }

  getSttOrNlgResponseById(id: string): Promise<SttOrNlgResponse | undefined> {
    return this.getById<SttOrNlgResponse>(id, "", DbTables.SttOrNlgResponses);
  }

  getSttOrNlgResponsesByIds(ids: string[]): Promise<SttOrNlgResponse[]> {
    return this.getByIds<SttOrNlgResponse>(ids, "", DbTables.SttOrNlgResponses);
  }

  async getSttOrNlgResponsesByTupleSessionIdWithSttId(sessionId: string, sttId: string): Promise<SttOrNlgResponse[]> {
    const statement = this.db.prepare(
      `SELECT * FROM  ${DbTables.SttOrNlgResponses} as d
       WHERE d.SessionId='${sessionId}' AND d.SttId='${sttId}'`
    );

    return await all<SttOrNlgResponse>(statement);
  }

  async getSttOrNlgResponsesByTupleSessionIdWithNlgId(sessionId: string, nlgId: string): Promise<SttOrNlgResponse[]> {
    const statement = this.db.prepare(
      `SELECT * FROM  ${DbTables.SttOrNlgResponses} as d
       WHERE d.SessionId='${sessionId}' AND d.NlgId='${nlgId}'`
    );

    return await all<SttOrNlgResponse>(statement);
  }

  async getSttOrNlgResponsesBySessionId(sessionId: string): Promise<SttOrNlgResponse[]> {
    const statement = this.db.prepare(
      `SELECT * FROM  ${DbTables.SttOrNlgResponses} as d
       WHERE d.SessionId='${sessionId}'`
    );

    return await all<SttOrNlgResponse>(statement);
  }

  async getSttOrNlgResponsesBySessionToDecisionId(sessionToDecisionId: string): Promise<SttOrNlgResponse[]> {
    const statement = this.db.prepare(
      `SELECT * FROM  ${DbTables.SttOrNlgResponses} as d
       WHERE d.SessionToDecisionId='${sessionToDecisionId}'`
    );

    return await all<SttOrNlgResponse>(statement);
  }

  async getLog(sessionId: string): Promise<MessageLogProfiled[]> {
    const sttOrNlgResponse = await this.getSttOrNlgResponsesBySessionId(sessionId);
    const messageLogs: MessageLogProfiled[] = [];
    const nlgResponses = await this.getByIds<NlgResponse>(
      sttOrNlgResponse.map((x) => x.nlgResponseId).filter((x) => x !== null && x !== undefined) as string[],
      "",
      DbTables.NlgResponses
    );

    const mapNlgResponse = new Map<string, NlgResponse>(nlgResponses.map((x) => [x.id, x]));

    const sttResponses = await this.getByIds<SttResponse>(
      sttOrNlgResponse.map((x) => x.sttResponseId).filter((x) => x !== null && x !== undefined) as string[],
      "",
      DbTables.SttResponses
    );

    const mapSttResponse = new Map<string, SttResponse>(sttResponses.map((x) => [x.id, x]));

    for (const item of orderBy(sttOrNlgResponse, (x) => x.dateTime)) {
      this.tryAddNlgResponseToMessageLog(item, mapNlgResponse, messageLogs);
      await this.tryAddSttResponseToMessageLog(item, mapSttResponse, messageLogs);
    }

    return messageLogs;
  }

  private tryAddNlgResponseToMessageLog(
    item: SttOrNlgResponse,
    mapNlgResponse: Map<string, NlgResponse>,
    messageLogs: MessageLogProfiled[]
  ) {
    if (item.nlgResponseId !== undefined && item.nlgResponseId !== null) {
      const nlgResponse = mapNlgResponse.get(item.nlgResponseId);

      if (nlgResponse !== undefined) {
        messageLogs.push({
          time: new Date(item.dateTime).toISOString(),
          incoming: false,
          msg: nlgResponse.response,
          voiceSegmentId: null,
          nlgResponseId: nlgResponse.id,
        });
      }
    }
  }

  private async tryAddSttResponseToMessageLog(
    item: SttOrNlgResponse,
    mapSttResponse: Map<string, SttResponse>,
    messageLogs: MessageLogProfiled[]
  ): Promise<void> {
    if (item.sttResponseId !== undefined && item.sttResponseId !== null) {
      const sttResponse = mapSttResponse.get(item.sttResponseId);

      if (sttResponse !== undefined) {
        let reasonId: string | undefined = undefined;
        let transition: Transition | undefined = undefined;
        if (item.sessionToDecisionId !== undefined && item.sessionToDecisionId !== null) {
          const sessionToDecision = await this.getSessionToDecisionById(item.sessionToDecisionId);

          if (sessionToDecision !== undefined) {
            const decision = await this.getDecisionById(sessionToDecision.decisionId);
            if (decision?.transitionId !== undefined && decision?.transitionId !== null) {
              transition = await this.getTransitionById(decision?.transitionId);
            }
            
            reasonId = decision?.reasonId;
          }
        }

        messageLogs.push({
          time: new Date(item.dateTime).toISOString(),
          incoming: true,
          msg: sttResponse.response,
          voiceSegmentId: item.voiceSegmentId as number,
          reasonId: reasonId,
          sttResponseId: sttResponse.id,
          typeofSpeech: item.typeOfSttResponse,
          transition: transition ? {
            from: transition.fromNode,
            to: transition.toNode,
            type: "goto"
          } : undefined
        });
      }
    }
  }
}
