import ApplicationApi from "./application";
import ParaphraseApi from "./paraphrase";
import DeployApi from "./deploy";
import ShareApi from "./share";

class PlatformApi {
  readonly share = new ShareApi();
  readonly deploy = new DeployApi();
  readonly paraphrase = new ParaphraseApi();
  readonly deployedApp = new ApplicationApi();
}

export default new PlatformApi();
