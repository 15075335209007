import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
import { makeObservable, observable, runInAction, toJS } from "mobx";
import { csvToArray, uploadFile } from "./helpers";

class ConversationSetup {
  readonly element = document.createElement("div");
  readonly editor: monaco.editor.IStandaloneCodeEditor;
  public isInvalid = false;

  constructor() {
    makeObservable(this, {
      isInvalid: observable,
    });

    this.element.style.width = "100%";
    this.element.style.height = "100%";
    this.editor = monaco.editor.create(this.element, {
      theme: "dashaTheme",
      automaticLayout: true,
      fixedOverflowWidgets: true,
      minimap: { enabled: false },
      tabSize: 2,
      scrollbar: {
        horizontalScrollbarSize: 8,
        verticalScrollbarSize: 8,
      },
    });

    const uri = monaco.Uri.file("config.json");
    const model = monaco.editor.createModel("", "json", uri);
    this.editor.setModel(model);

    monaco.editor.onDidChangeMarkers(() =>
      runInAction(() => {
        const markers = monaco.editor.getModelMarkers({ resource: uri });
        this.isInvalid = markers.length > 0;
      })
    );
  }
  
  dispose() {
    this.editor.getModel()?.dispose();
    this.editor.dispose();
  }

  async uploadFile() {
    const file = await uploadFile();
    let data: Record<string, any>;

    if (file.format === "csv") data = csvToArray(file.content);
    else data = JSON.parse(file.content);

    this.editor.getModel()?.setValue(JSON.stringify(data, null, 2));
  }

  getConfig() {
    try {
      return JSON.parse(this.editor.getModel()?.getValue() ?? "[]");
    } catch {
      return [];
    }
  }

  setSchema(schema: any) {
    const uri = this.editor.getModel()?.uri.toString();
    if (uri == null) return;

    const arraySchema = {
      type: "array",
      items: toJS(schema),
    };

    const scheme = {};
    const props = schema.properties ?? {};
    Object.entries<any>(props).forEach(([key, prop]) => {
      scheme[key] = prop.type;
    });

    this.editor.getModel()?.setValue(JSON.stringify([scheme], null, 2));

    monaco.languages.json.jsonDefaults.setDiagnosticsOptions({
      validate: true,
      // @ts-ignore
      trailingCommas: "error",
      enableSchemaRequest: true,
      schemas: [
        {
          fileMatch: ["config.json"],
          schema: arraySchema,
          uri,
        },
      ],
    });
  }
}

export default ConversationSetup;
