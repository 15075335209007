import React, { FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";

import Profiler from "@core/profiler/ProfilerStorage";
import Playground from "../core/Playground";
import GridLayout from "../core/misc/GridLayout";
import Keyboard from "../core/misc/KeyboardManager";
import Gutter from "../uikit/Layout/Gutter";
import { GroupPanel } from "../uikit";

import StatisticsPanel from "../ProfilerPanel/components/StatisticsPanel";
import DialogPanel from "../RunnerPanel/DialogPanel";
import EditorPanel from "../EditorPanel";
import HeaderPanel from "../HeaderPanel";
import RunnerPanel from "../RunnerPanel";
import TerminalPanel from "../TerminalPanel";
import VersionsPanel from "../VersionsPanel";
import FilesPanel from "../FilesPanel";
import * as S from "./styled";

const WorkspaceView: FC<{ ide: Playground }> = ({ ide }) => {
  const [layout] = useState<GridLayout>(() => new GridLayout());
  const [tabs, setTabs] = useState<string[]>([]);
  const project = ide.workspace.project;

  useEffect(() => {
    const tabs = ["RANGE STATISTICS", "OUTPUT", "VERSIONS"];
    setTabs(ide.workspace.opened instanceof Profiler ? tabs : tabs.slice(1));
  }, [ide.workspace.opened]);

  useEffect(() => {
    Keyboard.addShiftShortcut("f", () => layout.toggleFullScreen());
    Keyboard.addShiftShortcut("u", () => layout.toggleCollapse("runner"));
    Keyboard.addShiftShortcut("p", () => layout.toggleCollapse("files"));
    Keyboard.addShiftShortcut("o", () => layout.toggleCollapse("tools"));
    Keyboard.addShortcut("s", () => ide.workspace.project?.updateRemote());
    layout.bindGrid("#grid-workspace");
    return () => Keyboard.clearShortcuts();
  }, []);

  return (
    <S.Workspace id="grid-workspace">
      <HeaderPanel id="header" accounts={ide.accounts} workspace={ide.workspace} explorer={ide.explorer} />
      <EditorPanel id="editor" explorer={ide.explorer} workspace={ide.workspace} layout={layout} />

      {ide.workspace.opened instanceof Profiler ? (
        <DialogPanel
          id="runner"
          workspace={ide.workspace}
          session={ide.workspace.opened.session}
          isOpen={layout.isExpanded("runner")}
          layout={layout}
          allowTransitionsDebug={true}
        />
      ) : (
        <RunnerPanel
          id="runner"
          workspace={ide.workspace}
          audioController={ide.sip}
          isOpen={layout.isExpanded("runner")}
          onChangeOpen={() => layout.toggleCollapse("runner")}
        />
      )}

      <FilesPanel id="files" workspace={ide.workspace} isOpen={layout.isExpanded("files")} />

      <GroupPanel id="tools" tabs={tabs}>
        {ide.workspace.opened instanceof Profiler && <StatisticsPanel profiler={ide.workspace.opened} />}
        <TerminalPanel workspace={ide.workspace} />
        {project?.metadata.isEditable && <VersionsPanel workspace={ide.workspace} deploys={ide.deploys} />}
      </GroupPanel>

      <Gutter
        id="gutter1"
        disabled={layout.isDisabled("files")}
        onMove={(dir, init) => layout.resize("files", dir, init)}
      />

      <Gutter
        id="gutter2"
        disabled={layout.isDisabled("runner")}
        onMove={(dir, init) => layout.resize("runner", -dir, init)}
      />

      <Gutter
        vertical
        id="gutter3"
        disabled={layout.isDisabled("tools")}
        onMove={(dir, init) => layout.resize("tools", dir, init)}
      />
    </S.Workspace>
  );
};

export default observer(WorkspaceView);
