import styled from "styled-components";

export const Workspace = styled.div`
  --gutter-size: 1px;

  display: grid;
  height: 100vh;
  grid-template-columns: 1px minmax(0, 1fr) 1px var(--runner);
  grid-template-rows: 64px minmax(0, 1fr) 1px var(--tools);
  grid-template-areas:
    "header header header header"
    "gutter1 editor gutter2 runner"
    "gutter1 gutter3 gutter3 gutter3"
    "gutter1 tools tools tools";

  #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #editor {
    grid-area: editor;
  }

  #runner {
    grid-area: runner;
  }

  #tools {
    grid-area: tools;
    z-index: 1;
  }
`;
