import Workspace from "@core/workspace/Workspace";
import { action, makeObservable, observable, runInAction } from "mobx";

import api from "../account/api";
import { DeployResponse } from "../account/api/deploy";
import Project from "../explorer/Project";
import UIManager from "../misc/UIManager";
import Deploy from "./Deploy";

class DeployStorage {
  deploys: Deploy[] = [];

  private _timer: NodeJS.Timer;
  private isDisposed = false;

  constructor(readonly project: Project, readonly workspace: Workspace) {
    makeObservable(this, {
      deploys: observable,
      createDeploy: action,
      deleteDeploy: action,
      deploy: action,
    });

    void this.load();
  }

  getDeploy(version: string): Deploy | null {
    return this.deploys.find((dep) => dep.version == version) ?? null;
  }

  async load() {
    const deploys = await api.deploy.projectDeploys(this.project.id, this.project.account);
    deploys.forEach((dep: DeployResponse) => this.createDeploy(dep));
    this._timer = setTimeout(() => this.updateStatuses(), 3000);
  }

  async createDeploy(dep: DeployResponse) {
    const instance = await Deploy.load(dep, this.project);
    this.deploys.push(instance);
  }

  async deleteDeploy(id: string) {
    const deploy = this.deploys.find((dep) => dep.id === id);
    if (deploy == null) return;

    try {
      this.deploys = this.deploys.filter((dep) => dep.id !== id);
      await api.deploy.stop(id, this.project.account);
      await api.deploy.delete(id, this.project.account);
      return true;
    } catch (e) {
      console.error(e);
      runInAction(() => this.deploys.push(deploy));
      UIManager.notice("There must be at least 5 minutes between initialization and deployment abort. try later");
      return false;
    }
  }

  async deploy(version: string) {
    const dslStorage = this.workspace.storages.dsl;
    if (dslStorage == null) return;

    await dslStorage.syncGraphService();
    await this.workspace.runtime?.generateDeploy();
    await this.workspace.runtime?.syncFiles(dslStorage.graphService);

    await this.project.updateRemote();
    const oldDeploy = this.getDeploy(version);
    if (oldDeploy) await this.deleteDeploy(oldDeploy.id);
    const deploy = await Deploy.create(version, this.project);

    runInAction(() => {
      this.deploys.push(deploy);
      deploy.start();
    });

    await this.project.toggleReadonlyVersion(version, true);
  }

  async updateStatuses() {
    await Promise.all(
      Object.values(this.project.versions).map(async (version) => {
        const deploy = this.getDeploy(version.name);
        await deploy?.updateStatus();
        await this.project.toggleReadonlyVersion(version.name, deploy?.isActive || false);
      })
    );

    if (this.isDisposed) return;
    this._timer = setTimeout(() => this.updateStatuses(), 3000);
  }

  dispose() {
    this.isDisposed = true;
    clearInterval(this._timer);
  }
}

export default DeployStorage;
