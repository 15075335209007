import { makeAutoObservable, runInAction } from "mobx";
import { ApplicationInfo, ConversationsResult } from "../account/api/application";
import { DeployResponse, DeployStatusEnum } from "../account/api/deploy";
import { IAccount } from "../account/interface";
import Project from "../explorer/Project";
import api from "../account/api";

interface Options {
  account: IAccount;
  status: DeployStatusEnum;
  id: string;
  version: string;
  message: string;
  endpoint: string;
}

class Deploy {
  conversations: ConversationsResult[] = [];
  application?: ApplicationInfo;
  status: DeployStatusEnum;

  endpoint: string;
  account: IAccount;

  id: string;
  message: string;
  version: string;
  isLoading = false;

  constructor(options: Options) {
    this.id = options.id;
    this.endpoint = options.endpoint;
    this.account = options.account;
    this.version = options.version;
    this.message = options.message;
    this.status = options.status;

    makeAutoObservable(this, { account: false });
    if (this.status === DeployStatusEnum.Running) {
      void this.updateApplication();
    }
  }

  get isActive() {
    return this.status === DeployStatusEnum.Pending || this.status === DeployStatusEnum.Running;
  }

  async updateStatus() {
    const status = await api.deploy.status(this.id, this.account);

    if (status.deploymentStatus === DeployStatusEnum.Running && this.application == null) {
      void this.updateApplication();
    }

    if (this.endpoint != null) {
      const conversations = await api.deployedApp.conversations(this.endpoint, this.account).catch(() => []);
      runInAction(() => (this.conversations = conversations));
    }

    await runInAction(async () => {
      this.status = status.deploymentStatus;
      this.message = status.message;
    });
  }

  async updateApplication() {
    const apps = await api.deployedApp.applications(this.endpoint, this.account);
    runInAction(() => (this.application = apps[0]));
  }

  *start() {
    try {
      this.status = DeployStatusEnum.Pending;
      const server = yield api.deploy.start(this.id, this.account);
      this.endpoint = server;
    } catch (e) {
      this.status = DeployStatusEnum.Failed;
    }
  }

  async runConversations(convs: Record<string, any>[]) {
    if (this.application == null) return;

    const data = {
      applicationId: this.application.applicationId,
      conversationsData: convs.map((input) => ({ input })),
    };

    await api.deployedApp.runConversations(this.endpoint, data, this.account);
  }

  static async load(config: DeployResponse, project: Project) {
    const [status, endpoint] = await Promise.all([
      api.deploy.status(config.id, project.account),
      api.deploy.url(config.id, project.account),
    ]);

    return new Deploy({
      endpoint,
      id: config.id,
      message: status.message,
      version: config.relativePath,
      status: status.deploymentStatus,
      account: project.account,
    });
  }

  static async create(version: string, project: Project) {
    const id = await api.deploy.create(project.id, version, project.account);
    const [config, status, endpoint] = await Promise.all([
      api.deploy.get(id, project.account),
      api.deploy.status(id, project.account),
      api.deploy.url(id, project.account),
    ]);

    return new Deploy({
      endpoint,
      id: config.id,
      message: status.message,
      version: config.relativePath,
      status: status.deploymentStatus,
      account: project.account,
    });
  }
}

export default Deploy;
