import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import dayjs from "dayjs";

import { formatAudioTime, getAudioMarkProps } from "./helpers";
import PauseIcon from "./assets/pause.svg";
import PlayIcon from "./assets/play.svg";
import PlayerModel from "./PlayerModel";
import * as S from "./styled";
import { SessionProtocol } from "../../core/workspace/session-storage/types";
import { MenuOption, PopupMenu } from "../../uikit/popup";
import { Icon } from "../../uikit/Icon";
import CopyButton from "../../uikit/CopyButton";

interface Props {
  session: SessionProtocol;
  player: PlayerModel;
}

const Player: FC<Props> = ({ session, player }) => {
  const sliderEl = useRef<HTMLInputElement>(null);

  useEffect(() => {
    return () => {
      player.destroy();
    };
  }, [session]);

  const handlePlayBtnClick = () => {
    if (player.isPlaying) {
      player.stopAudio();
    } else {
      player.playAudio();
    }
  };

  const playbackRates: MenuOption[] = [
    {
      label: "1x (normal)",
      action: () => player.setPlaybackRate(1),
      isSelected: player.playbackRate === 1,
      isEnable: true,
    },
    {
      label: "1.5x",
      action: () => player.setPlaybackRate(1.5),
      isSelected: player.playbackRate === 1.5,
      isEnable: true,
    },
    {
      label: "2x",
      action: () => player.setPlaybackRate(2),
      isSelected: player.playbackRate === 2,
      isEnable: true,
    },
  ];

  return (
    <S.Container>
      <S.PlayButton onClick={handlePlayBtnClick}>{player.isPlaying ? <PauseIcon /> : <PlayIcon />}</S.PlayButton>
      <S.AudioInfo>
        <S.TimeBox>
          <S.AudioTime>{formatAudioTime(player.currentTime)}</S.AudioTime>
          <S.SessionTime>
            {dayjs(session.timeStarted).format("hh:mm A (MMM DD, YYYY)")}{" "}
            <CopyButton tooltipText="Copy link to audio" clipboard={player.audioLink} />
          </S.SessionTime>
          <S.AudioTime>{formatAudioTime(player.duration)}</S.AudioTime>
        </S.TimeBox>
        <S.SliderBox>
          <S.Slider
            ref={sliderEl}
            type="range"
            min="0"
            max={player.duration}
            step="1"
            value={player.currentTime}
            onChange={(e) => player.setCurrentTime(+e.target.value)}
          />
          {player.messagesWithTime.map((message) => {
            if (!sliderEl.current) return null;
            const markProps = getAudioMarkProps(message, player.duration, sliderEl.current.clientWidth);
            return <S.AudioMark key={message.id} width={markProps[0]} offset={markProps[1]} from={message.from} />;
          })}
        </S.SliderBox>
      </S.AudioInfo>
      <S.PlaybackRateBox>
        <PopupMenu actions={playbackRates} on="click" position="bottom right" closeAfterSelect>
          <S.PlaybackRate>
            <span>{player.playbackRate}x</span>
            <Icon name="chevron-down" />
          </S.PlaybackRate>
        </PopupMenu>
      </S.PlaybackRateBox>
    </S.Container>
  );
};

export default observer(Player);
