import { IAccount } from "../interface";
import RestApi from "./fetch";

export interface ConversationsStart {
  applicationId?: string;
  conversationsData: {
    pushOptions?: Record<string, unknown>;
    input: Record<string, unknown>;
  }[];
}

export interface ConversationsResult {
  status: string;
  result?: Record<string, unknown>;
  input?: Record<string, unknown>;
  applicationId: string;
  jobId: string;
}

export interface ApplicationInfo {
  applicationId: string;
  applicationName: string;
  groupId: string;
  inputSchema: any;
  outputSchema: any;
}

class ApplicationApi extends RestApi {
  async applications(endpoint: string, account: IAccount): Promise<ApplicationInfo[]> {
    const fetch = await this.signFetch(account, endpoint, { noAuth: true });
    const resp = await fetch(`deployed-applications`, { method: "get" });
    const result = await resp.json();
    return result;
  }

  async conversations(endpoint: string, account: IAccount): Promise<ConversationsResult[]> {
    const fetch = await this.signFetch(account, endpoint);
    const resp = await fetch(`conversations/statuses`, { method: "get" });
    const result = await resp.json();
    return result;
  }

  async runConversations(
    endpoint: string,
    data: ConversationsStart,
    account: IAccount
  ): Promise<ConversationsResult[]> {
    const fetch = await this.signFetch(account, endpoint);
    const resp = await fetch(`conversations`, { method: "post", body: JSON.stringify(data) });
    const result = await resp.json();
    return result;
  }

  async getConversations(endpoint: string, account: IAccount): Promise<ConversationsResult[]> {
    const fetch = await this.signFetch(account, endpoint);
    const resp = await fetch(`conversations`, { method: "get" });
    const data = await resp.json();
    return data;
  }
}

export default ApplicationApi;
