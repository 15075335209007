import React, { createRef, FC, useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import ReactDOM from "react-dom";
import { action } from "mobx";

import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import MarkableMessage from "@core/workspace/session-storage/MarkableMessage";
import { NewIntentBtn } from "../../ProfilerPanel/components/DataMarkup/components/SubRow/styled";
import PlusIcon from "../../ProfilerPanel/assets/plus.svg";
import SearchSelect from "../../uikit/SearchSelect";
import { Icon } from "../../uikit";
import * as S from "./styled";

type Location = [number, number];
type MarkupProps = {
  editable: MarkableMessage;
  dataset: DatasetStorage | undefined;
  onNewEntityClick?: (phrase: string, loc: [number, number]) => void;
  onChange?: () => void;
};

const MarkupPhrase: FC<MarkupProps> = ({ editable, dataset, onNewEntityClick, onChange }) => {
  const [loc, setLocation] = useState<Location | null>(null);
  const [isSearch, toggleSearch] = useState(false);
  const [phraseRefs, setPhraseRefs] = useState<React.RefObject<HTMLButtonElement>[]>([]);
  const ref = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (e) => {
      if (ref.current === e.target || ref.current?.contains(e.target)) return;
      const i = phraseRefs.findIndex((r) => r.current === e.target || r.current?.contains(e.target));

      if (i === -1) {
        setLocation(null);
        return;
      }

      if (isTokenEnable(editable.tokens[i], i) == false) {
        return;
      }

      toggleSearch(false);

      if (loc == null) {
        setLocation([i, i]);
        return;
      }

      const [from, to] = loc;
      if (from - 1 > i || to + 1 < i) {
        setLocation(null);
        return;
      }

      if (from === i && to === i) {
        setLocation(null);
        return;
      }

      if (from === i) {
        setLocation([from + 1, to]);
        return;
      }

      if (to === i) {
        setLocation([from, to - 1]);
        return;
      }

      if (i > from && i < to) {
        setLocation([from, i - 1]);
        return;
      }

      if (i < loc[0]) return setLocation([loc[0] - 1, loc[1]]);
      if (i > loc[1]) return setLocation([loc[0], loc[1] + 1]);

      setLocation(null);
    };

    document.body.addEventListener("click", handler);
    return () => document.body.removeEventListener("click", handler);
  }, [loc, phraseRefs.length]);

  useEffect(() => {
    setPhraseRefs(() => editable.tokens.map(() => createRef<HTMLButtonElement>()));
  }, [editable.tokens.length]);

  const handleEntity = (entity, value?: string) => {
    if (loc == null) return;
    editable.markupPhrase(loc[0], loc[1], entity, null, value);
    editable.activeAll();
    onChange?.();

    setLocation(null);
  };

  const handleEntityHover = (index: number) =>
    action(() => {
      const token = editable.tokens[index];
      if (isTokenEnable(token, index)) return;

      const trigger = editable.triggers.find((t) => t.type === "entity" && token.hue === t.hue);
      if (trigger && trigger.type === "entity") {
        trigger.hovered = !trigger.hovered;
      }
    });

  const isSelected = (i) => {
    if (loc == null) return false;
    return loc[0] <= i && i <= loc[1];
  };

  const isTokenEnable = (token, i) => {
    if (token.hue != null) return false;
    if (loc == null) return true;
    return loc[0] - 1 <= i && i <= loc[1] + 1;
  };

  React.useEffect(() => {
    if (ref.current == null) return;
    if (loc == null) {
      ref.current.style.display = "none";
      return;
    }

    const rects = phraseRefs
      .slice(loc[0], loc[1] + 1)
      .map((ref) => ref.current?.getBoundingClientRect())
      .filter((v): v is DOMRect => v != null);

    ref.current.style.display = "block";
    ref.current.style.position = "fixed";
    ref.current.style.zIndex = "10000000";

    const minx = Math.min(...rects.map((r) => r.x));
    const maxx = Math.max(...rects.map((r) => r.right));
    const bottom = Math.max(...rects.map((r) => r.bottom));
    const { width } = ref.current.getBoundingClientRect();

    ref.current.style.left = `${minx + (maxx - minx) / 2 - width / 2}px`;
    ref.current.style.top = `${bottom + 4}px`;
  }, [loc]);

  return (
    <>
      <S.Phrase>
        {editable.tokens.map((token, i) => (
          <S.PhraseToken
            key={i}
            ref={phraseRefs[i]}
            style={{ "--hue": token.hue } as any}
            isSelected={isSelected(i)}
            isDisabled={isTokenEnable(token, i) == false}
            onMouseEnter={handleEntityHover(i)}
            onMouseLeave={handleEntityHover(i)}
          >
            {token.text}
          </S.PhraseToken>
        ))}

        {ReactDOM.createPortal(
          <div ref={ref}>
            <S.OpenSearchButton style={{ display: isSearch ? "none" : "flex" }} onClick={() => toggleSearch(true)}>
              <Icon name="plus" />
            </S.OpenSearchButton>

            {isSearch && dataset && (
              <SearchSelect
                focuses
                selected=""
                options={dataset.entitiesWithValues}
                newBtn={
                  <NewIntentBtn
                    onClick={() => {
                      if (loc === null) return;
                      const markedPart = editable.tokens
                        .slice(loc[0], loc[1] + 1)
                        .map((part) => part.text)
                        .join(" ");

                      toggleSearch(false);
                      onNewEntityClick?.(markedPart, loc);
                    }}
                  >
                    <PlusIcon />
                    <i>Create a new entity</i>
                  </NewIntentBtn>
                }
                onChange={handleEntity}
                onRemove={() => toggleSearch(false)}
                onBlur={() => toggleSearch(false)}
              />
            )}
          </div>,
          document.body
        )}
      </S.Phrase>
    </>
  );
};

export default observer(MarkupPhrase);
