import { UploadProjectConfig, ProjectMetadata } from "../api/share";
import Account from "../Account";
import { uuid4 } from "@sentry/utils";
import { IProjectsLibrary } from "./interface";
import api from "../api";

/** local storage does not save source of project */
class LocalLibrary implements IProjectsLibrary {
  constructor(readonly account: Account) {}

  async getProjects(): Promise<ProjectMetadata[]> {
    return this.account.getValue("projects") ?? [];
  }

  async getProjectContent(id: string) {
    return await api.share.getProjectContent(id, this.account).catch(() => null)
  }

  async updateProject(id: string, config: UploadProjectConfig) {
    const projects = await this.getProjects();
    const prj = projects.find((p) => p.id === id);
    if (prj == null) throw Error("project is not found");

    prj.updatedAt = new Date().toISOString();
    prj.customMetaData.projectType = config.projectType ?? "code";
    prj.customMetaData.author = this.account.name;
    prj.sharedAccess = config.sharedAccess ?? false;
    prj.description = config.description;
    prj.name = config.name;

    this.account.setValue("projects", projects);
    return prj;
  }

  async createProject(config: UploadProjectConfig) {
    const projects = await this.getProjects();
    const project: ProjectMetadata = {
      id: uuid4(),
      isEditable: true,
      createdAt: new Date().toISOString(),
      updatedAt: new Date().toISOString(),
      sharedAccess: config.sharedAccess ?? false,
      description: config.description,
      name: config.name,
      customMetaData: {
        projectType: config.projectType ?? "code",
        author: this.account.name,
      },
    };

    projects.push(project);
    this.account.setValue("projects", projects);
    return project;
  }

  async deleteProject(id: string) {
    const projects = await this.getProjects();
    const index = projects.findIndex((p) => p.id === id);
    if (index === -1) throw Error("project is not found");

    projects.splice(index, 1);
    this.account.setValue("projects", projects);
    return true;
  }
}

export default LocalLibrary;
