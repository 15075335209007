import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import Explorer from "../../core/explorer/Explorer";
import Workspace from "../../core/workspace/Workspace";
import AccountsManager from "../../core/account/Manager";

import { Tooltip } from "../../uikit/popup";
import { Icon, StrokeButton } from "../../uikit";
import * as S from "./styled";

interface Props {
  explorer: Explorer;
  accounts: AccountsManager;
  workspace: Workspace;
}

const ProjectControl = observer<Props>(({ workspace, explorer, accounts }) => {
  const [published, setPublished] = useState(false);
  const project = workspace.project;

  if (project == null) return null;

  const publishApp = async () => {
    setPublished(false);
    await project.sharedAccess(true).then(() => {
      setPublished(true);
      setTimeout(() => setPublished(false), 2000);
    });
  };

  const renderShareWidget = () => {
    const button = (
      <Tooltip text="Generate a public link">
        <StrokeButton disabled={workspace.sessions?.isRunning} onClick={publishApp}>
          Share app
        </StrokeButton>
      </Tooltip>
    );

    const copied = (
      <>
        <Icon name="check" style={{ marginRight: 4 }} /> Link copied in clipboard!
      </>
    );

    const { sharedAccess, isEditable } = project.metadata;
    const shareTooltip = published ? "Public link generated!" : "Copy link to clipboard";

    const link = sharedAccess ? (
      <Tooltip open={published} text={shareTooltip} clickContent={copied}>
        <S.ShareLink onClick={() => explorer.copyProjectLink(project.id, project.version)}>
          Copy link:<span>/{project.id}</span>
          <Icon name="link" />
        </S.ShareLink>
      </Tooltip>
    ) : null;

    const isCanShare = !sharedAccess && isEditable && accounts.isAuth;
    return (
      <S.ShareWidget>
        {link}
        {isCanShare && button}
      </S.ShareWidget>
    );
  };

  return (
    <S.Container>
      <S.Controls>
        <Tooltip text="Download source code" position="bottom center">
          <S.Button onClick={() => explorer.download(project)}>
            <Icon name="download" />
            <span>Download</span>
          </S.Button>
        </Tooltip>

        {renderShareWidget()}
      </S.Controls>
    </S.Container>
  );
});

export default ProjectControl;
