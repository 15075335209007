import React from "react";
import styled, { keyframes } from "styled-components";

import CheckIcon from "./icons/check.svg";
import ArrowIcon from "./icons/arrow.svg";
import CallIcon from "./icons/call.svg";
import FileIcon from "./icons/file.svg";
import MicrophoneIcon from "./icons/microphone.svg";
import ArrowUpIcon from "./icons/arrow-up.svg";
import ArrowLeftIcon from "./icons/arrow-left.svg";
import CursorIcon from "./icons/cursor.svg";
import CursorUpIcon from "./icons/cursor-up.svg";
import CursorDownIcon from "./icons/cursor-down.svg";
import CursorBackIcon from "./icons/cursor-back.svg";
import SpinnerIcon from "./icons/spinner.svg";
import DownloadIcon from "./icons/download.svg";
import RestoreIcon from "./icons/restore.svg";
import DraftFileIcon from "./icons/draft-file.svg";
import LogoutIcon from "./icons/logout.svg";
import ChevronDownIcon from "./icons/chevron-down.svg";
import MenuIcon from "./icons/menu.svg";
import LinkIcon from "./icons/link.svg";
import ArchiveIcon from "./icons/archive.svg";
import QuestionIcon from "./icons/question.svg";
import CrossIcon from "./icons/cross.svg";
import EditIcon from "./icons/edit.svg";
import CheckinIcon from "./icons/checkin.svg";
import InfoIcon from "./icons/info.svg";
import PlusIcon from "./icons/plus.svg";
import GenerateIcon from "./icons/generate.svg";
import TrashIcon from "./icons/trash.svg";
import WarningIcon from "./icons/warning.svg";
import LabIcon from "./icons/lab.svg";
import CloseIcon from "./icons/close.svg";
import CodeIcon from "./icons/code.svg";
import AppIcon from "./icons/app-icon.svg";
import DslIcon from "./icons/dsl.svg";
import UploadIcon from "./icons/upload.svg";
import AppsIcon from "./icons/apps.svg";
import CopyLinkIcon from "./icons/copy-link.svg";
import FiltersIcon from "./icons/filter-icon.svg";
import SettingsIcon from "./icons/settings-icon.svg";

import CreateDirIcon from "./icons/create-dir.svg";
import CreateFileIcon from "./icons/create-file.svg";
import UploadFileIcon from "./icons/upload-file.svg";

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled(SpinnerIcon)`
  animation: ${spin} 2s infinite linear;
`;

const icons = {
  arrow: ArrowIcon,
  call: CallIcon,
  file: FileIcon,
  logout: LogoutIcon,
  "chevron-down": ChevronDownIcon,
  "draft-file": DraftFileIcon,
  microphone: MicrophoneIcon,
  "arrow-up": ArrowUpIcon,
  "arrow-left": ArrowLeftIcon,
  menu: MenuIcon,
  cursor: CursorIcon,
  "cursor-up": CursorUpIcon,
  "cursor-down": CursorDownIcon,
  "cursor-back": CursorBackIcon,
  spinner: Spinner,
  download: DownloadIcon,
  restore: RestoreIcon,
  link: LinkIcon,
  check: CheckIcon,
  archive: ArchiveIcon,
  question: QuestionIcon,
  cross: CrossIcon,
  edit: EditIcon,
  checkin: CheckinIcon,
  info: InfoIcon,
  plus: PlusIcon,
  trash: TrashIcon,
  generate: GenerateIcon,
  warning: WarningIcon,
  lab: LabIcon,
  close: CloseIcon,
  code: CodeIcon,
  "app-icon": AppIcon,
  dsl: DslIcon,
  upload: UploadIcon,
  apps: AppsIcon,
  "copy-link-icon": CopyLinkIcon,
  "filter-icon": FiltersIcon,
  "settings-icon": SettingsIcon,

  "create-dir": CreateDirIcon,
  "create-file": CreateFileIcon,
  "upload-file": UploadFileIcon,
};

interface Props {
  style?: any;
  name: keyof typeof icons;
  color?: string;
  angle?: number;
  [key: string]: any;
}

export const Icon = React.forwardRef<SVGElement, Props>(({ name, angle = 0, color, style = {}, ...rest }, ref) => {
  const IconComponent = icons[name] as any;
  const styles = { ...style, transition: ".2s transform", transform: `rotateZ(${angle}deg)`, fill: color };
  return <IconComponent {...rest} ref={ref} style={styles} />;
});
