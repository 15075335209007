import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button, Pagination } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "@core/profiler/utils";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { Link, useParams } from "react-router-dom";
import { useHistory, useLocation } from 'react-router';



export const MissedCalls: FC<{ account: IAccount }> = ({ account }) => {
  const { customerId } = useParams();
  const [dataRows, setDataRows] = useState<dasha.sip.inboundConfigs.SIPReportResponse[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    setLoading(true);

    const fetchData = async () => {
      const a = await account.connectAs(customerId);
      const result = await dasha.sip.reports.getInboundCalls(
        dateRange.start?.toISOString(), 
        dateRange.end?.toISOString(), 
        299,
        undefined,
        (page - 1)*50, 50, { account: a });
      setDataRows(result);
      if (result.length < 50) {
        setTotalPages(page);
      } else {
        setTotalPages(page + 1);
      }
    };
    fetchData().finally(() => setLoading(false));
  }, [account, page, dateRange]);

  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Detailed information</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />
      <Loader active={loading}>Loading..</Loader>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Time</Table.HeaderCell>
            <Table.HeaderCell>From</Table.HeaderCell>
            <Table.HeaderCell>To</Table.HeaderCell>
            <Table.HeaderCell>Status code</Table.HeaderCell>
            <Table.HeaderCell>Reason</Table.HeaderCell>
            <Table.HeaderCell>CallId</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(dataRows).map(([k, c]) => {
            return (
              <Table.Row>
                <Table.Cell>{c.createdTime}</Table.Cell>
                <Table.Cell>{c.fromUser}@{c.fromDomain}</Table.Cell>
                <Table.Cell>{c.toUser}@{c.toDomain}</Table.Cell>
                <Table.Cell>{c.sipStatusCode}</Table.Cell>
                <Table.Cell>{c.sipReason}</Table.Cell>
                <Table.Cell>{c.sipCallId}</Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>

          </Table.Row>
        </Table.Footer>
      </Table>
      <Pagination activePage={page} totalPages={totalPages} onPageChange={(e, value) => setPage(value.activePage as number)} />
    </div>
  );
};
