import React, { FC } from "react";
import { observer } from "mobx-react-lite";

import ProfilerStorage, { ProfilerPage } from "@core/profiler/ProfilerStorage";
import Workspace from "../core/workspace/Workspace";
import SkeletonSvg from "./skeleton.svg";
import FilesView from "./FilesView";
import * as S from "./styled";

interface Props {
  id: string;
  isOpen: boolean;
  workspace: Workspace;
}

const FilesPanel: FC<Props> = ({ id, isOpen, workspace }) => {
  const isEditable = workspace.project?.metadata.isEditable ?? false;
  const profileStorage = workspace.opened instanceof ProfilerStorage ? workspace.opened : null;

  if (workspace.project == null) {
    return (
      <S.Container id={id} isOpen={isOpen}>
        <SkeletonSvg style={{ paddingTop: 16, paddingLeft: 12, width: "100%", height: "100%" }} />
      </S.Container>
    );
  }

  if (isEditable === false) {
    return (
      <S.Container id={id} isOpen={isOpen}>
        <FilesView workspace={workspace} />
      </S.Container>
    );
  }

  const profiler = (
    <>
      <S.Header>
        <h2>Tools</h2>
      </S.Header>
      <S.Item
        onClick={() => profileStorage?.selectPage(ProfilerPage.TABLE)}
        isSelected={profileStorage?.selectedPage === ProfilerPage.TABLE}
      >
        Data markup
      </S.Item>
      <S.Item
        onClick={() => profileStorage?.selectPage(ProfilerPage.GRAPH)}
        isSelected={profileStorage?.selectedPage === ProfilerPage.GRAPH}
      >
        Graph for analytics
      </S.Item>
      <S.Item
        onClick={() => profileStorage?.selectPage(ProfilerPage.CONVERSATIONS)}
        isSelected={profileStorage?.selectedPage === ProfilerPage.CONVERSATIONS}
      >
        Conversations
      </S.Item>
    </>
  );

  return (
    <S.Container id={id} isOpen={isOpen}>
      <S.Tabs>
        <S.Tab
          onClick={() => profileStorage && workspace.closeFile(profileStorage)}
          isSelected={profileStorage == null}
        >
          Files
        </S.Tab>
        <S.Tab onClick={() => workspace.addFile(".profile")} isSelected={profileStorage != null}>
          Profiler
        </S.Tab>
      </S.Tabs>
      {profileStorage ? profiler : <FilesView workspace={workspace} />}
    </S.Container>
  );
};

export default observer(FilesPanel);
