import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";
import { countBy, map, property } from "lodash";

import Workspace from "@core/workspace/Workspace";
import Deploy from "@core/deploy-storage/Deploy";
import DeployStorage from "@core/deploy-storage/DeploysStorage";
import { DeployStatusEnum } from "@core/account/api/deploy";
import { ProjectVersion } from "@core/explorer/Project";
import UIManager from "@core/misc/UIManager";

import { PureButton, StrokeButton } from "../uikit/Button";
import { MenuOption, PopupMenu, Tooltip } from "../uikit/popup";
import { Icon } from "../uikit";

import RunConversations from "./RunConversations";
import * as S from "./styled";
import { AccountFeature } from "@core/account/interface";

interface Props {
  workspace: Workspace;
  deploys?: DeployStorage;
}

const indicator = {
  [DeployStatusEnum.Pending]: "#ffb500",
  [DeployStatusEnum.Failed]: "tomato",
  [DeployStatusEnum.Running]: "forestgreen",
  [DeployStatusEnum.Stop]: "rgba(255, 255, 255, .1)",
};

const VersionsPanel: FC<Props> = ({ workspace, deploys }) => {
  const [openedModal, setModal] = useState<Deploy | null>(null);

  const project = workspace.project;
  const profiler = workspace.storages.profiler;
  const isDeploysAccess = workspace.project?.account.hasFeature(AccountFeature.DEPLOYER) ?? false;

  if (project == null) return null;
  if (project.metadata.isEditable === false) return <p>No access</p>;

  type NestedVersion = ProjectVersion & { children: NestedVersion[] };
  const versions = new Map<string, NestedVersion>();
  Object.values(project.versions)
    .filter((v) => v)
    .forEach((v) => versions.set(v.name, { ...v, children: [] }));

  versions.forEach((version) => {
    if (version.from == null) return;
    versions.get(version.from)?.children.push(version);
  });

  const newVersion = async (from: string) => {
    await project.createVersion(from, Date.now().toString());
  };

  const renderVersion = (version: NestedVersion, depth = 0, name = "") => {
    const isSelected = project.version === version.name;
    const deploy = deploys?.getDeploy(version.name);
    const app = deploy?.application;

    const actions: MenuOption[] = [
      {
        label: "Fork version",
        action: () => newVersion(version.name),
        isEnable: true,
      },
      {
        label: "Run profiler",
        isEnable: app != null && isDeploysAccess,
        action: async () => {
          if (app == null || profiler == null) return;

          await project.switchVersion(version.name);
          setTimeout(async () => {
            await workspace.addFile(profiler.path);
            await profiler.deserialize({
              start: 0,
              end: Date.now(),
              name: app.applicationName,
              application: app.applicationId,
              savedDatasets: [],
              marked: {},
            });
          }, 600);
        },
      },
      {
        label: "Upload conversations",
        isEnable: app != null && isDeploysAccess,
        action: () => setModal(deploy ?? null),
      },
      {
        label: "Copy deployment ID",
        isEnable: deploy != null && isDeploysAccess,
        action: () => {
          if (deploy == null) return;
          void navigator.clipboard.writeText(deploy.id);
          UIManager.notice("Deployment ID was copied");
        },
      },
      {
        label: "Copy application ID",
        isEnable: app != null && isDeploysAccess,
        action: () => {
          if (app == null) return;
          void navigator.clipboard.writeText(app.applicationId);
          UIManager.notice("Application ID was copied");
        },
      },
      {
        isSelected: false,
        label: "Delete version",
        isEnable: Object.keys(project.versions).length > 1,
        action: async () => {
          const isSuccess = deploy ? await deploys?.deleteDeploy(deploy.id) : true;
          if (isSuccess) await project.removeVersion(version.name);
        },
      },
    ];

    const conversationsTooltip = (
      <div>
        {map(countBy(deploy?.conversations, property("status")), (value, key) => (
          <div key={value}>
            {key}: {value}
          </div>
        ))}
      </div>
    );

    const copyDeployEndpoint = () => {
      if (deploy?.endpoint == null) return;
      void navigator.clipboard.writeText(deploy.endpoint);
      UIManager.notice("Deployment Endpoint was copied");
    };

    const getWidthOfInput = (value: string) => {
      const tmp = document.createElement("span");
      tmp.style.fontFamily = "var(--main-font)";
      tmp.style.border = "1px solid #ffffff4d";
      tmp.style.fontWeight = "400";
      tmp.style.padding = "0 8px";
      tmp.style.fontSize = "15px";

      tmp.textContent = value;
      document.body.appendChild(tmp);

      const theWidth = tmp.getBoundingClientRect().width;
      document.body.removeChild(tmp);
      return theWidth;
    };

    const handleInput = (e) => {
      e.target.style.width = getWidthOfInput(e.target.value) + "px";
    };

    return (
      <React.Fragment key={version.name}>
        <S.Version isSelected={isSelected} style={{ paddingLeft: 16 + depth }}>
          <div>
            <input
              maxLength={50}
              defaultValue={version.message || "Unnamed"}
              style={{ width: getWidthOfInput(version.message || "Unnamed") }}
              onBlur={(e) => project.changeMessage(version.name, e.target.value)}
              placeholder="Some version message"
              onInput={handleInput}
            />

            {isDeploysAccess && deploy && (
              <S.Status style={{ "--indicator": indicator[deploy.status] } as any}>{deploy.status}</S.Status>
            )}

            {isDeploysAccess && deploy && deploy?.conversations.length > 0 && (
              <Tooltip text={conversationsTooltip}>
                <div>[{deploy?.conversations.length} convs]</div>
              </Tooltip>
            )}
          </div>

          <div>
            {project.version !== version.name && (
              <StrokeButton onClick={() => project.switchVersion(version.name)}>open</StrokeButton>
            )}

            <StrokeButton onClick={() => newVersion(version.name)}>fork</StrokeButton>

            {isDeploysAccess && deploys != null && (deploy == null || !deploy.isActive) && (
              <StrokeButton onClick={() => deploys?.deploy(version.name)}>Run</StrokeButton>
            )}

            {isDeploysAccess && deploy?.status === DeployStatusEnum.Running && (
              <StrokeButton onClick={() => deploys?.deleteDeploy(deploy.id)}>Stop</StrokeButton>
            )}

            {isDeploysAccess && deploy?.status === DeployStatusEnum.Running && (
              <StrokeButton onClick={copyDeployEndpoint}>
                <Icon style={{ marginRight: 4 }} name="link" />
                Сopy link
              </StrokeButton>
            )}

            <PopupMenu actions={actions} position={["bottom right", "top right"]} on="click" closeAfterSelect>
              <PureButton style={{ marginLeft: 8 }}>
                <Icon name="menu" />
              </PureButton>
            </PopupMenu>
          </div>
        </S.Version>
        {version.children.map((v, i) => renderVersion(v, depth + 16, [name, i + 1].filter((v) => v).join(".")))}
      </React.Fragment>
    );
  };

  return (
    <S.Container>
      {openedModal != null && (
        <RunConversations isOpen={openedModal != null} onClose={() => setModal(null)} deploy={openedModal} />
      )}

      {Array.from(versions.values())
        .filter((v) => v.from == null)
        .map((version) => renderVersion(version, 0))}
    </S.Container>
  );
};

export default observer(VersionsPanel);
