import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import Workspace from "../../core/workspace/Workspace";
import MarkableMessage from "../../core/workspace/session-storage/MarkableMessage";
import { Message, SessionProtocol } from "../../core/workspace/session-storage/types";
import { Trigger } from "../../core/workspace/session-storage/devlogs";
import useAutoScroller from "../../uikit/hooks/useAutoScroller";

import { formatDate, formatDuration } from "../helpers";
import ClientMessage from "../ClientMessage";
import TriggersEdit from "../TriggersEdit";
import DashaMessage from "../DashaMessage";
import * as S from "./styled";
import CopyButton from "../../uikit/CopyButton";
import OutputInfo from "../OutputInfo";
import { Link } from "react-router-dom";
import { Icon } from "semantic-ui-react";
import { ActionButton } from "../../uikit";

interface Props {
  workspace: Workspace | undefined;
  session: SessionProtocol;
  allowTransitionsDebug?: boolean;
  gptEmulateCallback?: (instanceId: string) => void;
}

const Dialog: FC<Props> = ({ session, workspace, allowTransitionsDebug, gptEmulateCallback }) => {
  const ref = useAutoScroller<HTMLDivElement>();
  const [editableMessage, setEditable] = useState<MarkableMessage | null>(null);
  const handleClose = () => setEditable(null);
  const datasetStorage = workspace?.storages?.dataset;

  const handleSave = async (message: Message) => {
    if (datasetStorage == null || editableMessage == null) return;
    await workspace?.addFile(datasetStorage);

    setTimeout(() => {
      editableMessage.activeAll();
      let dataset = editableMessage.generateDataset();
      datasetStorage?.unsetDataset(dataset);

      editableMessage.deserialize(message);
      editableMessage.activeAll();
      dataset = editableMessage.generateDataset();
      datasetStorage?.mergeDataset(dataset);
      void workspace?.sessions?.saveSessions();
      setEditable(null);
    }, 0);
  };

  const handleOpenTrigger = async (trigger: Trigger) => {
    if (datasetStorage == null) return;
    await workspace?.addFile(datasetStorage);

    setTimeout(() => {
      const type = trigger.type === "entity" ? "entities" : "intents";
      datasetStorage?.revealTrigger(type, trigger.name);
    }, 0);
  };

  const handleCommit = async (message: MarkableMessage) => {
    if (datasetStorage == null) return;
    message.activeAll();
    const dataset = message.generateDataset();
    await workspace?.addFile(datasetStorage);

    setTimeout(() => {
      datasetStorage?.mergeDataset(dataset);
      void workspace?.sessions?.saveSessions();
    }, 0);
  };

  const isEditable =
    datasetStorage != null &&
    workspace !== undefined &&
    workspace?.isRunning === false &&
    workspace?.project?.metadata.isEditable === true &&
    session.isReadOnly === false;

  if (session.timeEnded && !session.timeStarted && session.timeInitialized) {
    return (
      <S.DialogContainer>
        <S.System>
          <S.SystemMessage>
            Interrupted: {formatDate(session.timeInitialized)} - {formatDate(session.timeEnded, true)}
          </S.SystemMessage>
        </S.System>
      </S.DialogContainer>
    );
  }

  return (
    <S.DialogContainer ref={ref}>
      {editableMessage && datasetStorage && (
        <TriggersEdit
          session={session}
          dataset={datasetStorage}
          message={editableMessage}
          onComplete={handleSave}
          onClose={handleClose}
        />
      )}

      {session.timeStarted && (
        <S.System>
          <S.SystemMessage>Start: {formatDate(session.timeStarted, true)}</S.SystemMessage>
        </S.System>
      )}

      {session.inputData && <OutputInfo data={session.inputData} />}
      {session.messages.map((msg) => {
        if (msg instanceof MarkableMessage) {
          return (
            <ClientMessage
              key={msg.id}
              message={msg}
              editable={isEditable}
              allowTransition={allowTransitionsDebug}
              allowContext={workspace?.sessions?.allowContextDebug}
              selected={session.selectedMessage === msg.id}
              currentMessageInAudio={session.currentMessageInAudio === msg.id}
              onTrigger={handleOpenTrigger}
              onCommit={handleCommit}
              onEdit={setEditable}
            />
          );
        }
        if (msg.isSystem) {
          return (
            <S.System>
            <S.SystemMessage>
              {msg.message}
            </S.SystemMessage>
            {msg.gptInstanceId && gptEmulateCallback && (
              <ActionButton onClick={() => gptEmulateCallback(msg.gptInstanceId ?? "")}>Debug</ActionButton>
          )} 
          </S.System>
          )
        }
        return (
          <DashaMessage
            key={msg.id}
            msg={msg}
            isCurrentInAudio={session.currentMessageInAudio === msg.id}
            allowTransition={allowTransitionsDebug}
            allowContext={workspace?.sessions?.allowContextDebug}
            gptEmulateCallback={gptEmulateCallback}
            gptInstanceId={msg.gptInstanceId}
          />
        );
      })}

      {session.timeEnded && (
        <S.System>
          <S.SystemMessage>
            End: {formatDate(session.timeEnded, true)}. Duration {formatDuration(session.duration)}
          </S.SystemMessage>
          <CopyButton tooltipText="Copy job id" clipboard={session.jobId || ""}>
            Copy Job id
          </CopyButton>
          <Link to={`/inspector/${session.jobId}`} target="_blank" rel="noopener noreferrer">Inspect</Link>
        </S.System>
      )}

      {session.outputData && <OutputInfo data={session.outputData} />}
    </S.DialogContainer>
  );
};

export default observer(Dialog);
