import { IAccount } from "../interface";

export class RestError extends Error {
  constructor(readonly response: Response) {
    super(`RestError ${response.url} ${response.status}`);
  }
}

class RestApi {
  domain = "";

  signFetch = async (account: IAccount, customEndpoint?: string, { noAuth = false } = {}) => {
    const cred = await account.connect();

    return async (input: string, init: RequestInit = {}): Promise<Response> => {
      const endpoint = customEndpoint ?? `https://${cred.server}`;
      const url = new URL(`${this.domain}/${input}`, endpoint).toString();
      const auth: Record<string, string> = {
        "Content-Type": "application/json-patch+json",
      };

      if (noAuth === false) {
        auth.Authorization = `Bearer ${cred.apiKey}`;
      }

      const res = await fetch(url, {
        ...init,
        headers: Object.assign(auth, init.headers),
      });

      if (res.ok == false) throw new RestError(res);
      return res;
    };
  };
}

export default RestApi;
