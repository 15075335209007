import { makeObservable, observable, computed, runInAction, flow, toJS, action } from "mobx";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import JSZip from "jszip";

import { ProjectMetadata } from "../account/api/share";
import { IAccount } from "../account/interface";
import { Emitter } from "../misc/emitter";
import { FSStructure } from "../account/filesystem";
import UIManager from "../misc/UIManager";

export interface ProjectDefinition {
  updatedAt: string;
  version: string;
  versions: Record<string, ProjectVersion>;
}

export interface ProjectVersion {
  name: string;
  isReadonly: boolean;
  message: string;
  created: number;
  updated: number;
  from?: string;
}

class Project {
  public version = "master";
  public versions: Record<string, ProjectVersion> = {
    master: { name: "master", message: "master", created: 0, updated: 0, isReadonly: false },
  };

  public files: FSStructure[] = [];
  private readonly _onDidChange = new Emitter<{ file: string; content: string; initiator?: string }>();
  public readonly onDidChange = this._onDidChange.event;

  private readonly _onDidRewrite = new Emitter<void>();
  public readonly onDidRewrite = this._onDidRewrite.event;

  private readonly _onDidCreate = new Emitter<string>();
  public readonly onDidCreate = this._onDidCreate.event;

  private readonly _onDidRemove = new Emitter<string>();
  public readonly onDidRemove = this._onDidRemove.event;

  private readonly _onDidRename = new Emitter<{ old: string; rename: string }>();
  public readonly onDidRename = this._onDidRename.event;

  constructor(public _metadata: ProjectMetadata, readonly account: IAccount) {
    makeObservable(this, {
      files: observable,
      version: observable,
      versions: observable,
      metadata: computed,
      _metadata: observable,
      toggleReadonlyVersion: action,
      updateFiles: flow,
    });

    this.onDidRewrite(() => this.updateFiles());
  }

  get definitionPath() {
    return `${this.root}/.metadata`;
  }

  get metadata() {
    return cloneDeep(this._metadata);
  }

  get id() {
    return this._metadata.id;
  }

  get root() {
    return "/" + this.id;
  }

  path(sub = "") {
    if (!this.version) throw Error("project version is undefined");
    return `${this.root}/${this.version}${sub ? "/" : ""}${sub}`;
  }

  *updateFiles() {
    const files = yield this.account.storage.files(this.path());
    this.files = files;
  }

  async initialize(version: string|null) {
    await this.account.storage.mkdir(this.root);
    const read = this.account.storage.readJSON<ProjectDefinition>(this.definitionPath);
    const def = await read.catch(() => null);
    const localUpdate = +new Date(def?.updatedAt ?? 0);
    const remoteUpdate = +new Date(this._metadata.updatedAt);

    if (localUpdate < remoteUpdate) {
      const content = await this.account.library.getProjectContent(this.id);
      if (content) {
        const zip = await new JSZip().loadAsync(content);
        const metadata = await zip.file(".metadata")?.async("string");
        if (metadata == null || this.isValidDefinition(metadata) === false) {
          zip.remove(".metadata");
          await this.account.storage.writeFromZip(this.path(), zip);
          await this.writeDefinition();
          this._onDidRewrite.fire();
          return;
        }

        await this.account.storage.writeFromZip(this.root, zip);
      }
    }

    await this.readDefinition(version);
    this._onDidRewrite.fire();
  }

  private async writeDefinition() {
    return await this.account.storage.write(
      this.definitionPath,
      JSON.stringify({
        versions: toJS(this.versions),
        version: toJS(this.version),
        updatedAt: toJS(this._metadata.updatedAt),
      })
    );
  }

  isValidDefinition(doc: string) {
    try {
      const json = JSON.parse(doc);
      return Object.keys(json.versions).length > 0;
    } catch {
      return false;
    }
  }

  async readDefinition(version: string|null) {
    const read = this.account.storage.readJSON<ProjectDefinition>(this.definitionPath);
    const def = await read.catch(() => ({
      versions: {},
      version: "",
    }));

    const versions: Record<string, ProjectVersion> = {};
    const files = await this.account.storage.fs.promises.readdir(this.root);
    for (const path of files) {
      const stat = await this.account.storage.fs.promises.stat(this.root + "/" + path);
      if (stat.isDirectory() === false) continue;

      versions[path] = def.versions[path] ?? {
        name: path,
        message: "",
        created: +new Date(this.metadata.createdAt),
        updated: +new Date(this.metadata.updatedAt),
        isReadonly: false,
      };
    }

    if (versions[def.version] == null) {
      def.version = Object.keys(versions)[0];
    }

    console.log("def", def);

    runInAction(() => {
      this.versions = versions;
      this.version = versions[version ?? def.version] ? (version ?? def.version) : Object.keys(versions)[0];
    });
  }

  async rewrite(zip: Uint8Array, subfolder: string | undefined = undefined) {
    await this.account.storage.mkdir(this.root);
    await this.account.storage.writeFromZip(this.path(subfolder??""), zip);
    this._onDidRewrite.fire();
  }

  async file(file: string) {
    return await this.account.storage.readString(this.path(file));
  }

  async json<T>(file: string) {
    return await this.account.storage.readJSON<T>(this.path(file));
  }

  async zip(subdir = "") {
    return await this.account.storage.zipDirectory(this.path(subdir));
  }

  async clear() {
    await this.account.storage.delete(this.root);
  }

  async switchVersion(version: string) {
    if (this.version === version) return;
    if (this.versions[version] == null) return;

    await runInAction(async () => {
      this.version = this.versions[version].name;
      this._onDidRewrite.fire();
      await this.writeDefinition();
    });
  }

  async changeMessage(version: string, msg: string) {
    if (this.versions[version] == null) return;
    this.versions[version].message = msg;
    await this.writeDefinition();
  }

  async toggleReadonlyVersion(version: string, is: boolean) {
    if (this.versions[version] == null) return;
    this.versions[version].isReadonly = is;
    await this.writeDefinition();
  }

  async removeVersion(version: string) {
    if (Object.keys(this.versions).length < 2) return;
    if (this.versions[version] == null) return;

    await this.account.storage.delete(`${this.root}/${version}`);
    await runInAction(async () => {
      delete this.versions[version];
      Object.values(this.versions).forEach((v) => {
        if (v.from !== version) return;
        v.from = undefined;
      });

      if (this.version === version) {
        this.version = Object.keys(this.versions)[0];
        this._onDidRewrite.fire();
      }

      await this.writeDefinition();
      await this.updateRemote();
    });
  }

  async createVersion(from: string, name: string) {
    if (this.versions[name] != null) return;

    const zip = await this.account.storage.zipDirectory(`${this.root}/${from}`);
    await this.account.storage.writeFromZip(`${this.root}/${name}`, zip);

    await runInAction(async () => {
      this.version = name;
      this.versions[name] = {
        name,
        from,
        isReadonly: false,
        created: Date.now(),
        updated: Date.now(),
        message: `Fork ${this.versions[from].message}`,
      };
      await this.writeDefinition();
      await this._onDidRewrite.fire();
    });
  }

  async rename(name: string, description: string, projectType?: "code" | "visual") {
    this._metadata.name = name;
    this._metadata.description = description;

    if (projectType) {
      this._metadata.customMetaData.projectType = projectType;
    }

    await this.updateRemote();
  }

  async sharedAccess(sharable: boolean) {
    this._metadata.sharedAccess = sharable;
    await this.updateRemote({ witZip: false });
  }

  debounceUpdateRemote = debounce(() => this.updateRemote(), 20000);
  async updateRemote({ witZip = true } = {}) {
    const { name, description, customMetaData, sharedAccess, id, isEditable } = this._metadata;
    if (isEditable === false) return;
    let content = new Uint8Array();

    if (witZip) {
      const zip = await this.account.storage.zipDirectory(this.root);
      content = await zip.generateAsync({ type: "uint8array" });
    }

    await this.account.library.updateProject(id, {
      projectType: customMetaData.projectType,
      description,
      sharedAccess,
      content,
      name,
    });

    UIManager.notice("Project saved");
  }

  updateContent = async (file: string, data: string | object | Array<any>, initiator?: string) => {
    if (this._metadata.isEditable === false) return;
    const content = typeof data === "object" ? JSON.stringify(data) : data;
    await this.account.storage.write(this.path(file), content);
    this._onDidChange.fire({ file, content, initiator });
    void this.debounceUpdateRemote();

    await runInAction(async () => {
      this._metadata.updatedAt = new Date().toISOString();
      await this.writeDefinition();
    });
  };

  createFile = async (name: string, node?: FSStructure) => {
    if (this._metadata.isEditable === false) return;
    const path = node?.path ? node.path + "/" + name : name;
    if (await this.account.storage.exist(this.path(path))) return;

    await this.account.storage.write(this.path(path), "");
    void this.debounceUpdateRemote();
    this._onDidCreate.fire(path);
    this.updateFiles();
  };

  createFolder = async (name: string, node?: FSStructure, forceUpdate = true) => {
    if (this._metadata.isEditable === false) return;
    const path = (node?.path ?? "") + "/" + name;
    if (await this.account.storage.exist(this.path(path))) return;

    await this.account.storage.mkdir(this.path(path));
    void this.debounceUpdateRemote();
    if (forceUpdate) this.updateFiles();
  };

  deleteFile = async (node: FSStructure) => {
    if (this._metadata.isEditable === false) return;
    const removes = await this.account.storage.delete(this.path(node.path));
    removes.forEach((path) => this._onDidRemove.fire(path.replace(this.path() + "/", "")));
    void this.debounceUpdateRemote();
    this.updateFiles();
  };

  renameFile = async (node: FSStructure, name: string) => {
    if (this._metadata.isEditable === false) return;
    const parent = node.path.split("/").slice(0, -1).join("/");
    const path = parent ? parent + "/" + name : name;
    if (await this.account.storage.exist(this.path(path))) return;

    if (node.type === "folder") {
      const zip = await this.account.storage.zipDirectory(this.path(node.path));
      await this.account.storage.delete(this.path(node.path));
      await this.account.storage.writeFromZip(this.path(path), zip);
      void this.debounceUpdateRemote();
      this.updateFiles();

      return Object.values(zip.files)
        .filter((file) => !file.dir)
        .forEach((file) => {
          this._onDidRename.fire({
            old: node.path + "/" + file.name,
            rename: path + "/" + file.name,
          });
        });
    }

    const content = await this.account.storage.readString(this.path(node.path));
    await this.account.storage.delete(this.path(node.path));
    await this.account.storage.write(this.path(path), content);
    this._onDidRename.fire({ old: node.path, rename: path });
    void this.debounceUpdateRemote();
    this.updateFiles();
  };
}

export default Project;
