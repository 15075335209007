import React, { useState, ReactNode, useEffect } from "react";

import { ActionsWrapper, Collapse, StyledFile, VerticalLine } from "./Tree.style";
import { PlaceholderInput } from "./TreePlaceholderInput";
import { useTreeContext } from "./TreeContext";

import { MenuOption, PopupMenu } from "../../uikit/popup";
import { PureButton } from "../../uikit/Button";
import { Icon } from "../../uikit";

import DirOpenIcon from "../icons/dir-open.svg";
import DirCloseIcon from "../icons/dir-close.svg";

const Folder = ({ name, selected, children, indent, node, forceOpen }) => {
  const { isEditable, onDelete, onRename, onCreateFile, onCreateFolder } = useTreeContext();
  const [editable, setEditable] = useState<ReactNode>(null);
  const [isEditing, setEditing] = useState(false);
  const [isOpen, setIsOpen] = useState(forceOpen);

  useEffect(() => {
    if (forceOpen) setIsOpen(true);
  }, [forceOpen, selected])

  const commitDeleteFolder = () => onDelete?.(node);
  const commitFolderCreation = (name: string) => {
    onCreateFolder?.(name, node);
    setEditable(null);
  };

  const commitFileCreation = (name: string) => {
    onCreateFile?.(name, node);
    setEditable(null);
  };

  const commitFolderEdit = (name: string) => {
    onRename?.(node, name);
    setEditing(false);
  };

  const handleCancel = () => {
    setEditing(false);
    setEditable(null);
  };

  const handleFileCreation = () => {
    setIsOpen(true);
    setTimeout(() => {
      setEditable(
        <PlaceholderInput
          key={Date.now()}
          type="file"
          style={{ paddingLeft: indent + 16 }}
          onSubmit={commitFileCreation}
          onCancel={handleCancel}
        />
      );
    }, 200);
  };

  const handleFolderCreation = () => {
    setIsOpen(true);
    setTimeout(() => {
      setEditable(
        <PlaceholderInput
          key={Date.now()}
          type="folder"
          style={{ paddingLeft: indent + 16 }}
          onSubmit={commitFolderCreation}
          onCancel={handleCancel}
        />
      );
    }, 200);
  };

  const handleFolderRename = () => {
    setIsOpen(true);
    setEditing(true);
  };

  const actions: MenuOption[] = [
    {
      label: "Rename folder",
      action: handleFolderRename,
      isEnable: true,
    },
    {
      label: "Create file",
      action: handleFileCreation,
      isEnable: true,
    },
    {
      label: "Create folder",
      action: handleFolderCreation,
      isEnable: true,
    },
    {
      label: "Delete folder",
      action: commitDeleteFolder,
      isEnable: true,
    },
  ];

  if (isEditing) {
    return (
      <VerticalLine>
        <PlaceholderInput
          type="folder"
          defaultValue={name}
          onCancel={handleCancel}
          onSubmit={commitFolderEdit}
          style={{ paddingLeft: indent }}
        />
        <Collapse isOpen={isOpen}>{children}</Collapse>
      </VerticalLine>
    );
  }

  return (
    <VerticalLine>
      <StyledFile onClick={() => setIsOpen(!isOpen)} style={{ paddingLeft: indent }}>
        <ActionsWrapper>
          <p>
            {isOpen ? <DirOpenIcon /> : <DirCloseIcon />}
            &nbsp;&nbsp;
            {name}
          </p>

          {isEditable && (
            <PopupMenu actions={actions} closeAfterSelect on="click" position={["bottom center", "top center"]}>
              <PureButton>
                <Icon name="menu" />
              </PureButton>
            </PopupMenu>
          )}
        </ActionsWrapper>
      </StyledFile>

      <Collapse isOpen={isOpen}>
        {children}
        {editable}
      </Collapse>
    </VerticalLine>
  );
};

export { Folder };
