import React from "react";
import ReactDOM from "react-dom";
import * as dasha from "@dasha.ai/sdk/web";
import * as Sentry from "@sentry/react";

import App from "./app";
import ErrorBoundary from "./ErrorBoundary";
import 'semantic-ui-css/semantic.min.css';
// Sentry.init({
//   dsn: "https://4259193be5fe4edd894cb12bcfe696d5@sentry.dev.az.dasha.ai/45",
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

dasha.log.on("data", ({ level, message, stack }) => {
  if (level !== "error") return;
  const err = new Error(message);
  err.stack = stack;
  Sentry.captureException(err);
});

const SentryApp = Sentry.withErrorBoundary(App, { fallback: ErrorBoundary });

ReactDOM.render(<SentryApp />, document.getElementById("root"));

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./sw.js", { scope: "/" })
    .then((reg) => {
      console.log("Registration succeeded. Scope is " + reg.scope);
    })
    .catch((error) => {
      console.log("Registration failed with " + error);
    });
}
