import { IAccount } from "@core/account/interface";
import React, { FC, useState, useEffect, useCallback } from "react";
import ReactDiffViewer from "react-diff-viewer";
import "./index.css";
import {
  Card,
  Checkbox,
  Input,
  List,
  ListItem,
  Loader,
  Message,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  TabPane,
  TextArea,
} from "semantic-ui-react";
import * as dasha from "@dasha.ai/sdk/web";
import { IHistoryMessage } from "@dasha.ai/sdk/web/gpt";
import DashaMessage from "../RunnerPanel/DashaMessage";
import { ActionButton } from "../uikit";
import { FunctionEditorCard } from "./functionEditor";
import { GptOptionsTable } from "./gptOptions";
import CopyButtonAction from "../uikit/CopyButtonAction";

interface Props {
  account: IAccount;
  devLogs: any[];
  gptInstanceId: string;
}

function transformToHistory(message): IHistoryMessage {
  if (message.msgId === "GptFunctionCallRequestMessage") {
    return {
      type: "call",
      functionCallName: message.FunctionName,
      functionCallArgs: message.Args,
    };
  }
  return {
    type: "Text",
    text: message.Message,
    thinking: message.Metadata?.Thinking ?? undefined,
  };
}

const objectMap = (obj, fn) => Object.fromEntries(Object.entries(obj).map(([k, v], i) => [k, fn(v, k, i)]));

export const GptEmulatorModal: FC<Props> = ({ account, devLogs, gptInstanceId }) => {
  const [prompt, setPrompt] = useState<string>("");
  const [initialPrompt, setInitialPrompt] = useState<string>("");
  const [options, setOptions] = useState<Record<string, string | undefined>>({});
  const [initialOptions, setInitialOptions] = useState<Record<string, string | undefined>>({});
  const [functions, setFunctions] = useState<Record<string, any>>({});
  const [initialFunctions, setInitialFunctions] = useState<Record<string, any>>({});
  const [error, setError] = useState<string | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [emulationResult, setEmulationResult] = useState<dasha.gpt.IHistoryMessage[][]>([]);
  const [refResult, setRefResult] = useState<dasha.gpt.IHistoryMessage[]>([]);

  useEffect(() => {
    saveChanges();
    const startMessage = devLogs.find(
      (message) => message.msg.msgId === "GptStartMessage" && message.msg.InstanceId === gptInstanceId
    );

    const refMessages = devLogs
      .filter(
        (message) =>
          (message.msg.msgId === "GptResponseMessage" || message.msg.msgId === "GptFunctionCallRequestMessage") &&
          message.msg.InstanceId === gptInstanceId
      )
      .map((message) => transformToHistory(message.msg));
    setEmulationResult([]);
    setInitialPrompt(startMessage.msg.Prompt);
    setInitialOptions(startMessage.msg.Options);

    const originalFunctions = objectMap(startMessage.msg.Functions, (f) => {
      return { ...f, ...{ Parameters: JSON.parse(f.Parameters) } };
    });
    setInitialFunctions(originalFunctions);
    const localStored = localStorage.getItem("prompt:"+startMessage.msg.Prompt);
    if (localStored != null) {
      const localStoredObj = JSON.parse(localStored);
      setPrompt(localStoredObj.prompt ?? startMessage.msg.Prompt);
      setOptions(localStoredObj.options ?? startMessage.msg.Options);
      setFunctions({...originalFunctions, ...localStoredObj.functions});

    } else {
      setPrompt(startMessage.msg.Prompt);
      setOptions(startMessage.msg.Options);
      setFunctions(originalFunctions);
    }

    setRefResult(refMessages);
    setError(undefined);
  }, [devLogs, gptInstanceId]);

  const saveChanges = useCallback(() => {
    if (initialPrompt !== "") {
      localStorage.setItem("prompt:"+initialPrompt, JSON.stringify({
        prompt: prompt,
        functions: functions,
        options: options
      }));
    }
  }
  ,[prompt, functions, options]);
  const gptEmulateTry = useCallback(
    (countTries) => {
      setError(undefined);
      saveChanges();
      setLoading(true);
      setEmulationResult([]);
      const fetchData = async () => {
        const a = await account.connect();
        const remapped = objectMap(functions, (f) => {
          return { ...f, ...{ Parameters: JSON.stringify(f.Parameters) } };
        });

        const response = await dasha.gpt.runGptEmulation(
          {
            debugLog: devLogs,
            emulatedInstanceId: gptInstanceId,
            functions: remapped,
            options: options,
            prompt: prompt,
          },
          countTries,
          { account: a }
        );
        const _error = response[0].error;
        if (_error !== null && _error !== undefined) {
          setError(_error);
        } else {
          setEmulationResult(response.map((x) => x.gptResponse ?? []));
        }
      };
      fetchData()
        .catch((e) => setError(e.message))
        .finally(() => setLoading(false));
    },
    [devLogs, gptInstanceId, account, prompt, options, functions]
  );
  const RenderListItem = (x: IHistoryMessage) => {
    switch (x.type) {
      case "Text":
        return (
          <DashaMessage
            msg={{
              from: "ai",
              transitions: [],
              changeContext: {},
              triggers: [],
              message: x.text,
              thinking: x.thinking,
              time: 0,
              id: 0,
              isSystem: false,
            }}
          />
        );
      case "call":
        return (
          <DashaMessage
            msg={{
              from: "ai",
              transitions: [],
              changeContext: {},
              triggers: [],
              message: `Start call ${x.functionCallName}(${JSON.stringify(x.functionCallArgs)}`,
              thinking: undefined,
              time: 0,
              id: 0,
              isSystem: true,
            }}
          />
        );
      default:
        return <div>Unexpected message {x.type}</div>;
    }
  };

  const onUpdateFunctionDefinition = useCallback(
    (functionName, functionValue) => {
      const override = {};
      override[functionName] = functionValue;
      const newFunctions = { ...functions, ...override };
      setFunctions(newFunctions);
    },
    [functions]
  );

  const updateOption = useCallback(
    (optionName, optionValue) => {
      if (optionValue === "") {
        optionValue = undefined;
      }
      const newOptions = { ...options };
      newOptions[optionName] = optionValue;
      setOptions(newOptions);
    },
    [options]
  );

  const panes = [
    {
      menuItem: "Prompt",
      render: () => (
        <TabPane attached={false}>
          <TextArea
            rows={10}
            placeholder="Prompt"
            value={prompt}
            className="dasha-textarea"
            onChange={(event, data) => setPrompt(data.value as string)}
          />
          <CopyButtonAction tooltipText="Copy Prompt" clipboard={"`"+prompt+"`"} hasIcon={false}>
              Copy prompt
          </CopyButtonAction>
        </TabPane>
      ),
    },
    {
      menuItem: "PromptDiff",
      render: () => (
        <TabPane attached={false}>
          <ReactDiffViewer oldValue={initialPrompt} newValue={prompt} splitView={true} />
          <ActionButton onClick={()=>setPrompt(initialPrompt)}> Reset prompt changes </ActionButton>
        </TabPane>
      ),
    },
    {
      menuItem: "Options",
      render: () => (
        <TabPane attached={false}>
          <GptOptionsTable options={options} initialOptions={initialOptions} onUpdateOption={updateOption} />
          <ActionButton onClick={()=>setOptions(initialOptions)}> Reset options changes </ActionButton>
        </TabPane>
      ),
    },
    {
      menuItem: "Functions",
      render: () => (
        <TabPane attached={false}>
          <Card.Group itemsPerRow={1}>
            {Object.entries(functions).map((x) => (
              <FunctionEditorCard
                function_name={x[0]}
                function_value={x[1]}
                updateFunctionDefinition={onUpdateFunctionDefinition}
              />
            ))}
          </Card.Group>
          <ActionButton onClick={()=>setFunctions(initialFunctions)}> Reset functions changes </ActionButton>
        </TabPane>
      ),
    },
  ];

  return (
    <>
      <Tab menu={{ pointing: true }} panes={panes} />
      <Loader active={loading}>Loading..</Loader>
      {error && <Message negative>{error}</Message>}
      <Table className="dasha-semantic-table" columns={4}>
        <TableHeader>
          <TableRow>
            <TableHeaderCell>Reference</TableHeaderCell>
            <TableHeaderCell>
              <ActionButton onClick={() => gptEmulateTry(1)} disabled={loading}>
                Generate
              </ActionButton>
            </TableHeaderCell>
            <TableHeaderCell>

            </TableHeaderCell>
            <TableHeaderCell>
              <ActionButton onClick={() => gptEmulateTry(3)} disabled={loading}>
                Generate 3x
              </ActionButton>
            </TableHeaderCell>            
          </TableRow>
        </TableHeader>

        <TableBody>
          <TableRow>
            <TableCell>
              <List>
                {refResult.map((x) => {
                  return <ListItem>{RenderListItem(x)}</ListItem>;
                })}
              </List>
            </TableCell>
            {emulationResult.map((x) => (
              <TableCell>
                <List>
                  {x.map((y) => {
                    return <ListItem>{RenderListItem(y)}</ListItem>;
                  })}
                </List>
              </TableCell>
            ))}
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
};
