import { observer } from "mobx-react-lite";
import React, { FC, Suspense, useEffect, useState, useCallback } from "react";

import Playground from "../core/Playground";
import HeaderPanel from "../HeaderPanel";
import { MenuOption } from "../uikit/popup";

import { projectSort, projectTypes, projectWorkflow } from "./types";
import { getIntelemediaPods } from "./IntelemediaPanel/create";
import * as S from "./styled";
import InspectorPanel from "../InspectorPanel";
import DiagnosticsPanel from "../DiagnosticsPanel";
import { InboundVoIP } from "../DiagnosticsPanel/InboundVoIP";
import { OutboundVoIP } from "../DiagnosticsPanel/OutboundVoIP";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { ProjectsView } from "./Project/ProjectsView";
import { ApiKey } from "../DiagnosticsPanel/ApiKey";
import { Tts } from "../DiagnosticsPanel/Tts";
import { UsagePanel } from "../DiagnosticsPanel/Usage";
import { EventsPanel } from "../DiagnosticsPanel/Events";
import { OnlineProfiler } from "../DiagnosticsPanel/OnlineProfiler";
import LoginAs from "../DiagnosticsPanel/LoginAs";
import { FailedConversations } from "../DiagnosticsPanel/FailedConversations";
import { MissedCalls } from "../DiagnosticsPanel/MissedCalls";

const IntelemediaPanel = React.lazy(() => import("./IntelemediaPanel"));

const ExplorerView: FC<{ ide: Playground }> = ({ ide }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname;

  const [intelemediaPods, setIntelemediaPods] = useState<string[]>([]);
  useEffect(() => {
    if (ide.accounts.account == null) {
      setIntelemediaPods([]);
      return;
    }

    void getIntelemediaPods(ide.accounts.account).then(setIntelemediaPods);
  }, [ide.accounts.account]);

  const imPanel = () => {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <IntelemediaPanel account={ide.explorer.account} pods={intelemediaPods} />
      </Suspense>
    );
  };
  const setAccount = useCallback((account) => {
    const switchAccount = async() => {
      await ide.changeAccount(account);
    };
    switchAccount();
  }, [ide])
  const renderView = () => {
    return (
      <Routes>
        {ide.accounts.isAuth && 
        <>
          <Route path="intelemedia" Component={imPanel} />
          <Route path="inspector/:id" element={<InspectorPanel account={ide.explorer.account} explorer={ide.explorer} />} />
          <Route path="inspector" element={<InspectorPanel account={ide.explorer.account} explorer={ide.explorer}/>} />
          <Route path="apikey" element={<ApiKey account={ide.explorer.account} />} />
          <Route path="diagnostics" element={<DiagnosticsPanel account={ide.explorer.account} />} />
          <Route path="diagnostics/:customerId" element={<DiagnosticsPanel account={ide.explorer.account} />} />
          <Route path="failed" element={<FailedConversations account={ide.explorer.account} />} />
          <Route path="failed/:customerId" element={<FailedConversations account={ide.explorer.account} />} />

          <Route path="voip/missed" element={<MissedCalls account={ide.explorer.account} />} />
          <Route path="voip/missed/:customerId" element={<MissedCalls account={ide.explorer.account} />} />
          <Route path="voip/inbound" element={<InboundVoIP account={ide.explorer.account} ide={ide} />} />
          <Route path="voip/inbound/:customerId" element={<InboundVoIP account={ide.explorer.account} ide={ide} />} />
          <Route path="voip/outbound" element={<OutboundVoIP account={ide.explorer.account} />} />
          <Route path="voip/outbound/:customerId" element={<OutboundVoIP account={ide.explorer.account} />} />
          <Route path="tts" element={<Tts account={ide.explorer.account} />} />
          <Route path="usage" element={<UsagePanel account={ide.explorer.account} />} />
          <Route path="usage/:customerId" element={<UsagePanel account={ide.explorer.account} />} />
          <Route path="events" element={<EventsPanel account={ide.explorer.account} />} />
          <Route path="events/:customerId" element={<EventsPanel account={ide.explorer.account} />} />
          <Route path="loginas" element={<LoginAs account={ide.explorer.account} setAccount={setAccount}/>}/>
          <Route path="conversations" element={<OnlineProfiler account={ide.explorer.account} />} />
          <Route path="conversations/:customerId" element={<OnlineProfiler account={ide.explorer.account} />} />
        </>
        }
        {Object.keys(projectTypes).map((id) => (
          <>
          <Route path={`apps/${id}`} element={<ProjectsView ide={ide} filter={id} />} />
          </>
        ))}
        <Route index element={<ProjectsView ide={ide} filter="all" />} />
      </Routes>
    );
  };

  return (
    <S.Layout powerbi={!location.pathname.startsWith("/apps")&&location.pathname!=="/"}>
      <HeaderPanel id="header" useLogo workspace={ide.workspace} accounts={ide.accounts} explorer={ide.explorer} />
      <S.Aside id="aside">
        <S.Header>All apps</S.Header>
        {Object.entries(projectTypes).map(([id, obj]) => (
          <S.Option key={id} isSelected={"/apps/" + id === path} onClick={() => navigate("/apps/" + id)}>
            {obj.icon} {obj.label}
          </S.Option>
        ))}

        {intelemediaPods.length > 0 && (
          <>
            <S.Header>Сustom settings</S.Header>
            <S.Option isSelected={path === "/intelemedia"} onClick={() => navigate("/intelemedia")}>
              Intelemedia
            </S.Option>
          </>
        )}
        {ide.accounts.isAuth && (
          <>
            <S.Option isSelected={path === "/apikey"} onClick={() => navigate("/apikey")}>
              Api Key
            </S.Option>
            <S.Option isSelected={path === "/inspector"} onClick={() => navigate("/inspector")}>
              Inspector
            </S.Option>
            <S.Option isSelected={path === "/diagnostics"} onClick={() => navigate("/diagnostics")}>
              Diagnostics
            </S.Option>
            <S.Option isSelected={path === "/voip/inbound"} onClick={() => navigate("/voip/inbound")}>
              Inbound VoIP
            </S.Option>
            <S.Option isSelected={path === "/voip/outbound"} onClick={() => navigate("/voip/outbound")}>
              Outbound VoIP
            </S.Option>
            <S.Option isSelected={path === "/conversations"} onClick={() => navigate("/conversations")}>
              Conversations
            </S.Option>
            <S.Option isSelected={path === "/usage"} onClick={() => navigate("/usage")}>
              Usage
            </S.Option>
            <S.Option isSelected={path === "/events"} onClick={() => navigate("/events")}>
              Events
            </S.Option>   
            <S.Option isSelected={path === "/tts"} onClick={() => navigate("/tts")}>
              Text to Speech
            </S.Option>            
          </>
        )}
      </S.Aside>
      {renderView()}
    </S.Layout>
  );
};

export default observer(ExplorerView);
