import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";
import { Dropdown, Input, TextArea, Message, Modal, Button } from "semantic-ui-react";
import "./styles.css";
import Playground from "../core/Playground";
import { CopyInput } from "../uikit/CopyInput";

export const VoIPCallReceiver: FC<{ ide: Playground }> = ({ ide }) => {
  const [open, setOpen] = useState(false);
  const [uri, setUri] = useState<string | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);

  useEffect(() => {
    setError(undefined);
    const fetchData = async () => {
      setUri(await ide.sip.initialize());
    };
    fetchData().catch((e) => setError(e.message));
  }, [ide]);

  const onClose = useCallback(() => {
    setError(undefined);
    const fetchData = async () => {
      await ide.sip.hangup();
    };
    fetchData()
      .catch((e) => setError(e.message))
      .finally(() => setOpen(false));
  }, [ide]);

  return (
    <Modal
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      open={open}
      trigger={<Button icon="phone">Receive call in browser</Button>}
    >
      <Modal.Header>Receive call in browser</Modal.Header>
      <Modal.Content>
        {error && <Message negative>{error} </Message>}
        <h3>Call to this uri instead on phone number in Dasha.AI application</h3>
        <CopyInput clipboard={uri} loading={uri === undefined} tooltipText="#connectSafe argument" type="text" />
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={onClose}>
          Finish
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
