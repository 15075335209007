import { IAccount } from "../interface";
import RestApi from "./fetch";

export enum DeployStatusEnum {
  Stop = "Stop",
  Pending = "Pending",
  Failed = "Failed",
  Running = "Running",
}

export interface DeployResponse {
  id: string;
  customerId: string;
  deploymentStatus: DeployStatusEnum;
  sharedProjectId: string;
  projectId: string;
  relativePath: string;
  gitBranch?: string;
  gitCommit?: string;
}

export interface DeployStatus {
  deploymentStatus: DeployStatusEnum;
  message: string;
}

class DeployApi extends RestApi {
  domain = "api/v1/application/deployment";

  async checkAccess(account: IAccount): Promise<boolean> {
    try {
      const fetch = await this.signFetch(account);
      await fetch(`list`, { method: "get" });
      return true;
    } catch (e) {
      return false;
    }
  }

  async list(account: IAccount): Promise<DeployResponse[]> {
    try {
      const fetch = await this.signFetch(account);
      const resp = await fetch(`list`, { method: "get" });
      const data = await resp.json();
      return data;
    } catch {
      return [];
    }
  }

  async projectDeploys(id: string, account: IAccount): Promise<DeployResponse[]> {
    try {
      const fetch = await this.signFetch(account);
      const resp = await fetch(`${id}/list`, { method: "get" });
      const data = await resp.json();
      return data;
    } catch {
      return [];
    }
  }

  async get(id: string, account: IAccount): Promise<DeployResponse> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(id, { method: "get" });

    const data = await resp.json();
    return data;
  }

  async url(id: string, account: IAccount): Promise<string> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`${id}/url`, { method: "get" });
    const data = await resp.json();
    return data;
  }

  async start(id: string, account: IAccount): Promise<string> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`${id}/deploy`, { method: "post" });
    const data = await resp.text();
    return data;
  }

  async delete(id: string, account: IAccount): Promise<boolean> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(id, { method: "delete" });
    return resp.ok;
  }

  async stop(id: string, account: IAccount): Promise<boolean> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`${id}/stop`, { method: "post" });
    return resp.ok;
  }

  async status(id: string, account: IAccount): Promise<DeployStatus> {
    const fetch = await this.signFetch(account);
    const resp = await fetch(`${id}/status`, { method: "get" });
    const data = await resp.json();
    return data;
  }

  async create(project: string, dir: string, account: IAccount): Promise<string> {
    const fetch = await this.signFetch(account);
    const resp = await fetch("", {
      method: "post",
      body: JSON.stringify({
        sharedProjectId: project,
        relativePath: dir,
      }),
    });

    const data = await resp.json();
    return data;
  }
}

export default DeployApi;
