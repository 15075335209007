import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Modal, Table, Dropdown, Button, Pagination, DropdownItemProps } from "semantic-ui-react";
import "./styles.css";
import { Loader } from "semantic-ui-react";
import { createDownloadLink } from "@core/profiler/utils";
import DateSelector from "../ProfilerPanel/components/ProfilerWidget/components/DateSelector";
import { Link, useParams } from "react-router-dom";
import { useHistory, useLocation } from 'react-router';
import { TaskStatus } from "@dasha.ai/sdk/web/jobs";



const getColumnValue = (columnName: string, obj: any) => {
  const subcols = columnName.split(".");
  for (var c of subcols) {
    if (obj === undefined || obj === null) {
      break;
    }
    obj = obj[c];
  }
  return JSON.stringify(obj);
}

const isObject = function(a) {
  return (!!a) && (a.constructor === Object);
};

const buildColumnListRecursive = (obj: any, target: Set<string>, root: string, depth: number) => {
  const columns = obj;
  for (const currentColumn of Object.keys(obj)) {
    const fullPath = root != "" ? `${root}.${currentColumn}` : currentColumn;
    target.add(fullPath);
    if (isObject(obj[currentColumn]) && depth + 1 < 2) {
      buildColumnListRecursive(obj[currentColumn], target, fullPath, depth + 1);
    }
  }
}

const availableStatuses: DropdownItemProps[] = [{text: "Failed", value: "Failed"}, {text: "Completed", value: "Completed"}];

export const OnlineProfiler: FC<{ account: IAccount }> = ({ account }) => {
  const { customerId } = useParams();
  const [dataRows, setDataRows] = useState<dasha.onlineprofiler.ProfilerEntity[]>([]);
  const [applicationNames, setApplicationNames] = useState([]);
  const [taskStatuses, setTaskStatuses] = useState<string[]>([]);
  const [selectedApplicationNames, setSelectedApplicationNames] = useState<string[]>([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(true);
  const [dateRange, setDateRange] = useState({ end: new Date(), start: new Date(new Date().setDate(1)) });
  const [filters, setFilters] = useState<dasha.filters.IFilter[]>([]);
  const [columns, setColumns] = useState<{key: string; value: string; text: string;}[]>([]);

  const [selectedColumns, setSelectedColumns] = useState(["completedTime", "applicationName", "input", "output"]);

  const handleDateChange = (dates: [Date, Date]) => {
    setDateRange({ start: dates[0], end: dates[1] });
  };

  useEffect(() => {
    const f: dasha.filters.IFilter[] = [
      new dasha.filters.StartedTimeFilter(dateRange.start ? new Date(dateRange.start.setHours(0, 0, 0, 0)) : undefined, dateRange.end ? new Date(dateRange.end.setHours(23, 59, 59, 999)) : undefined),
    ];
    if (selectedApplicationNames.length > 0) {
      f.push(new dasha.filters.ApplicationNamesFilter(selectedApplicationNames));
    }
    if (customerId !== undefined) {
      f.push(new dasha.filters.CustomerIdsFilter([customerId]));
    }
    if (taskStatuses.length > 0) {
      f.push(new dasha.filters.JobStatusesFilter(taskStatuses));
    }
    setFilters(f);
    
  }, [customerId, selectedApplicationNames, dateRange, taskStatuses]);

  useEffect(() => {
    if (filters.length === 0) {
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      const a = await account.connect();
      const result = await dasha.onlineprofiler.getData(50, (page - 1)*50, filters, { account: a });

      if (result.length < 50) {
        setTotalPages(page);
      } else {
        setTotalPages(page + 1);
      }
      setDataRows(result);

      if (selectedApplicationNames.length === 0) {
        try {
          const fillFilters = await dasha.onlineprofiler.getFilters(filters, [new dasha.filters.ApplicationNamesFilter([])], {
            account: a,
          });
          setApplicationNames(
            (fillFilters[0] as dasha.filters.ApplicationNamesFilter).values.map((x) => ({ text: x, key: x, value: x }))
          );
        } catch (e) {
          console.error(e, "Failed to fetch filters");
        }
      }

      const newCols = new Set<string>();
      
      for (const row of result) {
        buildColumnListRecursive(row, newCols, "", 0);
      }
      setColumns([...newCols].map((x) => ({ key: x, text: x, value: x })));
    };
    fetchData().finally(() => setLoading(false));
  }, [account, filters, page]);


  const exportToCsv = useCallback(() => {
    const fetchData = async () => {
      const a = await account.connect();
      const csvData = await dasha.onlineprofiler.getCsvBlob(filters, { account: a });

      const fname = selectedApplicationNames.join("_") + "_" + 
        (dateRange.start?.toDateString() ?? "any") + "_" + 
        (dateRange.end?.toDateString() ?? "any") + ".csv";
      createDownloadLink([csvData], "text/csv", fname);
    };
    fetchData().finally(() => setLoading(false));
    
  }, [account, filters, selectedApplicationNames, dateRange]);


  return (
    <div className="defaultRoot" id="grid-workspace">
      <h2>Detailed information</h2>
      <label htmlFor="date-range">Date range:</label>
      <DateSelector dateRange={dateRange} onChange={handleDateChange} />

      {/* <h2>Status</h2>
      <Dropdown
        placeholder="Status"
        fluid
        multiple
        search
        selection
        options={availableStatuses}
        value={taskStatuses}
        onChange={(e, { value }) => setTaskStatuses(value)}
      /> */}

      <h2>Applications</h2>
      <Dropdown
        placeholder="Applications"
        fluid
        multiple
        search
        selection
        options={applicationNames}
        value={selectedApplicationNames}
        onChange={(e, { value }) => setSelectedApplicationNames(value)}
      />

      <h2>Columns</h2>
      <Dropdown
        placeholder="Columns"
        fluid
        multiple
        search
        selection
        options={columns}
        value={selectedColumns}
        onChange={(e, { value }) => setSelectedColumns(value)}
      />

      <Loader active={loading}>Loading..</Loader>
      <Button onClick={exportToCsv} disabled={loading}> Download CSV </Button>
      <Table celled sortable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Inspect</Table.HeaderCell>
            {selectedColumns.map((c) => (
              <Table.HeaderCell>{ c }</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loading && (
            <Table.Row>
              <Table.Cell>
                <Loader />
              </Table.Cell>
            </Table.Row>
          )}
          {Object.entries(dataRows).map(([k, c]) => {
            return (
              <Table.Row negative={c.taskStatus === "Failed"}>
                <Table.Cell><Link to={`/inspector/${c.jobId}`} target="_blank" rel="noopener noreferrer">Inspect</Link></Table.Cell>
                {selectedColumns.map((col) => (
                  <Table.Cell>{ getColumnValue(col, c) }</Table.Cell>
                ))}
              </Table.Row>
            );
          })}
        </Table.Body>
        <Table.Footer>
          <Table.Row>

          </Table.Row>
        </Table.Footer>
      </Table>
      <Pagination activePage={page} totalPages={totalPages} onPageChange={(e, value) => setPage(value.activePage as number)} />
    </div>
  );
};
