import * as SIP from "sip.js";
import * as dasha from "@dasha.ai/sdk/web";
import { v4 as uuidv4 } from "uuid";
import { Emitter, IDisposable } from "./misc/emitter";

export interface AudioControllerProtocol {
  onFrequencyDataFromMicrophone(event: (buffer: Uint8Array) => void): IDisposable;
}

// @ts-ignore
const AudioContext = window.AudioContext ?? window.webkitAudioContext;

export default class SIPClient implements AudioControllerProtocol {
  private sip: SIP.Web.SimpleUser;
  private audio = new Audio();
  private isInitialized = false;
  private logger: typeof dasha.log;

  private audioCtx = new AudioContext();
  private analyser = this.audioCtx.createAnalyser();
  private dataArray = new Uint8Array(this.analyser.frequencyBinCount);
  private mediaStreamSource?: MediaStreamAudioSourceNode;
  private loopId?: number;

  public endpoint: string;
  public server: string;

  private readonly _onFrequencyDataFromMicrophone = new Emitter<Uint8Array>();
  public readonly onFrequencyDataFromMicrophone = this._onFrequencyDataFromMicrophone.event;

  constructor(public domain: string) {
    this.logger = dasha.log.child({ label: "sip" });

    this.endpoint = `sip:reg-${uuidv4()}@${domain}`;
    this.server = `wss://${domain}/sip/connect`;

    this.analyser.fftSize = 2048;

    const options: SIP.Web.SimpleUserOptions = {
      aor: this.endpoint,
      media: {
        constraints: {
          audio: { echoCancellation: true },
        } as any, // badly typed library,
      },
      delegate: {
        onServerDisconnect: (error) => {
          this.logger.error(error);
          this.isInitialized = false;
        },
        onCallReceived: async () => {
          try {
            this.mediaStreamSource?.disconnect();

            this.logger.info("call received");
            await this.sip.answer();

            this.audio.srcObject = this.sip.remoteMediaStream ?? null;
            await this.audio.play();

            if (this.sip.localMediaStream) {
              this.mediaStreamSource = this.audioCtx.createMediaStreamSource(this.sip.localMediaStream);
              this.mediaStreamSource.connect(this.analyser);
              this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
              await this.audioCtx.resume();

              this.loopId = requestAnimationFrame(this._loop);
            }
          } catch (error) {
            this.logger.error(error);
          }
        },
        onCallHangup: () => {
          this.logger.info("call hangup");
          if (this.loopId) cancelAnimationFrame(this.loopId);
          this.audio.srcObject = null;
        },
      },
    };

    this.sip = new SIP.Web.SimpleUser(this.server, options);
  }

  private _loop = () => {
    this.analyser.getByteFrequencyData(this.dataArray);
    this.loopId = requestAnimationFrame(this._loop);
    this._onFrequencyDataFromMicrophone.fire(this.dataArray);
  };

  async dispose(): Promise<void> {
    await this.sip.unregister();
  }

  async hangup(): Promise<void> {
    await this.sip.hangup().catch((e) => {});
  }
  async initialize(): Promise<string> {
    if (this.isInitialized) {
      return this.endpoint;
    }

    try {
      this.logger.info("connecting...");
      await this.sip.connect();

      this.logger.info("registering...");
      await this.sip.register();

      this.isInitialized = true;
      return this.endpoint;
    } catch (error) {
      this.logger.error(error);
      throw error;
    }
  }
}
