import React, { FC, useEffect, useState, useRef } from "react";
import dayjs from "dayjs";

import ArrowUpIcon from "../../assets/arrow-up.svg";
import ArrowDownIcon from "../../assets/arrow-down.svg";
import ClockIcon from "../../assets/clock.svg";
import { getPartValues, formatTime } from "./helpers";
import { getDateWithTime } from "../DateSelector/helpers";

import * as S from "./styled";
import { RangeDate } from "../DateSelector/types";

interface Props {
  dateRange: {
    start: Date;
    end: Date;
  };
  onRangeChange: ([start, end]: [RangeDate, RangeDate]) => void;
}

interface TimePopperProps {
  date: Date;
  onChange: (time: string) => void;
  onClose: () => void;
}

type TimeKey = "halfDay" | "hours" | "minutes";

const TIME_FORMAT = "hh:mm a";

const timeColumns: { hours: number[]; minutes: number[]; halfDay: string[] } = {
  hours: Array.from({ length: 12 }, (_, i) => i + 1),
  minutes: Array.from({ length: 60 }, (_, i) => i),
  halfDay: ["AM", "PM"],
};

const TimePopper: FC<TimePopperProps> = ({ date, onChange, onClose }) => {
  const [time, setTime] = useState<{ hours: number; minutes: number; halfDay: string }>({
    hours: +dayjs(date).format("h"),
    minutes: +dayjs(date).format("m"),
    halfDay: dayjs(date).format("A"),
  });
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    onChange(formatTime(time));
  }, [time]);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!el.current) return;

      if (!el.current.contains(e.target)) {
        onClose();
      }
    };

    document.body.addEventListener("click", handleClickOutside);

    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);

  const handleNextClick = (currentIndex: number, id: TimeKey) => {
    if (currentIndex !== timeColumns[id].length - 1) {
      setTime((prevValue) => ({ ...prevValue, [id]: timeColumns[id][currentIndex + 1] }));
    }
  };

  const handlePrevClick = (currentIndex: number, id: TimeKey) => {
    if (currentIndex !== 0) {
      setTime((prevValue) => ({ ...prevValue, [id]: timeColumns[id][currentIndex - 1] }));
    }
  };

  return (
    <S.PopperBox ref={el} onClick={(e) => e.stopPropagation()}>
      {Array.from(Object.keys(timeColumns)).map((key: TimeKey) => (
        <S.Column key={key}>
          <S.SelectBtn onClick={() => handlePrevClick(timeColumns[key].indexOf(time[key] as never), key)}>
            <ArrowUpIcon />
          </S.SelectBtn>
          <S.PopperValues>
            {getPartValues(timeColumns[key], time[key]).map((value) => (
              <p key={value} onClick={() => setTime((prevValue) => ({ ...prevValue, [key]: value }))}>
                <span>{value !== "" && `${value}`.length < 2 ? `0${value}` : value}</span>
              </p>
            ))}
          </S.PopperValues>
          <S.SelectBtn onClick={() => handleNextClick(timeColumns[key].indexOf(time[key] as never), key)}>
            <ArrowDownIcon />
          </S.SelectBtn>
        </S.Column>
      ))}
    </S.PopperBox>
  );
};

const TimeSelector: FC<Props> = ({ dateRange, onRangeChange }) => {
  const [isStartTimeOpen, setIsStartTimeOpen] = useState(false);
  const [isEndTimeOpen, setIsEndTimeOpen] = useState(false);

  const handleChangeMin = (time) => {
    const start = getDateWithTime(dateRange.start, time);
    const value = new Date(Math.min(+start, +dateRange.end));
    onRangeChange([value, dateRange.end]);
  };

  const handleChangeMax = (time) => {
    const end = getDateWithTime(dateRange.end, time);
    const value = new Date(Math.min(Math.max(+dateRange.start, +end), Date.now()));
    onRangeChange([dateRange.start, value]);
  };

  return (
    <S.Container>
      <S.Box>
        <label>Time from</label>
        <S.Input
          onClick={() => {
            setIsStartTimeOpen((prevValue) => !prevValue);
          }}
        >
          <span>{dayjs(dateRange.start).format(TIME_FORMAT)}</span> <ClockIcon />
          {isStartTimeOpen && (
            <TimePopper date={dateRange.start} onChange={handleChangeMin} onClose={() => setIsStartTimeOpen(false)} />
          )}
        </S.Input>
      </S.Box>
      <S.Box>
        <label>Time to</label>
        <S.Input
          onClick={() => {
            setIsEndTimeOpen((prevValue) => !prevValue);
          }}
        >
          <span>{dayjs(dateRange.end).format(TIME_FORMAT)}</span>
          <ClockIcon />
          {isEndTimeOpen && (
            <TimePopper date={dateRange.end} onChange={handleChangeMax} onClose={() => setIsEndTimeOpen(false)} />
          )}
        </S.Input>
      </S.Box>
    </S.Container>
  );
};

export default TimeSelector;
