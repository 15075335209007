import React, { FC, useState, useEffect } from "react";
import { useCallback } from "react";
import { Button } from "semantic-ui-react";
import { Card, Table, TextArea, Icon, Form, Label } from "semantic-ui-react";
import CopyButtonAction from "../uikit/CopyButtonAction";
import "./index.css";

interface Props {
  function_name: string;
  function_value: Record<string, unknown>;
  updateFunctionDefinition: (function_name: string, function_value: Record<string, unknown>) => void;
}

const getParamRow = (key, value, setParamDescription) => {
  return (
    <Table.Row>
      <Table.Cell>{key}</Table.Cell>
      <Table.Cell>{value.type}</Table.Cell>
      <Table.Cell>
        <TextArea
          className="dasha-textarea"
          rows={value.description.split("\n").length + 1}
          value={value.description}
          onChange={(e, data) => setParamDescription(data.value)}
        />
      </Table.Cell>
    </Table.Row>
  );
};
export const FunctionEditorCard: FC<Props> = ({ function_name, function_value, updateFunctionDefinition }) => {
  const [expanded, setExpanded] = useState(false);
  const [description, setDescription] = useState("");
  const [returns, setReturns] = useState("");
  const [parameters, setParameters] = useState(function_value.Parameters);
  const returnsConst = " returns: ";
  useEffect(() => {
    const inititalDesc = function_value.Description;
    const index = inititalDesc.lastIndexOf(returnsConst);
    setDescription(inititalDesc.substring(0, index));
    setReturns(inititalDesc.substring(index + returnsConst.length));
  }, [function_value]);

  const onUpdateRequest = useCallback(() => {
    var newValue = {
      ...function_value,
      ...{ Description: description + returnsConst + returns },
      ...{ Parameters: parameters },
    };
    updateFunctionDefinition(function_name, newValue);
  }, [parameters, description, returns]);

  const setParameterDescription = useCallback(
    (paramName, paramDescription) => {
      const newValue = { ...parameters };
      newValue["properties"][paramName]["description"] = paramDescription;
      setParameters(newValue);
    },
    [function_value]
  );

  const getJavaDoc = useCallback(() => {
    var docs = "/**\n" + description.replaceAll("\n", "\n*").trim() + "\n";
    for (var [k, v] of Object.entries(parameters.properties)) {
      docs += "* @param " + k + " " + v.description.trim() + "\n";
    }
    docs += "* @return " + returns.replaceAll("\n", " ").trim();
    docs += "\n*/";
    return docs;
  }, [description, returns, parameters]);
  return (
    <Card onBlur={onUpdateRequest}>
      <Card.Content>
        <Card.Header>{function_name}</Card.Header>
        <Card.Description>
          <Button basic circular icon size="tiny" onClick={() => setExpanded(!expanded)}>
            <Icon name="plus circle" />
          </Button>
          {!expanded && <>{description}</>}
        </Card.Description>
        {expanded && (
          <Card.Content extra>
            <Form>
              <Label>Description</Label>
              <TextArea
                label="Description"
                rows={description.split("\n").length + 1}
                placeholder="Description"
                value={description}
                className="dasha-textarea"
                onChange={(event, data) => {
                  setDescription(data.value as string);
                }}
              />
              <Label>Returns</Label>
              <TextArea
                label="Returns"
                rows={returns.split("\n").length + 1}
                placeholder="Returns"
                value={returns}
                className="dasha-textarea"
                onChange={(event, data) => {
                  setReturns(data.value as string);
                }}
              />
              <Table>
                <Table.Header>
                  <Table.Cell>Parameter</Table.Cell>
                  <Table.Cell>Type</Table.Cell>
                  <Table.Cell>Description</Table.Cell>
                </Table.Header>
                <Table.Body>
                  {Object.entries(parameters.properties).map(([k, v]) =>
                    getParamRow(k, v, (pd) => setParameterDescription(k, pd))
                  )}
                </Table.Body>
              </Table>
            </Form>
            <CopyButtonAction tooltipText="Copy JavaDoc" clipboard={getJavaDoc} hasIcon={false}>
              Copy JavaDoc
            </CopyButtonAction>
          </Card.Content>
        )}
      </Card.Content>
    </Card>
  );
};
