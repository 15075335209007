import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 0 24px;
  background: #161729;
  box-sizing: border-box;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

export const Fullwrap = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 17px;
  line-height: 28px;
  color: #eceff8;
  margin: 0;
  text-align: center;

  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin-left: 4px;
  }
`;

export const Logo = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  z-index: 1;

  span {
    margin-left: 8px;
    font-family: var(--main-font);
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: #d9d9e2;
  }
`;

export const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  > * + * {
    margin-left: 12px;
  }
`;
