import React, { FC, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";
import Popup from "reactjs-popup";

import "reactjs-popup/dist/index.css";
import { PureButton } from "./Button";

const shadowSize = "4px";
const shadowColor = "rgba(0, 0, 0, 0.8)";

export const animation = keyframes`
   0% {
    transform: scale(1) translateY(0px);
    opacity: 0;
  }
  1% {
    transform: scale(0.96) translateY(10px);
    opacity: 0;
  }
  100% {
    transform: scale(1) translateY(0px);
    opacity: 1;
  }
`;

const StyledTooltip = styled(Popup)`
  &-content {
    animation: ${animation} 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;

    width: fit-content !important;
    background: #1d1e31;
    border-radius: 4px;
    padding: 8px;

    font-family: var(--main-font);
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: rgba(255, 255, 255, 0.75);
    box-shadow: 0 1px ${shadowSize} ${shadowColor} !important;
    z-index: 10000000 !important;
    border: none;

    pointer-events: none;
    transition: 0.3s opacity;

    span {
      display: flex;
      align-items: center;
    }
  }

  &-arrow {
    color: #1d1e31;
    stroke: transparent;
  }
`;

export const MenuPopup = styled(Popup)`
  &-content {
    width: fit-content !important;
    animation: ${animation} 0.2s cubic-bezier(0.38, 0.1, 0.36, 0.9) forwards;
  }

  &-arrow {
    color: #1d1e31;
    stroke: transparent;
    z-index: 10 !important;
  }
`;

export const Menu = styled.div`
  margin: 0;
  padding: 0;
  background: #1d1e31;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  padding: 12px;
`;

export const Option = styled(PureButton)<{ isSelected?: boolean }>`
  color: ${(p) => (p.isSelected ? "#7E7BF7" : "#fff")};

  display: flex;
  align-items: center;
  font-style: normal;
  font-size: 13px;
  line-height: 24px;
  transition: 0.2s opacity;
  opacity: 0.75;

  font-family: var(--main-font);
  font-weight: normal;
  text-transform: none;
  outline: none !important;

  & + & {
    margin-top: 8px;
  }

  svg {
    margin-left: 4px;
  }

  &:hover {
    opacity: 1;
  }

  &:disabled {
    color: rgba(255, 255, 255, 0.3);
    opacity: 1;
  }
`;

export interface MenuOption {
  action?: () => void;
  icon?: React.ReactElement;
  isSelected?: boolean;
  isEnable: boolean;
  label: string;
}

interface MenuProps extends PopupProps {
  actions: MenuOption[];
  closeAfterSelect?: boolean;
  children: React.ReactElement;
}

export const PopupMenu: FC<MenuProps> = ({ actions, closeAfterSelect, children, ...props }) => {
  const ref = useRef<PopupActions>(null);
  const handleClick = (option: MenuOption) => () => {
    if (closeAfterSelect) ref.current?.close();
    option.action?.();
  };

  return (
    <MenuPopup
      on="hover"
      closeOnDocumentClick
      mouseLeaveDelay={300}
      mouseEnterDelay={0}
      contentStyle={{ padding: "0px", border: "none" }}
      trigger={children}
      arrow={false}
      {...props}
      ref={ref}
    >
      <Menu>
        {actions
          .filter((action) => action.isEnable)
          .map((action, i) => (
            <Option
              key={i}
              isSelected={action.isSelected}
              disabled={action.action == null}
              onClick={handleClick(action)}
            >
              {action.icon} {action.label}
            </Option>
          ))}
      </Menu>
    </MenuPopup>
  );
};

interface Props extends PopupProps {
  clickContent?: React.ReactElement;
  children: React.ReactElement;
  text: React.ReactNode;
}

export const Tooltip: FC<Props> = ({ text, clickContent, children, ...props }) => {
  const ref = useRef<PopupActions>(null);
  const [isClick, setClick] = useState(false);

  const handleClick = () => {
    if (!clickContent) return;
    ref.current?.close();

    setTimeout(() => {
      setClick(true);
      ref.current?.open();
    }, 0);

    setTimeout(() => {
      ref.current?.close();
      setClick(false);
    }, 1000);
  };

  const wrappedChildren = () =>
    React.cloneElement(children, {
      ...children.props,
      onClick: (e) => {
        handleClick();
        children.props.onClick?.(e);
      },
    });

  if (text == null) {
    return children;
  }

  return (
    <StyledTooltip ref={ref} on={isClick ? "click" : "hover"} closeOnDocumentClick trigger={wrappedChildren} {...props}>
      <span>{isClick ? clickContent : text}</span>
    </StyledTooltip>
  );
};
