import React, { useState } from "react";
import { observer } from "mobx-react-lite";

import Playground from "./core/Playground";
import ExplorerView from "./ExplorerView";
import WorkspaceView from "./WorkspaceView";

import { Icon, ScrollbarStyle } from "./uikit";
import HelpWidget from "./HelpWidget";
import * as S from "./styled";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { ActionButton } from "./uikit";
import styled from "styled-components";

const Title = styled.p`
  font-family: var(--main-font);
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
  margin: 0;
  margin-top: 24px;
`;


const App = () => {
  const [ide] = useState<Playground>(() => new Playground());

  if (!ide.explorer.isInitalized) {
    return (
      <S.LoadingApp>
        <Icon name="spinner" style={{ width: 32 }} />
        <p>Loading Dasha Studio</p>
      </S.LoadingApp>
    );
  }

  if (!ide.accounts.isAuth) {
    return (
      <S.App>
        <Title>Please sign in to start exploring Dasha Playground</Title>
        <ActionButton
          onClick={() => ide.accounts.login()}
          style={{ height: "15%", width: "20%", top: "30%", left: "40%", position: "absolute" }}
        >
          SIGN IN DASHA.AI
        </ActionButton>
        <ActionButton
          onClick={() => ide.accounts.login()}
          style={{ height: "15%", width: "20%", top: "50%", left: "40%", position: "absolute" }}
        >
          SIGN UP DASHA.AI
        </ActionButton>
      </S.App>
    );
  }

  return (
    <BrowserRouter>
      <S.App>
        <ScrollbarStyle />
        <HelpWidget />
        <Routes>
          <Route element={ide.explorer.opened ? <WorkspaceView ide={ide} /> : <ExplorerView ide={ide} />} path="*" />
        </Routes>
      </S.App>
    </BrowserRouter>
  );
};

export default observer(App);
