import styled from "styled-components";

export const Layout = styled.div<{ powerbi: boolean }>`
  width: 100%;
  height: 100vh;
  margin: auto;

  display: grid;
  height: 100vh;
  grid-template-columns: 200px 1fr;
  grid-template-rows: 64px 130px 1fr;
  grid-template-areas: ${(p) =>
    p.powerbi
      ? `"header header" "aside projects" "aside projects"`
      : `"header header" "aside actions" "aside projects"`};

  #header {
    grid-area: header;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  #aside {
    grid-area: aside;
    border-right: 1px solid rgba(255, 255, 255, 0.12);
  }

  #actions {
    grid-area: actions;
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
  }

  #projects {
    grid-area: projects;
  }

  .powerbi-reports {
    grid-area: projects;
    iframe {
      border: 0;
    }
  }
`;

export const Title = styled.h1`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  color: #eceff8;
  margin: 0;
`;

export const Filters = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 0 24px;

  p {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
    color: rgba(217, 217, 226, 0.5);
    margin: 0;
  }

  span {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    margin-left: 4px;
    color: #fff;
  }

  svg {
    margin-left: 4px;
  }
`;

export const Content = styled.div`
  box-sizing: border-box;
  padding: 24px 5%;
  margin: auto;
  overflow-y: auto;
  height: 100%;
  width: 100%;
`;

export const Projects = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 24px));
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  padding-bottom: 80px;

  @media (max-width: 1200px) {
    grid-template-columns: repeat(3, calc(33% - 16px));
  }

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 16px));
  }

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

export const Aside = styled.div`
  background: #1b1b2d;
  overflow-y: scroll;
`;

export const Header = styled.h2`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.5);
  height: 32px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  margin: 0;
  margin-top: 12px;
`;

export const Option = styled.div<{ isSelected: boolean }>`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  display: flex;
  align-items: center;
  padding: 0 14px;
  cursor: pointer;
  height: 32px;

  transition: 0.2s background, 0.2s color;
  color: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  background: ${(p) => (p.isSelected ? "#35317D" : "#1B1B2D")};

  svg {
    margin-right: 6px;
  }

  path {
    fill: ${(p) => (p.isSelected ? "#fff" : "#A1A1BD")};
  }

  &:hover {
    background: ${(p) => (p.isSelected ? "#35317D" : "#232435")};
  }
`;
