import { OutputData } from "@core/workspace/session-storage/types";
import React, { FC, useState } from "react";
import { Icon } from "../../uikit";
import { Tooltip } from "../../uikit/popup";

import * as S from "./styled";

interface Props {
  data: OutputData;
}

const OutputInfo: FC<Props> = ({ data }) => {
  const [isOpen, setIsOpen] = useState(true);

  const handleToggle = () => {
    setIsOpen((prevValue) => !prevValue);
  };

  return (
    <S.Container>
      <Tooltip text={isOpen ? "Hide info" : "Show info"} position="top center">
        <S.ToggleButton onClick={handleToggle}>
          <span>Info log</span>
          <Icon name="arrow" angle={isOpen ? 180 : 0} />
        </S.ToggleButton>
      </Tooltip>
      {isOpen && (
        <S.Info>
          {Array.from(Object.keys(data)).map((key) => (
            <li key={key}>
              <S.Key>"{key}":</S.Key>
              <S.Value>{data[key] !== null ? `${JSON.stringify(data[key], undefined, 1)}` : "null"}</S.Value>
            </li>
          ))}
        </S.Info>
      )}
    </S.Container>
  );
};

export default OutputInfo;
