import { observer } from "mobx-react-lite";
import React, { FC, useRef, useState } from "react";
import { PopupActions, PopupProps } from "reactjs-popup/dist/types";

import { Menu, MenuOption, MenuPopup, Option, Tooltip } from "../../../../../uikit/popup";
import { Icon } from "../../../../../uikit/Icon";
import Checkbox from "../Checkbox";

import CloseIcon from "./assets/close.svg";
import SearchIcon from "./assets/search.svg";
import * as S from "./styled";

interface Props extends PopupProps {
  name: string;
  searchPlaceholder: string;
  actions: MenuOption[];
  selected: Set<string>;
  hasClearBtn?: boolean;
  hasTooltip?: boolean;
  renderTrigger?: React.ReactElement;
  onReset: () => void;
}

const FilterMenu: FC<Props> = ({
  name,
  renderTrigger,
  hasTooltip = true,
  hasClearBtn,
  searchPlaceholder,
  selected,
  actions,
  onReset,
  position,
}) => {
  const [search, setSearch] = useState("");
  const ref = useRef<PopupActions>(null);
  const handleClick = (option: MenuOption) => () => {
    option.isEnable && option.action?.();
  };

  let trigger =
    selected.size === 0 ? (
      <S.TriggerBox>
        <span>{name}</span> <Icon name="chevron-down" />{" "}
      </S.TriggerBox>
    ) : (
      <S.TriggerBox selected>
        <>
          <span>
            {Array.from(selected)[selected.size - 1]}
            {selected.size > 1 && <>, +{selected.size - 1}</>}
          </span>
          <S.CloseBtn
            onClick={(e) => {
              e.stopPropagation();
              onReset();
            }}
          >
            <CloseIcon />
          </S.CloseBtn>
        </>
      </S.TriggerBox>
    );

  if (renderTrigger) {
    trigger = renderTrigger;
  }

  const content = (
    <S.Container>
      <MenuPopup
        on="click"
        closeOnDocumentClick
        mouseLeaveDelay={300}
        mouseEnterDelay={0}
        contentStyle={{ padding: "0px", border: "none" }}
        trigger={trigger}
        arrow={false}
        position="bottom right"
        ref={ref}
      >
        <Menu style={{ padding: "0", minWidth: "200px", maxWidth: "200px" }}>
          <S.Header>
            <S.Name>{name}</S.Name>
            <S.CloseBtn onClick={() => ref.current?.close()}>
              <CloseIcon />
            </S.CloseBtn>
            <S.SearchField>
              {!search && <SearchIcon />}
              <input
                type="text"
                placeholder={searchPlaceholder}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {search && (
                <S.CloseBtn onClick={() => setSearch("")}>
                  <CloseIcon />
                </S.CloseBtn>
              )}
            </S.SearchField>
            {hasClearBtn && (
              <S.ClearBtn onClick={() => selected.clear()}>
                <CloseIcon />
                <span>Clear filters</span>
              </S.ClearBtn>
            )}
          </S.Header>
          <S.Actions>
            {actions
              .filter((action) => action.label.toLowerCase().includes(search.toLowerCase().trim()))
              .map((action, i) => (
                <Option
                  key={i}
                  isSelected={action.isSelected}
                  disabled={!action.isEnable}
                  onClick={handleClick(action)}
                >
                  <Checkbox
                    checked={action.isEnable ? selected.has(action.label) : false}
                    onChange={handleClick(action)}
                  />{" "}
                  <span>{action.label}</span>
                </Option>
              ))}
          </S.Actions>
        </Menu>
      </MenuPopup>
    </S.Container>
  );

  return hasTooltip ? (
    <Tooltip
      position={position}
      text={
        <S.TooltipText>
          <span>{name}</span>
          <span>{Array.from(selected).join(", ")}</span>
        </S.TooltipText>
      }
    >
      {content}
    </Tooltip>
  ) : (
    content
  );
};

FilterMenu.defaultProps = {
  position: "top center",
};

export default observer(FilterMenu);
