import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table } from "semantic-ui-react";
import "./styles.css";
import { useParams } from "react-router-dom";



export const CustomerInfo: FC<{ account: IAccount, customerId: string|undefined }> = ({ account, customerId }) => {
  const [customerInfo, setCustomerInfo] = useState<dasha.customer.CustomerInfo | undefined>(undefined);
  useEffect(() => {
    const fetchData = async () => {
      const ci = await dasha.customer.getCustomerInfo({account: await account.connectAs(customerId)});
      setCustomerInfo(ci);
    };
    fetchData();
  }, []);

  return (
    <>
      {customerInfo && (
        <>
          <h2>Customer info</h2>
          <Table celled>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>
                        Name
                    </Table.Cell>
                    <Table.Cell>
                        {customerInfo.name}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Id
                    </Table.Cell>
                    <Table.Cell>
                        {customerInfo.id}
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>
                        Max Concurrency
                    </Table.Cell>
                    <Table.Cell>
                        {customerInfo.maxConcurrency}
                    </Table.Cell>
                </Table.Row>
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
};

