import React, { FC, useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";

import { Message } from "@core/workspace/session-storage/types";
import DevMessage from "../DevMessage";
import { formatDate } from "../helpers";
import CopyButton from "../../uikit/CopyButton";

import * as S from "../Session/styled";
import { Icon } from "semantic-ui-react";
import { ActionButton } from "../../uikit";

interface Props {
  msg: Message;
  isCurrentInAudio?: boolean;
  allowContext?: boolean;
  allowTransition?: boolean;
  gptEmulateCallback?: (instanceId: string) => void;
}

const DashaMessage: FC<Props> = ({ msg, isCurrentInAudio, gptEmulateCallback, ...props }) => {
  const el = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!isCurrentInAudio) return;

    el.current?.scrollIntoView({ block: "start", behavior: "smooth" });
  }, [isCurrentInAudio]);
  if (msg.message === "" && (msg.thinking === "" || msg.thinking === undefined)) {
    return <></>;
  }
  return (
    <S.Message ref={el}>
      {msg.thinking && (
        <>
          <S.System>
            <S.ThinkingMessage>{msg.thinking}</S.ThinkingMessage>
          </S.System>
        </>
      )}
      {msg.message !== "" && (
        <>
          <S.MessageBubble isCurrentInAudio={!!isCurrentInAudio}>{msg.message}</S.MessageBubble>
          <DevMessage message={msg} {...props} />
          <S.MessageTime>{formatDate(msg.time)}</S.MessageTime>
        </>
      )}
      {msg.gptInstanceId && gptEmulateCallback && (
        <ActionButton onClick={() => gptEmulateCallback(msg.gptInstanceId ?? "")}>Debug</ActionButton>
      )}      
    </S.Message>
  );
};

export default observer(DashaMessage);
