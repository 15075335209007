import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Modal, Input, Dropdown, Message, Icon, Confirm } from "semantic-ui-react";
import "./styles.css";
import { InboundConfig } from "@dasha.ai/sdk/web/sip/inbound-configs";
import { ApplicationListDropdown } from "./ApplicationListDropdown";
import { GroupListDropdown } from "./GroupListDropdown";

function GetTrigger(existingName: string | undefined) {
  if (existingName === undefined) {
    return <Button>Add configuration</Button>;
  }
  return <Icon name="edit" />;
}
export const InboundVoIPEditor: FC<{
  account: IAccount;
  customerId: string | undefined;
  existingName: string | undefined;
  onUpdate: (name: string, cfg: InboundConfig | undefined) => void;
}> = ({ account, customerId, existingName, onUpdate }) => {
  const priorities = Array.from([0, 1, 2, 3, 4, 5, 6, 7]).map((x) => ({ text: x, value: x, key: x }));
  const [open, setOpen] = React.useState(false);
  const [cfgName, setCfgName] = React.useState(existingName);
  const [alias, setAlias] = React.useState<string | undefined>(undefined);
  const [ipAcls, setIpAcls] = React.useState<string | undefined>(undefined);
  const [password, setPassword] = React.useState<string | undefined | null>(undefined);
  const [applicationName, setApplicationName] = React.useState<string | undefined>(undefined);
  const [groupName, setGroupName] = React.useState<string | undefined>("Default");
  const [priority, setPriority] = React.useState(0);
  const [error, setError] = React.useState(undefined);
  const [loading, setLoading] = React.useState(false);
  const [originalConfig, setOriginalConfig] = React.useState<InboundConfig | undefined>();
  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const loadExisting = React.useEffect(() => {
    if (existingName === undefined) {
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = await account.connectAs(customerId);
        const result = await dasha.sip.inboundConfigs.getConfigByName(existingName, { account: a });
        setAlias(result.alias);
        setIpAcls(result.ipAcls?.join(","));
        setApplicationName(result.applicationName);
        setPriority(result.priority);
        setGroupName(result.groupName);
        setLoading(false);
        setOriginalConfig(result);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData().catch((e) => console.error(e));
  }, [account, existingName]);

  const addCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = await account.connectAs(customerId);
        const result = await dasha.sip.inboundConfigs.createConfig(
          cfgName!!,
          {
            applicationName: applicationName!!,
            groupName: groupName,
            priority: priority,
            ipAcls: ipAcls?.split(","),
            password: password ?? undefined,
            alias: alias,
          },
          {
            account: a,
          }
        );
        onUpdate(cfgName, result);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [account, setError, setLoading, applicationName, groupName, priority, ipAcls, password, alias, cfgName, onUpdate]);

  const deleteCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = await account.connectAs(customerId);
        const result = await dasha.sip.inboundConfigs.deleteConfig(cfgName!!, {
          account: a,
        });
        onUpdate(cfgName, undefined);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e.Message);
      });
  }, [account, setError, setLoading, existingName]);

  const updateCb = React.useCallback(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const a = await account.connectAs(customerId);
        const result = await dasha.sip.inboundConfigs.updateConfig(
          cfgName!!,
          {
            applicationName: applicationName!!,
            groupName: groupName,
            priority: priority,
            ipAcls: ipAcls?.split(","),
            password: password,
            alias: alias === originalConfig?.alias ? undefined : alias,
          },
          {
            account: a,
          }
        );
        onUpdate(cfgName, result);
        setOpen(false);
      } catch (e) {
        setError(e.message);
      }
    };
    fetchData()
      .then(() => {
        setLoading(false);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [
    account,
    setError,
    setLoading,
    applicationName,
    groupName,
    priority,
    ipAcls,
    password,
    alias,
    cfgName,
    originalConfig,
    onUpdate,
  ]);

  return (
    <Modal onClose={() => setOpen(false)} onOpen={() => setOpen(true)} open={open} trigger={GetTrigger(existingName)}>
      <Modal.Header>Inbound VoIP Configuration</Modal.Header>
      {error && <Message negative>{error}</Message>}
      <Modal.Content>
        <h3>Name</h3>
        <Input
          disabled={existingName !== undefined}
          placeholder="Configuration name"
          value={cfgName}
          onChange={(e, { value }) => setCfgName(value)}
        />
        <h3>Application</h3>
        <ApplicationListDropdown
          account={account}
          application={applicationName}
          selectApplication={setApplicationName}
        ></ApplicationListDropdown>
        <h3>Group</h3>
        <GroupListDropdown account={account} group={groupName} selectGroup={setGroupName}></GroupListDropdown>
        <h3>Priority</h3>
        <Dropdown
          placeholder="Select Priority"
          fluid
          search
          selection
          options={priorities}
          value={priority}
          onChange={(e, { value }) => setPriority(value)}
        />
        <h3>Global unique alias</h3>
        sip:
        <Input placeholder="Global unique alias" value={alias} onChange={(e, { value }) => setAlias(value)} />@
        {account.server.replace("app", "sip")}
        <h3>IP Acls (Optional)</h3>
        <Input
          placeholder="168.86.128.0/18"
          value={ipAcls}
          onChange={(e, { value }) => setIpAcls(value)}
          style={{ width: "100%" }}
        />
        <h3>Password (Optional)</h3>
        <Input
          placeholder="Password"
          value={password}
          onChange={(e, { value }) => {
            setPassword(value);
          }}
          type="password"
        />
        <Button
          content="Clear password"
          labelPosition="right"
          onClick={() => setPassword(null)}
          disabled={password === null}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setOpen(false)}>
          Cancel
        </Button>
        {existingName === undefined && (
          <Button
            content="Add"
            labelPosition="right"
            icon="checkmark"
            onClick={() => addCb()}
            positive
            disabled={applicationName === undefined}
          />
        )}
        {existingName !== undefined && (
          <>
            <Button content="Update" labelPosition="right" icon="checkmark" onClick={() => updateCb()} positive />
            <Button negative onClick={() => setConfirmDelete(true)}>
              Delete
            </Button>
            <Confirm
              content={`Are you sure Delete ${existingName}`}
              open={confirmDelete}
              onCancel={() => setConfirmDelete(false)}
              onConfirm={() => deleteCb()}
            />
          </>
        )}
      </Modal.Actions>
    </Modal>
  );
};
