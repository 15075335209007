import oidc from "oidc-client";
import { action, computed, makeObservable, observable } from "mobx";

import storage from "../misc/LocalStorage";
import analytics from "../misc/analytics";
import { Emitter } from "../misc/emitter";

import { IAccount } from "./interface";
import AnonymousAccount from "./Anonymous";
import Account from "./Account";

class AccountsManager {
  server: string;
  authServer: string;
  account: IAccount;

  private readonly _onDidAccount = new Emitter<IAccount>();
  public readonly onDidAccount = this._onDidAccount.event;

  constructor(server: string, authServer: string) {
    this.server = server;
    this.authServer = authServer;
    this.account = new AnonymousAccount(this.server);

    makeObservable(this, {
      account: observable,
      isAuth: computed,
      loadCurrentAccount: action,
      authAccount: action,
      logout: action,
    });

    new oidc.UserManager({})
      .signinRedirectCallback(window.location.href)
      .then((user) => {
        window.history.pushState(null, "", window.location.pathname);
        const name = user.profile.email ?? "user";
        const token = user.access_token;
        this.authAccount(name, token);
      })
      .catch(() => this.loadCurrentAccount());
  }

  authAccount(name: string, token: string) {
    storage.set("currentAccount", name);
    analytics.identity(name);

    this.account = new Account(name, token, this.server);
    this._onDidAccount.fire(this.account);
  }

  public loadCurrentAccount() {
    const name = storage.get<string>("currentAccount");
    if (name == null) return this.logout();

    const account = Account.fromName(name, this.server);
    if (account == null) return this.logout();

    analytics.identity(name);
    this.account = account;
    this._onDidAccount.fire(account);
  }

  get isAuth() {
    const isAnon = this.account instanceof AnonymousAccount;
    return isAnon === false;
  }

  logout() {
    storage.set("currentAccount", null);
    analytics.track("logout");
    analytics.logout();

    this.account = new AnonymousAccount(this.server);
    this._onDidAccount.fire(this.account);
  }

  login(prompt: string = "login") {
    const manager = new oidc.UserManager({
      authority: `https://${this.authServer}`,
      client_id: "platform_client",
      redirect_uri: window.location.href,
      popup_redirect_uri: window.location.href,
      response_type: "id_token token",
      scope: "openid email platform_api user_properties",
      prompt: prompt,
    });

    analytics.track(prompt);
    void manager.signinRedirect();
  }
}

export default AccountsManager;
