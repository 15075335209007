import LocalLibrary from "./projects/LocalLibrary";
import Account from "./Account";

class AnonymousAccount extends Account {
  library = new LocalLibrary(this);

  constructor(server: string) {
    super("anonymous", "", server);
  }

  async connect() {
    const { apiKey, server } = await super.connect();
    const trialGetted = +(this.getValue<number>("_trialGettedMs") ?? 0);
    const trialExpiresIn = +(this.getValue<number>("_trialExpiresInMs") ?? 0);

    if (!apiKey || Date.now() - trialExpiresIn > trialGetted) {
      await this.refreshFreeToken();
      return await super.connect();
    }

    return { apiKey, server };
  }

  async updateFeatures() {
    this.features.clear();
  }

  private async refreshFreeToken(): Promise<void> {
    const res = await fetch(`https://${this.server}/api/v1/marketing/token`, { method: "POST" });
    const { accessToken, expiresIn } = await res.json();
  
    this.setToken(accessToken);
    this.setValue("_trialGettedMs", Date.now().toString());
    this.setValue("_trialExpiresInMs", (expiresIn * 1000).toString());
  }
}

export default AnonymousAccount;
