import React, { FC, useState } from "react";
import { observer } from "mobx-react-lite";

import { Icon } from "../../uikit";
import { uploadProfile } from "../../ProfilerPanel/uploadProfile";
import CreateWidget from "../../widgets/CreateWidget";
import UploaderWidget from "../../widgets/UploaderWidget";
import Explorer from "../../core/explorer/Explorer";

import ProfilerIcon from "../icons/profiler.svg";
import PlusIcon from "../icons/plus.svg";
import * as S from "./styled";

const Actions: FC<{ explorer: Explorer }> = ({ explorer }) => {
  type Action = "create" | "uploader" | null;
  const [action, setAction] = useState<Action>(null);
  const closeAction = () => setAction(null);

  const handleLoadProfile = async () => {
    const { content } = await uploadProfile();
    await explorer.createFromProfile(content);
  };

  return (
    <S.Buttons id="actions">
      <UploaderWidget isOpen={action == "uploader"} explorer={explorer} onClose={closeAction} />
      <CreateWidget isOpen={action == "create"} explorer={explorer} onClose={closeAction} />

      <S.CreateProjectButton onClick={() => setAction("create")}>
        <Icon name="app-icon" />
        <div>
          <h2>Create app</h2>
          <p>The app is created using code</p>
        </div>
        <PlusIcon />
      </S.CreateProjectButton>

      <S.CreateProjectButton onClick={() => setAction("uploader")}>
        <Icon name="upload" />
        <div>
          <h2>Upload project</h2>
          <p>Bring in other files</p>
        </div>
        <PlusIcon />
      </S.CreateProjectButton>

      <S.CreateProjectButton onClick={() => handleLoadProfile()}>
        <ProfilerIcon />
        <div>
          <h2>Upload .dashasp</h2>
          <p>Create app using profile</p>
        </div>
        <PlusIcon />
      </S.CreateProjectButton>
    </S.Buttons>
  );
};

export default observer(Actions);
