export const uploadFile = async () =>
  new Promise<{ name: string; format: string; content: string }>((resolve, reject) => {
    const input = document.createElement("input");
    input.type = "file";
    input.style.display = "none";
    document.body.appendChild(input);
    input.click();

    input.addEventListener("change", (e: any) => {
      document.body.removeChild(input);
      if (e.target.files?.length !== 1) reject();
      const file = e.target.files[0];

      if (["csv", "json"].includes(file.name.split(".").pop()) === false)
        reject("not valid " + file.name.split(".").pop());

      const reader = new FileReader();
      reader.onerror = () => reject("error");
      reader.onabort = () => reject("abort");
      reader.onload = () => {
        resolve({
          name: file.name,
          format: file.name.split(".").pop(),
          content: reader.result?.toString() ?? "",
        });
      };

      reader.readAsText(file);
    });
  });

export const csvToArray = (str: string, delimiter = ",") => {
  const headers = str.slice(0, str.indexOf("\n")).split(delimiter);
  const rows = str.slice(str.indexOf("\n") + 1).split("\n");

  const arr = rows.map((row) => {
    const values = row.split(delimiter);
    const el = headers.reduce((object, header, index) => {
      object[header.replace('\r', '')] = values[index]?.replace('\r', '');
      return object;
    }, {});
  
    return el;
  });

  return arr;
};
