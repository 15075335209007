import React, { FC, useCallback, useState } from "react";
import { Checkbox, Input, Table } from "semantic-ui-react";
import { ActionButton } from "../uikit";
import CopyButtonAction from "../uikit/CopyButtonAction";

interface Props {
  options: Record<string, string>;
  initialOptions: Record<string, string>;
  onUpdateOption: (option_name: string, option_value: string | undefined) => void;
}

const optionNames = {
  model: { type: "string", hint: "openai/gpt-35-turbo" },
  function_call: { type: "string", hint: "auto" },
  openai_apikey: { type: "string", hint: "your apikey" },
  openai_endpoint: { type: "string", hint: "for Azure OpenAI" },
  top_p: { type: "number", hint: "0.5" },
  temperature: { type: "number", hint: "0.5" },
  presence_penalty: { type: "number", hint: "0.0" },
  frequency_penalty: { type: "number", hint: "0.0" },
  max_tokens: { type: "number", hint: "Restrict max tokens in output" },

  ignore_json_output: { type: "boolean" },
  allow_function_name_in_response: { type: "boolean" },
  stop_sequences: { type: "string", hint: "; delimited strings" },

  prepend: { type: "string", hint: "what to say before response" },
  ignore_current_prepend: { type: "number_boolean" },

  sort_history: { type: "boolean" },
  merge_history: { type: "boolean" },
  history_length: { type: "number", hint: "count phrases of any side" },
  log_history: { type: "boolean" },

  thinking_enabled: { type: "boolean" },
  thinking_choices: { type: "number" },
  thinking_fallback_enabled: { type: "boolean" },
  thinking_empty_enabled: { type: "boolean" },
  thinking_multi_answer_enabled: { type: "boolean" },

  retry_temperature_scale: { type: "number", hint: "2.0" },
  retry_topp_scale: { type: "number", hint: "2.0" },
  max_retries: { type: "number", hint: "1" },
};

export const GptOptionsTable: FC<Props> = ({ options, initialOptions, onUpdateOption }) => {
  const [showAllOptions, setShowAllOptions] = useState(false);

  const getOptionCell = (option_name, option_description) => {
    if (option_description.type == "string") {
      return (
        <Input
          placeholder={option_description.hint}
          onChange={(e, data) => onUpdateOption(option_name, data.value)}
          value={options[option_name] ?? ""}
        ></Input>
      );
    }
    if (option_description.type == "number") {
      return (
        <Input
          placeholder={option_description.hint}
          onChange={(e, data) => onUpdateOption(option_name, data.value)}
          value={options[option_name] ?? ""}
        ></Input>
      );
    }
    if (option_description.type == "boolean") {
      return (
        <Checkbox
          indeterminate={options[option_name] === undefined}
          onChange={(e, data) => onUpdateOption(option_name, data.checked?.toString())}
          checked={options[option_name] !== undefined ? options[option_name] === "true" : undefined}
        ></Checkbox>
      );
    }

    if (option_description.type == "number_boolean") {
      return (
        <Checkbox
          indeterminate={options[option_name] === undefined}
          onChange={(e, data) => onUpdateOption(option_name, data.checked ? "1" : "0")}
          checked={options[option_name] !== undefined ? options[option_name] === "1" : undefined}
        ></Checkbox>
      );
    }
    return <></>;
  };

  const getOptionRow = (option_name, option_description) => {
    if (!showAllOptions && initialOptions[option_name] === undefined && options[option_name] === undefined) {
      return <></>;
    }
    return (
      <Table.Row>
        <Table.Cell>{option_name}</Table.Cell>
        <Table.Cell>{getOptionCell(option_name, option_description)}</Table.Cell>
        <Table.Cell>
          <ActionButton onClick={() => onUpdateOption(option_name, undefined)}>Clear</ActionButton>
        </Table.Cell>
      </Table.Row>
    );
  };
  const getOptionsString = useCallback(() => {
    var result = [];
    for (var [k,v] of Object.entries(options)) {
      result.push("\t" + k + ": \"" + v + "\"");
    }
    
    return "{\n" + result.join(",\n") + "\n}";
  }, [options]);
  return (
    <>
      <Checkbox
        toggle
        onChange={(e, data) => setShowAllOptions(data.checked ?? false)}
        checked={showAllOptions}
        label="Show all"
      />
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Option</Table.HeaderCell>
            <Table.HeaderCell>Value</Table.HeaderCell>
            <Table.HeaderCell>Clear</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{Object.entries(optionNames).map((x) => getOptionRow(x[0], x[1]))}</Table.Body>
      </Table>
      <CopyButtonAction tooltipText="Copy Options" clipboard={getOptionsString} hasIcon={false}>
              Copy Options
      </CopyButtonAction>
    </>
  );
};
