import { debounce } from "lodash";
import { action, autorun, IReactionDisposer, makeObservable, observable, runInAction, toJS } from "mobx";
import * as dasha from "@dasha.ai/sdk/web";

import Project from "@core/explorer/Project";
import { FileStorage } from "@core/workspace/TextStorage";
import DatasetStorage from "@core/workspace/dataset-storage/DatasetStorage";
import { DatasetDocument } from "@core/workspace/dataset-storage/types";
import { SessionProtocol } from "@core/workspace/session-storage/types";
import UIManager from "@core/misc/UIManager";

import ProfilerGraph from "./ProfilerGraph";
import ProfilerTable from "./ProfilerTable";
import ProfilerStorage from "./storage";
import { ProfileDump } from "./types";
import ConversationsTable from "./ConversationsTable";
import { createDownloadLink } from "./utils";

export enum ProfilerPage {
  TABLE,
  GRAPH,
  CONVERSATIONS,
}

class Profiler implements FileStorage {
  public isPinned = true;
  public isReadonly = true;
  public isFullscreen = true;
  public name = "Profiler";
  public path = ".profile";

  public loadingProgress: dasha.ProgressEvent | null = null;
  public session: SessionProtocol | null = null;
  public savedDatasets: DatasetDocument[] = [];

  public profile: null | Uint8Array = null;
  public selectedPage = ProfilerPage.TABLE;
  public document?: ProfileDump;
  public table?: ProfilerTable;
  public conversationsTable: ConversationsTable;
  public graph?: ProfilerGraph;

  public cancelProfileLoadCb?: (() => void) | null = null;

  private autosaverDisposer?: IReactionDisposer;
  private storage?: ProfilerStorage;

  constructor(readonly project: Project, private readonly datasetStorage?: DatasetStorage) {
    makeObservable(this, {
      selectedPage: observable,
      loadingProgress: observable,
      savedDatasets: observable,
      document: observable,
      table: observable,
      conversationsTable: observable,
      session: observable,
      graph: observable,
      profile: observable,
      openSession: action,
      selectPage: action,
      exportProfile: action,
    });
  }

  private enableAutosaver() {
    this.autosaverDisposer?.();
    this.autosaverDisposer = autorun(async () => {
      const document = this.serialize();
      await this.saveProfileChangesDebounce(document);
    });
  }

  async dispose() {
    this.autosaverDisposer?.();
    await this.storage?.dispose();

    runInAction(() => {
      this.loadingProgress = null;
      this.savedDatasets = [];
      this.document = undefined;
      this.storage = undefined;
      this.table = undefined;
    });
  }

  public saveToDataset() {
    if (this.table == null) return;
    const prev = this.savedDatasets;
    prev.forEach((set) => this.datasetStorage?.unsetDataset(set));
    const sets = this.table.generateDatasets();
    sets.forEach((set) => this.datasetStorage?.mergeDataset(set));
    this.savedDatasets = sets;

    UIManager.success(`Your changes have been successfully added to the ${this.datasetStorage?.path} file`);
  }

  public selectPage(page: ProfilerPage) {
    this.selectedPage = page;
  }

  public openSession(session: SessionProtocol | null) {
    this.session = session;
  }

  public exportProfile() {
    createDownloadLink([this.profile || ""], "", "profile.dashasp");
  }

  public async initializeTools(storage: ProfilerStorage, document: ProfileDump) {
    const graph = new ProfilerGraph(storage);
    const table = new ProfilerTable({
      storage,
      marked: document.marked,
      project: this.project,
      datasetStorage: this.datasetStorage,
    });
    const conversationsTable = new ConversationsTable(storage, this.project.account);

    await table.prepareProfileData();
    await graph.prepareData();
    await conversationsTable.prepareData();
    await this.dispose();

    graph.onDidSelectTransition((trx) => {
      table.filter?.resetSelectedFilters("selectedNodesTo");
      table.filter?.resetSelectedFilters("selectedNodesFrom");

      if (trx == null) return;
      table.filter?.selectFilterOption("selectedNodesTo", trx.toNode);
      table.filter?.selectFilterOption("selectedNodesFrom", trx.fromNode);
      this.selectPage(ProfilerPage.TABLE);
    });

    table.onDidOpenSession((s) => this.openSession(s));
    conversationsTable.onDidOpenSession((s) => this.openSession(s));
    runInAction(() => {
      this.graph = graph;
      this.table = table;
      this.conversationsTable = conversationsTable;
      this.storage = storage;
      this.document = document;
      this.savedDatasets = document.savedDatasets;
      this.enableAutosaver();
    });
  }

  public async deserializeFromFile(file: string, binary: Uint8Array) {
    try {
      const storage = await ProfilerStorage.create(binary);
      const document = await Profiler.serializeStorage(storage);
      await this.initializeTools(storage, { ...document.dump, file });

      runInAction(() => {
        this.profile = binary;
      });
    } catch (e) {
      console.error(e);
      UIManager.notice(`Failed to upload ${file}`);
    }
  }

  public async deserialize(dump: ProfileDump) {
    try {
      const handler = (e) => runInAction(() => (this.loadingProgress = e));
      const storage = await this.loadProfile(dump, handler);
      await this.initializeTools(storage, dump);
    } catch (e) {
      console.error(e);
      UIManager.notice("Failed to load profile");
    }
  }

  private async loadProfile(dump: ProfileDump, onProgress: dasha.ProgressHandler) {
    const options = {
      applicationName: dump.application ? undefined : dump.name,
      applicationId: dump.application,
      start: new Date(dump.start),
      end: new Date(dump.end),
    };

    const account = await this.project.account.connect();
    const profile = await dasha.profiler.loadProfile(options, {
      account,
      onProgress,
      cancelToken: new dasha.CancelToken((cancel) => {
        this.cancelProfileLoadCb = cancel;
      }),
    });

    const storage = await ProfilerStorage.create(profile);

    runInAction(() => {
      this.profile = profile;
    });

    return storage;
  }

  public async cancelLoadingProfile() {
    this.cancelProfileLoadCb?.();
    this.cancelProfileLoadCb = null;
    await this.dispose();
  }

  serialize(): ProfileDump {
    return {
      file: this.document?.file,
      end: this.document?.end ?? 0,
      start: this.document?.start ?? 0,
      name: this.document?.application ?? "",
      application: this.document?.application,
      marked: toJS(this.table?.editedTriggers() ?? {}),
      savedDatasets: this.savedDatasets,
    };
  }

  // TODO: Validate scheme!
  async tryLocalDeserialize() {
    try {
      const document = await this.project.json<ProfileDump>(".profile");
      if (document.file != null) return;
      await this.deserialize(document);
    } catch (e) {
      return;
    }
  }

  saveProfileChangesDebounce = debounce(async (dump: ProfileDump) => {
    await this.project.updateContent(".profile", dump);
  }, 1000);

  static async serializeStorage(storage: ProfilerStorage) {
    const start = await storage.getMinStartedTime();
    const end = await storage.getMaxCompletedTime();
    const apps = await storage.getApplications();
    const app = apps.sort((a, b) => a.createdTime - b.createdTime)[0];
    const dump: ProfileDump = {
      name: apps[0].name,
      application: apps.length > 1 ? undefined : apps[0].id,
      start: +start - 1,
      end: +end + 1,
      savedDatasets: [],
      marked: {},
    };

    return { package: app.package, dump };
  }
}

export default Profiler;
