import * as dasha from "@dasha.ai/sdk/web";
import LocalStorage from "./filesystem";
import { IProjectsLibrary } from "./projects/interface";

export enum AccountFeature {
  DEPLOYER,
  MANAGER,
}

export interface IAccount {
  name: string;
  library: IProjectsLibrary;
  storage: LocalStorage;
  server: string;
  token: string;

  hasFeature(feature: AccountFeature): boolean;
  setValue(key: string, value: unknown): void;
  getValue<T>(key: string): T | null;
  connect(): Promise<dasha.Account>;
  connectAs(customerId: string|undefined): Promise<dasha.Account>;
}
