import * as React from "react";
import { observer } from "mobx-react-lite";

import Deploy from "@core/deploy-storage/Deploy";
import UIManager from "@core/misc/UIManager";

import Modal from "../uikit/Modal";
import { ModalTitle } from "../uikit/Modal/styled";
import { ActionButton, Icon, StrokeButton } from "../uikit";
import ConversationSetup from "./ConversationSetup";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  deploy?: Deploy | null;
}

const RunConversations: React.FC<Props> = ({ isOpen, onClose, deploy }) => {
  const [isLoading, setLoading] = React.useState(false);
  const [editor] = React.useState(() => new ConversationSetup());
  const ref = React.useRef<HTMLDivElement>(null);

  const handleRun = async () => {
    try {
      setLoading(true);
      await deploy?.runConversations(editor.getConfig());
      UIManager.notice("Сontacts have been added to the call queue");
      onClose();
    } catch {
      UIManager.notice("An error occurred, please try again later");
    }

    setLoading(false);
  };

  React.useEffect(() => {
    editor.setSchema(deploy?.application?.inputSchema ?? {});
  }, [isOpen, deploy]);

  React.useEffect(() => {
    if (!ref.current) return;
    ref.current.appendChild(editor.element);
    return () => editor.dispose()
  }, [editor]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isDisabled={isLoading} style={{ width: 600 }}>
      <ModalTitle>Run conversations</ModalTitle>
      <div ref={ref} style={{ width: "100%", height: "400px" }} />

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={() => editor.uploadFile()}>
          Upload csv or json
        </StrokeButton>
        <ActionButton style={{ flex: 1, marginLeft: 16 }} onClick={handleRun} disabled={editor.isInvalid || isLoading}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          RUN
        </ActionButton>
      </div>
    </Modal>
  );
};

export default observer(RunConversations);
