export interface FactDetails {
  skill: string;
  weight: string;
  likelihood: string;
  location: Location;
}

export interface NluIntentFact extends FactDetails {
  type: "Intent";
  intent: string | null;
  state: string;
  sentenceType: string;
}

export interface NluDataFact extends FactDetails {
  type: "Data";
  data: {
    value: string | null;
    tag: string | null;
  };
  factType: string;
}

export type NluFact = NluDataFact | NluIntentFact;

export interface Location {
  start: string;
  end: string;
}

export interface MsgSwitchState {
  msgId: "NodeSwitchNotificationMessage";
  to: string;
}

export interface MsgStateSwitch {
  msgId: "StateSwitch";
  message?: MsgRecognize;

  callingNodePath: string;
  returningNodePath: string;
  startingNodePath: string;
  continueNodePath: string;
  sourceNodePath: string;
  targetNodePath: string;
  transitionType: string;
}

export interface MsgRecognize {
  msgId: "RecognizedSpeechMessage";
  voiceSegmentId: number;
  time: string;
  results: [
    {
      text: string;
      facts?: NluFact[];
    }
  ];
}

export interface MsgRowText {
  msgId: "RawTextChannelMessage";
  time: string;
  text: string;
  phraseSequenceId: number | undefined;
  Metadata?: {
    SourceName: string;
    SourceData: string;
  }
}

export interface LogMessage {
  msgId: "LogMessage";
  message: string;
  level: number;
}


export interface StoppedPlayingAudioChannelMessage {
  msgId: "StoppedPlayingAudioChannelMessage",
  StartPlayingTime: string;
  PlayedText?: string;
  phraseSequenceId: number | undefined;
  Metadata?: {
    SourceName: string;
    SourceData: string;
  }
}

export interface GptResponseMessage {
  msgId: "GptResponseMessage",
  Metadata: { Thinking: string | undefined; }
}
export interface DevLog {
  time: number;
  incoming: boolean;
  msg: MsgRowText | MsgRecognize | MsgSwitchState | MsgStateSwitch | StoppedPlayingAudioChannelMessage | GptResponseMessage | LogMessage;
}

export enum MarkupType {
  unmark,
  exclude,
  include,
}

export interface TriggerData {
  id: number;
  mark: MarkupType;
  probability: number | null;
  readOnly?: boolean;
  location: {
    start: number;
    end: number;
  };
}

export interface IntentTrigger extends TriggerData {
  type: "intent";
  name: string;
}

export interface EntityTrigger extends TriggerData {
  type: "entity";
  tag: string | null;
  hue: number | null;
  name: string;
  value: string | null;
  entityValue?: string | null;
  hovered?: boolean;
}

export type Trigger = IntentTrigger | EntityTrigger;
