import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import Account from "@core/account/Account";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Input, Button, Message } from "semantic-ui-react";
import "./styles.css";
import { CustomerInfo } from "./CustomerInfo";
import { CustomerQueues } from "./CustomerQueues";
import { InboundVoIP } from "./InboundVoIP";
import { OutboundVoIP } from "./OutboundVoIP";
interface Props {
  account: IAccount;
  setAccount: (account: IAccount) => void;
}

const LoginAsPannel: FC<Props> = ({ account, setAccount }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string|undefined>(undefined);
  const [customerId, setCustomerId] = useState<string|null|undefined>(account.getValue<string>("customerId"));

  const updateCustomerId = useCallback(() => {
    const fetchData = async () => {
      const oldCustomerId = account.getValue<string>("customerId");
      try {
        account.setValue("customerId", customerId);
        const ci = await dasha.customer.getCustomerInfo({account: await account.connect()});

        account.setValue("customerName", ci.name);
        const newAccount = new Account(account.name, account.token, account.server);
        newAccount.setValue("customerId", customerId);
        setAccount(newAccount);        
      } catch (e) {
        setError("Failed to switch to customer");
        account.setValue("customerId", oldCustomerId);
        return;
      }
    };
    fetchData();

  }, [account, customerId]);
  const clearCustomerId = useCallback(() => {
    account.setValue("customerId", null);
    account.setValue("customerName", null);
    const newAccount = new Account(account.name, account.token, account.server);
    setAccount(newAccount);        
  }, [account]);
  return (
    <div className="defaultRoot" id="grid-workspace">
        {error && <Message error>{error}</Message>}
        <Input style={{ width: "100%" }} value={customerId} onChange={(e) => setCustomerId(e.target.value)}/>
        <Button onClick={clearCustomerId}>Logout</Button>
        <Button onClick={updateCustomerId}>Switch</Button>
        
        <CustomerInfo account={account}></CustomerInfo>
    </div>
  );
};

export default observer(LoginAsPannel);
