import * as React from "react";

import Explorer from "../core/explorer/Explorer";
import Project from "../core/explorer/Project";
import { ActionButton, Icon, StrokeButton } from "../uikit";
import { ModalTitle } from "../uikit/Modal/styled";
import Modal from "../uikit/Modal";

import { Text, Tabs, Tab } from "./styled";
import useRename from "./useRename";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  explorer: Explorer;
  project?: Project;
}

const UploaderWidget: React.FC<Props> = ({ isOpen, onClose, explorer, project }) => {
  const [isInvalid, setInvalid] = React.useState(false);
  const [isNoCode, setNoCode] = React.useState(false);
  const { renameInputs, name, description } = useRename(project);
  const [isLoading, setLoading] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement | null>();

  React.useEffect(() => {
    setInvalid(false);
  }, [isOpen]);

  const handleFile: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    setInvalid(false);

    if (e.target.files?.length !== 1) {
      setInvalid(true);
      return;
    }

    const file = e.target.files[0];
    if (file.size > 1024 * 1024 * 5 || file.name.split(".").pop() !== "zip") {
      setInvalid(true);
      return;
    }

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onerror = () => setInvalid(true);
    reader.onload = async () => {
      try {
        if (reader.result instanceof ArrayBuffer) {
          const content = new Uint8Array(reader.result);
          const projectType = isNoCode ? "visual" : "code";
          const projectName = name || file.name.split(".").slice(0, -1).join(".");
          setLoading(true);

          if (project == null) {
            await explorer.createFromZip({
              content,
              name: projectName,
              projectType: projectType,
              description: "",
            });

            setLoading(false);
            onClose();
            return;
          }

          await project.rewrite(content);
          await project.rename(projectName, description, projectType);
          setLoading(false);
          onClose();
        }
      } catch (e) {
        console.log(e);
        setInvalid(true);
        setLoading(false);
        setNoCode(false);
      }
    };
  };

  const handleClick = () => {
    inputRef.current?.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isDisabled={isLoading}>
      <ModalTitle>{project ? "Edit from archive" : "Upload project"}</ModalTitle>

      <Tabs>
        <Tab onClick={() => setNoCode(false)} isSelected={isNoCode === false}>
          Code
        </Tab>
        <Tab onClick={() => setNoCode(true)} isSelected={isNoCode === true}>
          No-code
        </Tab>
      </Tabs>

      {project ? renameInputs() : null}

      <Text style={{ marginTop: 8 }}>
        Upload the zip archive with the project, which contains the <code>.dashaapp</code> config and all other project
        files (no more than 5mb)
      </Text>

      {isInvalid && <Text style={{ color: "#E3CA71" }}>Failed to load the project</Text>}

      <input
        ref={(el) => {
          console.log("ref", el);
          inputRef.current = el;
        }}
        type="file"
        onChange={handleFile}
      />

      <div style={{ display: "flex", marginTop: 16 }}>
        <StrokeButton disabled={isLoading} style={{ flex: 1 }} onClick={onClose}>
          Close
        </StrokeButton>
        <ActionButton disabled={isLoading} style={{ flex: 1, marginLeft: 16 }} onClick={handleClick}>
          {isLoading && <Icon name="spinner" style={{ marginRight: 8, width: 18 }} />}
          UPLOAD
        </ActionButton>
      </div>
    </Modal>
  );
};

export default UploaderWidget;
