import React, { FC, useState } from "react";

import RenameWidget from "../../widgets/RenameWidget";
import RemoveWidget from "../../widgets/RemoveWidget";
import DuplicateWidget from "../../widgets/DuplicateWidget";
import UploaderWidget from "../../widgets/UploaderWidget";
import ProfilerWidget from "../../ProfilerPanel/components/ProfilerWidget";
import { PopupMenu } from "../../uikit/popup";

import Workspace from "../../core/workspace/Workspace";
import Explorer from "../../core/explorer/Explorer";
import Project from "../../core/explorer/Project";
import UIManager from "../../core/misc/UIManager";

type Action = "remove" | "rename" | "duplicate" | "uploader" | "profiler" | null;

interface Props {
  isDisabled?: boolean;
  explorer: Explorer;
  workspace: Workspace;
  project: Project;
  children: React.ReactElement;
}

const ProjectActions: FC<Props> = ({ children, isDisabled, project, explorer }) => {
  const [action, setAction] = useState<Action>(null);
  const closeAction = () => setAction(null);

  if (isDisabled) {
    return children;
  }

  const actions = [
    {
      label: "Edit title & description",
      isEnable: project.metadata.isEditable,
      action: () => setAction("rename"),
    },
    {
      label: "Copy app link",
      isEnable: !project.metadata.isEditable,
      action: () => {
        void explorer.copyProjectLink(project.id, project.version);
        UIManager.notice("Project link has been copied");
      },
    },
    {
      label: "Clone this app",
      isEnable: true,
      action: () => setAction("duplicate"),
    },
    {
      label: "Edit from archive",
      isEnable: project.metadata.isEditable,
      action: () => setAction("uploader"),
    },
    {
      label: "Download",
      isEnable: true,
      action: () => explorer.download(project),
    },
    {
      label: "Delete",
      isEnable: project.metadata.isEditable,
      action: () => setAction("remove"),
    },
  ];

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <DuplicateWidget isOpen={action == "duplicate"} explorer={explorer} project={project} onClose={closeAction} />
      <RenameWidget isOpen={action == "rename"} project={project} onClose={closeAction} />
      <RemoveWidget isOpen={action == "remove"} explorer={explorer} project={project} onClose={closeAction} />
      <UploaderWidget isOpen={action == "uploader"} explorer={explorer} project={project} onClose={closeAction} />

      <PopupMenu actions={actions} position="bottom center" offsetY={8} closeAfterSelect>
        {children}
      </PopupMenu>
    </span>
  );
};

export default ProjectActions;
