import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Icon, Message, Popup, Table } from "semantic-ui-react";
import "./styles.css";
import { useParams } from "react-router-dom";

export const CustomerQueues: FC<{ account: IAccount, customerId: string|undefined }> = ({ account, customerId }) => {
  const [queues, setQueues] = useState<dasha.diagnostics.QueuesInformation | undefined>(undefined);
  const [limits, setLimits] = useState<dasha.diagnostics.Limits | undefined>(undefined);
  const [instances, setInstances] = useState<dasha.diagnostics.InstanceInformation[]>([]);
  const [error, setError] = useState<string | undefined>(undefined);
  const fetchData = useCallback(() => {
    const fetchDataInternal = async () => {
      const a = await account.connectAs(customerId);
      const q = await dasha.diagnostics.getQueueStates({ account: a });
      const l = await dasha.diagnostics.getLimits({ account: a });
      const inst = await dasha.diagnostics.getInstances({ account: a });
      setQueues(q);
      setLimits(l);
      setInstances(inst);
    };
    setError(undefined);
    fetchDataInternal().catch((e) => setError("Failed to fetch data"));
  }, [account]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {error && <Message error>{error}</Message>}
      {queues && (
        <>
          <h2>Group Queues</h2>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Group Id</Table.HeaderCell>
                <Table.HeaderCell>Queue Length</Table.HeaderCell>
                <Table.HeaderCell>Limit Used</Table.HeaderCell>
                <Table.HeaderCell>Limit Max</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {Object.entries(queues?.groupLengths).map(([k, v]) => {
                return (
                  <Table.Row>
                    <Table.Cell> {k} </Table.Cell>
                    <Table.Cell> {v} </Table.Cell>
                    <Table.Cell> {limits?.groups[k]?.current ?? 0} </Table.Cell>
                    <Table.Cell> {limits?.groups[k]?.limit ?? 0} </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      )}
      {instances && (
        <>
          <h2>Instances</h2>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell><Popup content="Assigned id of the connected SDK instance" trigger={<Icon small className="help" />}/>Instance Id</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Name of the application from dashaapp file" trigger={<Icon small className="help" />}/>Name</Table.HeaderCell>
                <Table.HeaderCell><Popup content="SDK version that is being used" trigger={<Icon small className="help" />}/>SDK</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Id of a group" trigger={<Icon small className="help" />}/>Group</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Active - SDK is up and running, Inactive - starting, Unregistering - closing" trigger={<Icon small className="help" />}/>State</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Count of conversations currently running" trigger={<Icon small className="help" />}/>Active</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Count of conversations waiting for a response from the SDK" trigger={<Icon small className="help" />}/>Pending</Table.HeaderCell>
                <Table.HeaderCell><Popup content="Max number of concurrent conversations, that can be processed by this instance" trigger={<Icon small className="help" />}/>Max</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {instances.map((x) => {
                return (
                  <Table.Row>
                    <Table.Cell> {x.instanceId} </Table.Cell>
                    <Table.Cell> {x.applicationName} </Table.Cell>
                    <Table.Cell> {x.sdkVersion} </Table.Cell>
                    <Table.Cell> {x.groupId} </Table.Cell>
                    <Table.Cell> {x.state} </Table.Cell>
                    <Table.Cell> {x.activeConversations} </Table.Cell>
                    <Table.Cell> {queues?.instances[x.instanceId ?? ""]?.pendingLength} </Table.Cell>
                    <Table.Cell> {x.maxConcurrency} </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </>
      )}
    </>
  );
};
