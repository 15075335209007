import React, { FC, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { SessionProtocol } from "@core/workspace/session-storage/types";
import Workspace from "@core/workspace/Workspace";
import GridLayout from "@core/misc/GridLayout";

import { Icon } from "../uikit";
import Session from "./Session";
import * as S from "./styled";
import Player from "./Player";
import SkeletonSvg from "./skeleton.svg";
import { toJS } from "mobx";

interface Props {
  id: string;
  isOpen: boolean;
  session: SessionProtocol | null;
  workspace: Workspace | undefined;
  layout: GridLayout;
  allowTransitionsDebug?: boolean;
  gptEmulateCallback?: (instanceId: string) => void;
}

const DialogPanel: FC<Props> = ({ workspace, layout, session, isOpen, id, allowTransitionsDebug, gptEmulateCallback }) => {
  useEffect(() => {
    layout.collapsePanel("runner");
  }, []);

  if (session == null)
    return (
      <S.Panel id={id} isOpen={isOpen}>
        {isOpen && <SkeletonSvg style={{ margin: "auto" }} />}
      </S.Panel>
    );

  return (
    <S.Panel id={id} isOpen={isOpen}>
      <S.ToggleButton onClick={() => layout.toggleCollapse("runner")}>
        <Icon name="arrow-left" angle={isOpen ? -180 : 0} />
      </S.ToggleButton>

      {session.audioPlayer && <Player session={session} player={session.audioPlayer} />}
      <Session session={session} workspace={workspace} gptEmulateCallback={gptEmulateCallback} allowTransitionsDebug={allowTransitionsDebug ?? workspace?.sessions?.allowTransitionDebug}/>
    </S.Panel>
  );
};

export default observer(DialogPanel);
