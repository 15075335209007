import React, { FC, useEffect, useState, useRef, useCallback, useMemo } from "react";

import { IAccount } from "@core/account/interface";
import { observer } from "mobx-react-lite";
import * as dasha from "@dasha.ai/sdk/web";
import { Table, Loader, Button, Dropdown } from "semantic-ui-react";
import "./styles.css";
import { InboundConfig } from "@dasha.ai/sdk/web/sip/inbound-configs";
import { InboundVoIPEditor } from "./InboundVoIPEditor";

export const GroupListDropdown: FC<{ account: IAccount, group: string | undefined, selectGroup: (group: string | undefined) => void }> = ({ account, group, selectGroup }) => {
  const [groups, setGroups] = useState<{ text: string }[]>([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const fetchData = async () => {
      const a = await account.connect();
      const q = ["Default", ...Object.keys(await dasha.groups.listGroups({ account: a, includeTemporary: false }))];
      const groups = Array.from(q).map((x) => ({ text: x, value: x, key: x }));
      setGroups(groups);
    };
    fetchData().then(() => {
      setLoading(false);
    });
  }, [account]);

  return (
    <Dropdown
      placeholder="Select Group"
      fluid
      search
      selection
      options={groups}
      loading={loading}
      value={group}
      onChange={(e, {value}) => selectGroup(value)}
    />
  );
};
